import { EQUALS } from "shared/utils";

export interface Field {
  name: string;
  id?: string;
}

export interface GenericRule {
  field: Field | undefined;
  defaultValue: any[];
  operator: string;
  id?: number | undefined;
}

export interface Rule extends GenericRule {
  isRequirement: boolean;
}

export const defaultField = undefined;
export const defaultOperator = EQUALS;

export const defaultConstraintRequirement: Rule = {
  field: defaultField,
  operator: defaultOperator,
  defaultValue: [],
  isRequirement: false,
  id: undefined,
};
