import moment, { Moment } from "moment";
import { RegionInterface } from "shared/utils";

export interface DoseBandInterface {
  id?: number;
  minimum: number;
  maximum: number;
  bandedDose: number;
}

export const emptyDoseBand: DoseBandInterface = {
  minimum: 0,
  maximum: 0,
  bandedDose: 0,
};

export interface User {
  firstName: string;
  lastName: string;
}

export interface DrugOrderInterface {
  id?: string;
  drugId?: number;
  name: string;
  route: string;
  type: string;
  doseUnit: DoseUnit;
  doseBands: DoseBandInterface[];
  doseBasis: number | null;
  roundToNearest: number | null;
  minimumDose: number | null;
  maximumDose: number | null;
  doseIsRange: boolean;
  solution: string;
  frequency: string;
  prn: boolean;
  minimumDuration: number | null;
  durationIsRange: boolean;
  maximumDuration: number | null;
  durationUnits: string;
  administrationInstructions: string;
  patientInstructions: string;
  singleDoseLimit: number | null;
  lifetimeDoseLimit: number | null;
  isActive: boolean;
  version: string;
  createdAt: Moment;
  editTime: Moment;
  updatedBy: User | null;
}

export const emptyDrugOrder: DrugOrderInterface = {
  name: "",
  route: "",
  type: "",
  doseUnit: {},
  doseBands: [],
  doseBasis: null,
  roundToNearest: null,
  minimumDose: null,
  maximumDose: null,
  doseIsRange: false,
  solution: "",
  frequency: "",
  prn: false,
  minimumDuration: null,
  durationIsRange: false,
  maximumDuration: null,
  durationUnits: "",
  administrationInstructions: "",
  patientInstructions: "",
  singleDoseLimit: null,
  lifetimeDoseLimit: null,
  isActive: true,
  version: "1",
  createdAt: moment(),
  editTime: moment(),
  updatedBy: null,
};

export interface DrugInterface {
  id?: number;
  genericName: string;
  brandName: string;
  route: string;
  form: string;
  doseUnit: DoseUnit;
  drugCategory: "" | "Treatment" | "Supporting";
  singleDoseLimit: number | null;
  lifetimeDoseLimit: number | null;
  concentration: string;
  solution: string;
  strength: string;
  roundToNearest: number | null;
  minimumDuration: number | null;
  durationIsRange: boolean;
  durationUnits: string;
  maximumDuration: number | null;
  administrationInstructions: string;
  patientInstructions: string;

  createdAt: Moment;
  editTime: Moment;
  updatedBy: User | null;
  isActive: boolean;
  drugOrders: DrugOrderInterface[];
}

export const emptyDrug: DrugInterface = {
  genericName: "",
  brandName: "",
  route: "",
  form: "",
  doseUnit: {},
  drugCategory: "",
  singleDoseLimit: null,
  lifetimeDoseLimit: null,
  concentration: "",
  solution: "",
  strength: "",
  roundToNearest: null,
  minimumDuration: null,
  durationIsRange: false,
  durationUnits: "",
  maximumDuration: null,
  administrationInstructions: "",
  patientInstructions: "",
  createdAt: moment(),
  editTime: moment(),
  updatedBy: null,
  isActive: true,
  drugOrders: [],
};

export interface FormularyInterface {
  name: string;
  region: RegionInterface;
  drugs: DrugInterface[];
}

export interface FormularyColumnInterface {
  attribute: string;
  label: string;
}

export interface DoseUnit {
  id?: string;
  name?: string;
  isCalculated?: boolean;
}

export enum DrugOrderActionType {
  EDIT,
  ADD,
  CLONE,
}
