import React, { useLayoutEffect, useRef, useState } from "react";
import {
  StyledTable,
  StyledTableHeaderRow,
  StyledTableCell,
  StyledTableHeaderCell,
} from "./components/TableComponents";
import { Badge, styled, useTheme } from "@mui/material";
import { ProtocolInformation } from "./interfaces";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import DraggableTableRow from "./components/DraggableTableRow";
import { DragIndicator } from "@mui/icons-material";
import { mergeEmptyDays } from "./components/utils";
import { useMutation } from "@apollo/client";
import {
  HorizontalScrollBar,
  VerticalScrollBar,
} from "./components/VirtualScrollBar";
import { useWindowSize } from "shared/useWindowSize";
import { prepareResponseForReOrdering } from "./utils";
import { REORDER_CYCLE_DRUG_ORDERS } from "graphql/ProtocolQueries";

interface CycleTableProps {
  protocolInformation: ProtocolInformation;
  selectedCycle: number;
  readonly: boolean;
}
const TableContentContainer = styled("div")`
  display: flex;
  align-items: center;
  padding: 8px;
`;

export const DoseCellContainer = styled(TableContentContainer)`
  justify-content: center;
`;

const CycleTable = (props: CycleTableProps) => {
  const theme = useTheme();
  const drugOrderNameColRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLTableElement>(null);
  const tableHeaderRef = useRef<HTMLTableRowElement>(null);
  const verticalScrollbarRef = useRef<HTMLDivElement>(null);
  const horizontalScrollbarRef = useRef<HTMLDivElement>(null);
  const windowSize = useWindowSize();
  const [tableBounds, setTableBounds] = useState({
    clientHeight: 0,
    clientWidth: 0,
    scrollHeight: 0,
    scrollWidth: 0,
    maxThWidth: 0,
  });

  const [reOrderCycleDrugOrdersMutation] = useMutation(
    REORDER_CYCLE_DRUG_ORDERS,
  );
  useLayoutEffect(() => {
    if (
      tableHeaderRef.current &&
      tableRef.current &&
      drugOrderNameColRef.current
    ) {
      const trElement = tableHeaderRef.current;

      const children = Array.from(trElement.children).slice(1);

      // Measure
      const thWidths: number[] = children.map(
        (child) => child.getBoundingClientRect().width,
      );
      const maxThWidth = Math.max(...thWidths);

      setTableBounds({
        clientHeight: tableRef.current.clientHeight,
        clientWidth: tableRef.current.clientWidth,
        scrollHeight: tableRef.current.scrollHeight,
        scrollWidth: tableRef.current.scrollWidth,
        maxThWidth: maxThWidth,
      });
    }
  }, [props.selectedCycle, props.protocolInformation, windowSize]);
  const selectedCycleSchedule = props.protocolInformation.cycleSchedule.find(
    (cycle) => cycle.cycleId === props.selectedCycle,
  );
  if (!selectedCycleSchedule) return <></>;
  const days = [
    ...Array(props.protocolInformation.numberOfDaysPerCycle).keys(),
  ].reduce((result: { [key: number]: boolean }, day) => {
    result[day] = false;
    return result;
  }, {});
  selectedCycleSchedule.drugOrder.forEach((drug) =>
    drug.days.forEach((day) => {
      days[day] = true;
    }),
  );
  const mergedDays = mergeEmptyDays(days);
  const headerItems = [
    <StyledTableHeaderCell key="-100" empty={true}>
      <div style={{ padding: "8px 16px " }} ref={drugOrderNameColRef}>
        Drug Order
      </div>
    </StyledTableHeaderCell>,
    ...mergedDays.map(
      (day, index): JSX.Element => (
        <StyledTableHeaderCell
          key={index}
          empty={day.title.startsWith("EMPTY")}
        >
          <div
            style={{ padding: "8px 16px", minWidth: "90px" }}
            key={day.key}
            data-cy={`cycle-table-header-day-${day.key}`}
          >
            {day.title}
          </div>
        </StyledTableHeaderCell>
      ),
    ),
  ];
  const tableRows = selectedCycleSchedule.drugOrder
    .sort((a, b) => a.order - b.order)
    .map((drugOrder, index) => (
      <DraggableTableRow
        data-cy={`cycle-table-row-${index}`}
        isDraggable={!props.readonly}
        key={drugOrder.order}
        index={drugOrder.order}
        draggableId={drugOrder.order?.toString()}
      >
        <StyledTableCell
          data-cy={`cycle-table-drug-order-${index}`}
          key="-100"
          minWidth={
            drugOrderNameColRef.current
              ? drugOrderNameColRef.current.clientWidth
              : 120
          }
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              whiteSpace: "nowrap",
            }}
          >
            <div
              style={{ padding: "8px 16px", minWidth: "300px" }}
              data-cy={`cycle-table-drugorder-name-${index}`}
            >
              {drugOrder.drugOrderName}
            </div>
            {drugOrder.type === "Take At Home" && (
              <div style={{ marginLeft: "auto" }}>
                <Badge color="info">Discharge Med</Badge>
              </div>
            )}
            {!props.readonly && (
              <DragIndicator
                style={
                  drugOrder.type === "Take At Home"
                    ? {}
                    : { marginLeft: "auto" }
                }
              />
            )}
          </div>
        </StyledTableCell>
        {mergedDays.map((day) => {
          return drugOrder.days.includes(day.key) ? (
            <StyledTableCell key={day.key} minWidth={tableBounds.maxThWidth}>
              <DoseCellContainer>
                <Badge
                  data-cy={`cycle-table-drugorder-dose-${index}-${day.key}`}
                  color="info"
                >
                  {drugOrder.dose}
                </Badge>
              </DoseCellContainer>
            </StyledTableCell>
          ) : (
            <StyledTableCell
              key={day.key}
              empty={day.title.startsWith("EMPTY")}
              minWidth={
                day.title.startsWith("EMPTY") ? 120 : tableBounds.maxThWidth
              }
            >
              <div></div>
            </StyledTableCell>
          );
        })}
      </DraggableTableRow>
    ));

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.grey[100],
        fontSize: "14px", // just to match the HMO table
        lineHeight: 1,
        paddingLeft: "8px",
      }}
    >
      <div style={{ display: "flex", width: "100%", overflow: "hidden" }}>
        <DragDropContext
          onDragEnd={(result: any) => {
            if (result.destination && result.source) {
              // TODO: Optimistic response to update the cycle ordering.
              reOrderCycleDrugOrdersMutation({
                variables: {
                  templateId: props.protocolInformation.protocolId,
                  cycle: props.selectedCycle,
                  drugOrderId:
                    selectedCycleSchedule.drugOrder[result.source.index]
                      .drugOrderId,
                  sourceIndex: result.source.index,
                  destinationIndex: result.destination.index,
                },
                optimisticResponse: {
                  reorderCycleDoses: {
                    protocolDrugOrders: prepareResponseForReOrdering(
                      props.protocolInformation.drugOrderInCycle,
                      props.selectedCycle,
                      result.source.index,
                      result.destination.index,
                    ),
                    __typename: "ReorderCycleDoses",
                  },
                },
              });
            }
          }}
        >
          <Droppable droppableId="cycle-table-droppable">
            {(provided: any) => (
              <StyledTable
                data-cy={"cycle-table"}
                ref={tableRef}
                onScroll={(event) => {
                  if (verticalScrollbarRef.current) {
                    verticalScrollbarRef.current.scrollTop =
                      event.currentTarget.scrollTop;
                  }
                  if (horizontalScrollbarRef.current) {
                    horizontalScrollbarRef.current.scrollLeft =
                      event.currentTarget.scrollLeft;
                  }
                }}
              >
                <thead
                  style={{
                    minHeight: "50px",
                    height: "50px",
                    backgroundColor: theme.palette.grey[100],
                  }}
                >
                  <StyledTableHeaderRow ref={tableHeaderRef}>
                    {headerItems}
                  </StyledTableHeaderRow>
                </thead>
                <tbody ref={provided.innerRef}>{tableRows}</tbody>
                {provided.placeholder}
              </StyledTable>
            )}
          </Droppable>
        </DragDropContext>
        <VerticalScrollBar
          scrollbarRef={verticalScrollbarRef}
          paddingHeight={
            tableHeaderRef.current ? tableHeaderRef.current.clientHeight : 40
          }
          bounds={tableBounds}
          parentRef={tableRef}
        />
      </div>
      <HorizontalScrollBar
        scrollbarRef={horizontalScrollbarRef}
        bounds={tableBounds}
        paddingWidth={
          drugOrderNameColRef.current
            ? drugOrderNameColRef.current.clientWidth
            : 40
        }
        parentRef={tableRef}
      />
    </div>
  );
};

export default CycleTable;
