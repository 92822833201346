import { TextField, styled } from "@mui/material";

export const StyledTextField = styled(TextField)`
  width: 120px;
  margin-right: 10px;
`;

export const StyledDiv = styled("div")`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
`;
