import React from "react";
import { Button as MuiButton, Tooltip, styled } from "@mui/material";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";

const defaultProps: any = {
  icon: <AddCircleOutline />,
};

const StyledButton = styled(MuiButton)`
&& {
  text-transform: none;
  min-width: 100px;
  min-height: 2em;
  font-size: 16px;
  border-width: 2px;
  }
}}
`;

const Button = (props: any) => (
  <Tooltip title={props.tooltip} placement="bottom">
    <span>
      <StyledButton
        variant="outlined"
        startIcon={props.icon ? props.icon : undefined}
        {...props}
      />
    </span>
  </Tooltip>
);

Button.defaultProps = defaultProps;

export default Button;
