import React, { useEffect, useRef } from "react";
import { Field, useFormikContext } from "formik";
import { Grid } from "@mui/material";
import NakedInputField from "./NakedInputField";

interface Props {
  index: number;
  isActive: boolean;
}

const DoseBandField = ({ index, isActive }: Props) => {
  const [disabled, setDisabled] = React.useState<boolean>(!isActive);
  const {
    values,
    setFieldValue,
  }: { values: any; setFieldValue: any } = useFormikContext();

  const handleDisable = (value: boolean) => {
    setDisabled(!value);
  };

  // // Avoid running the handleDisabled the first time the component loads this would reset
  // // current values.
  const firstUpdate = useRef(true);
  useEffect(() => {
    if (typeof isActive !== "undefined" && !firstUpdate.current)
      handleDisable(isActive);
    else firstUpdate.current = false;
    // eslint-disable-next-line
  }, [isActive]);

  const testBand = () => {
    const band = values.doseBands[index];
    if (band.minimum >= band.maximum) {
      setFieldValue(`doseBands[${index}].maximum`, band.minimum);
    }

    if (band.bandedDose < band.minimum) {
      setFieldValue(`doseBands[${index}].bandedDose`, band.minimum);
    }

    if (band.bandedDose > band.maximum) {
      setFieldValue(`doseBands[${index}].bandedDose`, band.maximum);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Field
            type="number"
            handleMutation={testBand}
            disabled={disabled}
            component={NakedInputField}
            id={`dose-band-${index}-minimum`}
            name={`doseBands[${index}].minimum`}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={4}>
          <Field
            type="number"
            disabled={disabled}
            handleMutation={testBand}
            component={NakedInputField}
            id={`dose-band-${index}-maximum`}
            name={`doseBands[${index}].maximum`}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={4}>
          <Field
            type="number"
            disabled={disabled}
            component={NakedInputField}
            handleMutation={testBand}
            id={`dose-band-${index}-banded_dose`}
            name={`doseBands[${index}].bandedDose`}
            variant="outlined"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DoseBandField;
