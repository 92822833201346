import { createContext } from "react";
import { RegionInterface } from "shared/utils";

export interface UserContextType {
  id: number;
  firstName: string;
  lastName: string;
  roAccess: boolean;
  moAccess: boolean;
  regions: RegionInterface[];
  editRegions: RegionInterface[];
}

export const initialContext: UserContextType = {
  id: 0,
  firstName: "",
  lastName: "",
  roAccess: false,
  moAccess: false,
  regions: [],
  editRegions: [],
};

const UserContext = createContext<UserContextType>(initialContext);

export default UserContext;
