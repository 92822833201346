import React, { useState, useLayoutEffect, useRef } from "react";
import { FieldProps, getIn } from "formik";

import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import ToggleButton from "components/base/ToggleButton";
import FormRow from "components/base/FormRow";
import InvalidMessage from "components/base/InvalidMessage";

interface Props extends FieldProps {
  label: string;
  options: any[];
  id: string;
  name: string;
  handleMutation: any;
  toggleActive?: boolean;
  isActive?: boolean;
  readOnly: boolean;
  labelXs?: any;
}

const ToggleButtonGroupField = ({
  field,
  form,
  label,
  options = [],
  handleMutation = () => {},
  toggleActive,
  isActive,
  readOnly,
  labelXs,
}: Props) => {
  const { name, value } = field;
  const { errors, submitCount, setFieldValue } = form;
  const fieldError = getIn(errors, name);
  const [disabled, setDisabled] = useState<boolean>(!isActive);

  const handleChange = (_event: React.ChangeEvent<any>, updatedValue: any) => {
    const stringValue =
      typeof updatedValue === "string"
        ? updatedValue
        : JSON.stringify(updatedValue);
    handleMutation(name, stringValue);
    setFieldValue(name, updatedValue);
  };

  const handleDisable = (value: boolean) => {
    setDisabled(!value);
    handleMutation(name, null, value);
    // field has been disabled remove any set value
    if (!value) {
      setFieldValue(name, null);
    }
  };

  const show_error = submitCount > 0 || fieldError;

  // Avoid running the handleDisabled the first time the component loads this would reset
  // current values.
  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (typeof isActive !== "undefined" && !firstUpdate.current)
      handleDisable(isActive);
    else firstUpdate.current = false;
    // eslint-disable-next-line
  }, [isActive]);

  return (
    <FormRow
      label={label}
      disabled={disabled}
      name={name}
      toggleActive={toggleActive}
      handleDisable={handleDisable}
      readOnly={readOnly}
      labelXs={labelXs}
    >
      <ToggleButtonGroup
        value={value}
        exclusive
        onChange={handleChange}
        aria-label={`${label}-group`}
        data-test-id={`${name}-button-group`}
      >
        {options.map((option: { label: string; value: any }, idx: number) => (
          <ToggleButton
            key={idx}
            name={name}
            selected={value === option.value}
            value={option.value}
            aria-label={option.label}
            disabled={disabled || readOnly}
            show_error={show_error}
            data-test-id={`${name}-button-${option.value}`}
          >
            {option.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      {show_error && <InvalidMessage text={fieldError} />}
    </FormRow>
  );
};

export default ToggleButtonGroupField;
