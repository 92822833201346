import React, { Children } from "react";
import { Grid, GridSize, styled } from "@mui/material";

const StyledRow = styled(Grid)`
  margin: 12px 0;
`;

const InputContainer = styled(Grid)`
  max-width: 600px;
  align-items: center;
  spacing: 8px;
  justify-content: flex-start;
`;

const ChildItem = styled(Grid)``;

interface Props {
  children: (JSX.Element | "" | undefined)[];
}

const threeColumns: GridSize[] = [2, 3, 7];
const FormikFormRow = ({ children }: Props) => {
  return (
    <StyledRow item container md={8} xs={12} alignItems="center">
      <InputContainer container>
        {Children.map(children, (child, index) => {
          return (
            <ChildItem item xs={threeColumns[index]}>
              {child}
            </ChildItem>
          );
        })}
      </InputContainer>
    </StyledRow>
  );
};

export default FormikFormRow;
