import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_TEMPLATE_STRUCTURE } from "graphql/TemplateGroupQueries";
import { Step } from "@mui/material";
import {
  StyledDrawer,
  StyledStepContent,
  StyledStepContentDiv,
  StyledStepLabel,
  StyledStepper,
} from "./SideBarStyledComponents";
import { getTemplatePages, getActiveStep } from "shared/utils";
import {
  SIMULATION,
  PRESCRIPTION,
  VOLUMING,
  INFORMATION,
  CYCLE,
  PEER_REVIEW,
  BULK_EDIT_REVIEW,
  TEMPLATE_PAGE,
} from "i18n/constants";

interface ParamTypes {
  templateId: string;
}

interface Props {
  valid: boolean;
  showPeerReview?: boolean;
  isRo: boolean;
  isBulkEdit?: boolean;
}

const SideBarNav = ({ showPeerReview, valid, isRo, isBulkEdit }: Props) => {
  const pages = getTemplatePages(isRo, showPeerReview, isBulkEdit);

  const history = useHistory();
  const { templateId } = useParams<ParamTypes>();

  const { loading, error, data } = useQuery(GET_TEMPLATE_STRUCTURE, {
    variables: { templateId },
  });
  const activeStepIndex = pages.indexOf(getActiveStep());
  const siteGroups = data?.template?.careplanTreatment
    ?.slice()
    .sort((a: any, b: any) => a.id - b.id);

  if (loading || !data || !siteGroups) return <div>Loading</div>;
  if (error) return <div>Error</div>;

  const navigateToPage = (url: string) => {
    const pathPrefix = isBulkEdit ? "bulk_edit" : "template";
    if (valid || url === "")
      history.push(`/${pathPrefix}/${templateId}/${url}`);
  };

  const getStepContent = (page: string, index: number) => {
    switch (page) {
      case SIMULATION:
        return siteGroups.map((siteGroup: any, index: number) => (
          <StyledStepContentDiv
            key={`${index}-${siteGroup.id}`}
            onClick={() => navigateToPage(`simulation/${siteGroup.id}`)}
          >
            {`Site Group ${index + 1}`}
          </StyledStepContentDiv>
        ));
      case PRESCRIPTION:
        return siteGroups.map((siteGroup: any) =>
          siteGroup.siteTemplate.map((site: any) => (
            <StyledStepContentDiv
              key={`${index}-${site.id}`}
              onClick={() => navigateToPage(`prescription/${site.id}`)}
            >
              {site?.site?.name}
            </StyledStepContentDiv>
          ))
        );
      case VOLUMING:
        return isBulkEdit
          ? ""
          : siteGroups.map((siteGroup: any, index: number) => (
              <StyledStepContentDiv
                key={`${index}-${siteGroup.id}`}
                onClick={() => navigateToPage(`voluming/${siteGroup.id}`)}
              >
                {`Site Group ${index + 1}`}
              </StyledStepContentDiv>
            ));
      case INFORMATION:
      case CYCLE:
      case PEER_REVIEW:
      case BULK_EDIT_REVIEW:
      case TEMPLATE_PAGE:
        return "";
      default:
        return "Unknown step";
    }
  };
  const getStepLabelNav = (page: string) => {
    switch (page) {
      case TEMPLATE_PAGE:
        return "";
      case INFORMATION:
        return "information/";
      case SIMULATION:
        return `simulation/${siteGroups[0]?.id}`;
      case PRESCRIPTION:
        return `prescription/${siteGroups[0]?.siteTemplate[0]?.id}`;
      case VOLUMING:
        return `voluming/${siteGroups[0]?.id}`;
      case PEER_REVIEW:
        return "peerReview";
      case BULK_EDIT_REVIEW:
        return `review/${siteGroups[0]?.id}`;
      case CYCLE:
        return "cycle/0";
      default:
        return "";
    }
  };

  return (
    <StyledDrawer>
      <StyledStepper
        nonLinear
        activeStep={activeStepIndex}
        orientation="vertical"
      >
        {pages.map((page: string, index: number) => {
          const isValid = valid || page === TEMPLATE_PAGE ? "true" : "false";
          const path = getStepLabelNav(page);
          return (
            <Step expanded key={index}>
              <StyledStepLabel
                valid={isValid}
                onClick={() => navigateToPage(path)}
              >
                {page}
              </StyledStepLabel>
              <StyledStepContent valid={isValid}>
                {getStepContent(page, index)}
              </StyledStepContent>
            </Step>
          );
        })}
      </StyledStepper>
    </StyledDrawer>
  );
};

export default SideBarNav;
