import React from "react";
import { Tooltip as MuiTooltip, styled } from "@mui/material";
import { TooltipProps } from "@mui/material/Tooltip";

interface StyledTooltipProps extends TooltipProps {
  className?: string;
}
const Tooltip = styled(({ className, ...props }: StyledTooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))({
  "&.MuiTooltip-tooltip": {
    fontSize: 14,
  },
});

export default Tooltip;
