import * as yup from "yup";

export const roValidationSchema = yup.object({
  siteFilter: yup.string().required("This field cannot be empty"),
  doseFilter: yup.string().required("This field cannot be empty"),
  techniqueFilter: yup.string().required("This field cannot be empty"),
  name: yup.string().required("This field cannot be empty"),
  treatmentSites: yup
    .array()
    .of(yup.string())
    .min(1, "Please select a treatment site"),
  tumourStream: yup.string().required("Please select a tumour stream"),
  siteGroupSites: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string(),
        name: yup.string(),
        treatmentrulesiteGroupRules: yup.array(),
        siteGroupTemplate: yup.array().min(1),
      })
    )
    .min(1, "Please add a site group"),
  site: yup.array().of(
    yup
      .array()
      .of(
        yup.object().shape({
          id: yup.string(),
          ruleSite: yup.array(),
          site: yup.object().shape({
            name: yup.string(),
          }),
        })
      )
      .min(1, "Please add a site")
  ),
});

export const moValidationSchema = yup.object().shape({
  name: yup.string().required("This field cannot be empty"),
  tumourStream: yup.string().required("Please select a tumour stream"),
  evidenceId: yup
    .string()
    .required("This field cannot be empty")
    .typeError("This field cannot be empty"),
  numberOfCycles: yup
    .number()
    .required("This field cannot be empty")
    .typeError("This field cannot be empty"),
  daysPerCycle: yup
    .number()
    .required("This field cannot be empty")
    .typeError("This field cannot be empty"),
  hasNegativeDays: yup.boolean(),
  negativeDays: yup.array().when("hasNegativeDays", {
    is: true,
    then: yup.array().min(1, "This field cannot be empty"),
  }),
});
