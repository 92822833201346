import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import CareplanCreatorForm from "./CareplanCreatorForm";
import UserContext from "contexts/UserContext";

import { GET_CAREPLAN_TEMPLATE } from "graphql/TemplateQueries";
import {
  LOAD_LIST_DATA,
  GET_DIAGNOSIS_CATEGORIES,
} from "graphql/ListDataQueries";

import { GET_SITES } from "graphql/SiteQueries";

const roListCategories = [
  "patientPosition",
  "extentOfCT",
  "immobilisation",
  "bladder",
  "stomach",
  "is3dBolus",
  "bolusLocationType",
  "bolusThickness",
  "bolusFrequency",
  "technique",
  "phase",
  "modality",
  "imaging",
  "imagingTechnique",
  "frequency",
  "doseto",
  "treatingDepartment",
  "qcl",
  "credential",
  "breathHold",
];

const moListCategories = [
  "route",
  "form",
  "drugType",
  "units",
  "solution",
  "durationunits",
];

const CareplanCreatorFormApollo = () => {
  const { templateId } = useParams<{ templateId: string }>();
  const user = useContext(UserContext);

  const {
    loading,
    error,
    data,
    refetch: reloadTemplate,
  } = useQuery(GET_CAREPLAN_TEMPLATE, {
    variables: { templateId },
  });

  const listCategories = ([] as string[]).concat(
    user.moAccess ? moListCategories : [],
    user.roAccess ? roListCategories : [],
  );

  const {
    loading: listDataLoading,
    error: listDataError,
    data: listData,
  } = useQuery(LOAD_LIST_DATA, {
    fetchPolicy: "cache-first",
    variables: {
      listCategories,
      templateId,
    },
  });

  const {
    loading: diagnosisCategoryLoading,
    error: diagnosisCategoryError,
    data: diagnosisCategoryOptions,
  } = useQuery(GET_DIAGNOSIS_CATEGORIES, {
    fetchPolicy: "cache-first",
  });

  const {
    loading: siteLoading,
    error: siteError,
    data: siteOptions,
  } = useQuery(GET_SITES, {
    fetchPolicy: "cache-first",
    variables: {
      templateId,
    },
  });
  return (
    <>
      {loading &&
        listDataLoading &&
        diagnosisCategoryLoading &&
        siteLoading && <div>Loading...</div>}
      {data && listData && diagnosisCategoryOptions && siteOptions && (
        <CareplanCreatorForm
          template={data.template}
          reloadTemplate={reloadTemplate}
          listData={listData.dataReferenceList}
          diagnosisCategoryData={diagnosisCategoryOptions.diagnosisCategoryList}
          siteListData={siteOptions.siteList}
        />
      )}
      {error && listDataError && diagnosisCategoryError && siteError && (
        <div>Error loading...</div>
      )}
    </>
  );
};

export default CareplanCreatorFormApollo;
