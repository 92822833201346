import React, { useEffect, useCallback, useRef } from "react";
import { useFormikContext } from "formik";
import _ from "lodash";

const AutoSave = ({ debounceMs = 1000 }) => {
  const prevFormikValues = useRef<unknown>();
  const formik = useFormikContext();
  const debouncedSubmit = useCallback(
    _.debounce(() => {
      return formik.submitForm();
    }, debounceMs),
    [formik.submitForm, debounceMs]
  );

  useEffect(() => {
    // Don't call the submit if the values haven't changed or loading for the first time
    if (
      prevFormikValues.current &&
      !_.isEqual(prevFormikValues.current, formik.values)
    ) {
      debouncedSubmit();
    } else {
      prevFormikValues.current = formik.values;
    }
  }, [debouncedSubmit, formik.values]);

  useEffect(() => {
    return () => {
      debouncedSubmit.flush();
    };
  }, [debouncedSubmit]);
  return <></>;
};

export default AutoSave;
