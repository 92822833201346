import { gql } from "@apollo/client";

export const GET_CYCLE = gql`
  query Cycle($cycleId: ID!) {
    cycle(cycleId: $cycleId) {
      id
      order
      cycleDays {
        id
        order
      }
    }
  }
`;

export const UPDATE_CYCLE_DRUG_ORDER = gql`
  mutation updateCycleDrugOrder(
    $templateId: ID!
    $cycle: Int!
    $drugOrderId: ID!
    $days: [Int]!
  ) {
    updateCycleDrugOrder(
      templateId: $templateId
      cycle: $cycle
      drugOrderId: $drugOrderId
      days: $days
    ) {
      protocolDrugOrders {
        id
      }
    }
  }
`;

export const REMOVE_DRUG_ORDER_FROM_DAY = gql`
  mutation removeDrugOrderFromDay($id: ID!) {
    removeDrugOrderFromDay(id: $id) {
      ok
    }
  }
`;

export const REORDER_CYCLE_DRUG_ORDERS = gql`
  mutation reorderCycleDoses(
    $templateId: ID!
    $cycle: Int!
    $drugOrderId: ID!
    $sourceIndex: Int!
    $destinationIndex: Int!
  ) {
    reorderCycleDoses(
      templateId: $templateId
      cycle: $cycle
      drugOrderId: $drugOrderId
      sourceIndex: $sourceIndex
      destinationIndex: $destinationIndex
    ) {
      protocolDrugOrders {
        id
        protocolDrugOrder {
          id
          drugOrder {
            id
            name
            minimumDose
            maximumDose
            doseBasis
            doseUnit {
              id
              name
            }
            type
          }
          index
        }
        cycle
        position
      }
    }
  }
`;

export const DRUG_ORDER_IN_CYCLE = gql`
  query drugOrderInCycle($templateId: ID!) {
    drugOrderInCycle(templateId: $templateId) {
      id
      protocolDrugOrder {
        id
        drugOrder {
          id
          name
          minimumDose
          maximumDose
          doseBasis
          doseUnit {
            id
            name
          }
          type
        }
        index
      }
      cycle
      position
    }
  }
`;
