import React, { Fragment, useState } from "react";
import {
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  IconButton,
  Stack,
  TextField,
  Button,
  Typography,
  styled,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { decimalFormatter } from "shared/utils";

import { DELETE_OAR, GET_OAR_TEMPLATE, REPLACE_OARS } from "graphql/OarQueries";
import { GET_SITE_GROUP_TEMPLATE } from "graphql/SiteGroupQueries";

const StyledTableHead = styled(TableHead)`
  background-color: ${(props) => props.theme.palette.grey[100]};
`;

const StyledTableHeaderCell = styled(TableCell)`
  font-weight: bold;
  border-bottom-width: 0px;
`;

const StyledTable = styled(Table)`
  max-width: 800px;
  border-spacing: 0 16px;
  border-collapse: separate;
`;

const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-head {
    background-color: black;
    color: white;
  }
  &.MuiTableCell-body {
    font-size: 14;
    padding-top: 4px;
    padding-bottom: 4px;
    border-top-width: 1px;
    border-color: ${(props) => props.theme.palette.grey[300]};
    border-style: solid;
    border-right-width: 0px;
    border-left-width: 0px;
    &:first-of-type {
      border-left-width: 1px;
    }
    &:last-of-type {
      border-right-width: 1px;
    }
  }
`;

interface OarInterface {
  id: string;
  name: string;
  organ: string;
}

interface OarColumnInterface {
  attribute: string;
  label: string;
}

interface Props {
  oars: OarInterface[];
  editOar: any;
  siteGroupId: string;
  isActive: boolean;
  inBulkEdit: boolean;
}

const OAR_TABLE_COLUMNS: OarColumnInterface[] = [
  { label: "Organ", attribute: "name" },
  { label: "Constraints", attribute: "constraints" },
  { label: "Edit", attribute: "edit" },
  { label: "Delete", attribute: "delete" },
];

const oarRow = (
  oar: OarInterface,
  key: string,
  onClick: any,
  onDelete: any,
  isActive: boolean,
): JSX.Element => {
  const oarConstraints = constraints(oar);
  return (
    <Fragment key={oar.organ}>
      <TableRow data-test-id={`oar-row-${key}`} key={key}>
        <StyledTableCell data-test-id={`organ-${key}`}>
          {oar.organ}
        </StyledTableCell>
        <StyledTableCell data-test-id={`oarConstraints-${key}`}>
          {oarConstraints.map((constraint: any, index: number) => (
            <div key={`${constraint}-${index}`}>{constraint}</div>
          ))}
        </StyledTableCell>
        <StyledTableCell align="center">
          <IconButton
            disabled={!isActive}
            data-test-id={`oarEdit-${key}`}
            onClick={onClick}
            size="large"
          >
            <EditIcon color="primary" />
          </IconButton>
        </StyledTableCell>
        <StyledTableCell align="center">
          <IconButton
            disabled={!isActive}
            data-test-id={`oarDelete-${key}`}
            onClick={onDelete}
            size="large"
          >
            <DeleteIcon color="error" />
          </IconButton>
        </StyledTableCell>
      </TableRow>
    </Fragment>
  );
};

export const constraints = (item: any) => {
  let constraints: string[] = [];
  if (item.volumeConstraintData)
    constraints = [
      ...item.volumeConstraintData.map(
        (vcd: any): string =>
          `V${decimalFormatter(vcd.firstValue) || " "}${vcd.firstValueUnit} ${
            vcd.operator
          } ${(vcd.secondValue && vcd.secondValue.join("/")) || " "}${
            vcd.secondValueUnit
          }`,
      ),
    ];
  if (item.dConstraintData)
    constraints = [
      ...constraints,
      ...item.dConstraintData.map(
        (dcd: any): string =>
          `${dcd.dosetoPrefixOperator || "D"}${decimalFormatter(dcd.firstValue) || " "}${dcd.firstValueUnit} ${
            dcd.operator
          } ${(dcd.secondValue && dcd.secondValue.join("/")) || " "}${
            dcd.secondValueUnit
          }`,
      ),
    ];

  if (item.maxValue && item.maxValue.length) {
    constraints = [
      ...constraints,
      `Max ≤ ${(item.maxValue && item.maxValue.join("/")) || " "}${
        item.maxValueUnit
      }`,
    ];
  }
  if (item.meanValue && item.meanValue.length) {
    constraints = [
      ...constraints,
      `Mean ≤ ${(item.meanValue && item.meanValue.join("/")) || " "}${
        item.meanValueUnit
      }`,
    ];
  }
  if (item.alara) constraints = ["ALARA"];
  return constraints;
};

const OarTable = (props: Props) => {
  const { oars, editOar, siteGroupId, isActive, inBulkEdit } = props;
  const [oarReplaceTemplateId, setOarReplaceTemplateId] = useState("");

  const [deleteOar] = useMutation(DELETE_OAR, {
    refetchQueries: [
      { query: GET_OAR_TEMPLATE, variables: { groupId: siteGroupId } },
      { query: GET_SITE_GROUP_TEMPLATE, variables: { groupId: siteGroupId } },
    ],
  });

  const [replaceOARs] = useMutation(REPLACE_OARS, {
    refetchQueries: [
      { query: GET_OAR_TEMPLATE, variables: { groupId: siteGroupId } },
      { query: GET_SITE_GROUP_TEMPLATE, variables: { groupId: siteGroupId } },
    ],
    onCompleted: (results) => {
      setOarReplaceTemplateId("");
    },
    onError: (error) => {
      alert(error.message || "Something went wrong");
    },
  });

  const onReplaceOAR = () => {
    replaceOARs({
      variables: {
        siteGroupTemplateId: siteGroupId,
        templateId: oarReplaceTemplateId,
        isSelectedForBulkEdit: inBulkEdit,
      },
    });
  };

  const onDeleteOar = (index: number) => {
    deleteOar({
      variables: {
        siteGroupId: siteGroupId,
        index: index,
      },
    });
  };
  return (
    <TableContainer>
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography>Replace with:</Typography>
        <TextField
          placeholder="Template ID"
          value={oarReplaceTemplateId}
          disabled={!isActive}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setOarReplaceTemplateId(event.target.value);
          }}
        />
        <Button disabled={!isActive} variant="contained" onClick={onReplaceOAR}>
          Replace OARs
        </Button>
      </Stack>

      <StyledTable aria-label="customized table">
        <colgroup>
          <col style={{ width: "30%" }} />
          <col style={{ width: "30%" }} />
          <col style={{ width: "20%" }} />
          <col style={{ width: "20%" }} />
        </colgroup>
        <StyledTableHead>
          <TableRow>
            {OAR_TABLE_COLUMNS.map((header, index) => (
              <StyledTableHeaderCell
                align={index > 1 ? "center" : "left"}
                key={`oar-header-${index}`}
              >
                {header.label}
              </StyledTableHeaderCell>
            ))}
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {oars.map((oar: OarInterface, index: number) =>
            oarRow(
              oar,
              `oar-row-${index}`,
              () => editOar(index),
              () => onDeleteOar(index),
              isActive,
            ),
          )}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
};

export default OarTable;
