import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import { DrugOrderInterface } from "interfaces/formulary";
import { styled } from "@mui/material";
import { getDrugOrderDoseTitle, getDrugOrderDoseValue } from "shared/utils";

const StyledCard = styled(Card)`
  border-radius: 8px;
`;

const StyledCardContent = styled(CardContent)`
  padding: 8px;
  :last-child {
    padding-bottom: 8px;
  }
`;

const StyledCardHeader = styled(CardHeader)`
  background-color: ${(props) => props.theme.palette.grey[100]};

  & span {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
`;

const StyledGrid = styled("div")`
  display: grid;
  justify-content: space-between;
  grid-template-columns: auto auto auto auto auto;
  padding: 8px;
`;

const StyledHeaderItem = styled("div")``;
const StyledValueItem = styled("div")`
  font-weight: 700;
`;

const DrugInfoPanel = (props: DrugOrderInterface) => {
  return (
    <StyledCard>
      <StyledCardHeader title={props.name} />
      <StyledCardContent>
        <StyledGrid>
          <StyledHeaderItem data-testid="druginfopanel-header-dosetitle">
            {getDrugOrderDoseTitle(props)}
          </StyledHeaderItem>
          <StyledHeaderItem data-testid="druginfopanel-header-route">
            Route
          </StyledHeaderItem>
          <StyledHeaderItem data-testid="druginfopanel-header-frequency">
            Frequency
          </StyledHeaderItem>
          <StyledHeaderItem data-testid="druginfopanel-header-prn">
            PRN
          </StyledHeaderItem>
          <StyledHeaderItem data-testid="druginfopanel-header-type">
            Type
          </StyledHeaderItem>
          <StyledValueItem data-testid="druginfopanel-value-dose">
            {getDrugOrderDoseValue(props)}
          </StyledValueItem>
          <StyledValueItem data-testid="druginfopanel-value-route">
            {props.route}
          </StyledValueItem>
          <StyledValueItem data-testid="druginfopanel-value-frequency">
            {props.frequency}
          </StyledValueItem>
          <StyledValueItem data-testid="druginfopanel-value-prn">
            {props.prn ? "Yes" : "No"}
          </StyledValueItem>
          <StyledValueItem data-testid="druginfopanel-value-type">
            {props.type}
          </StyledValueItem>
        </StyledGrid>
      </StyledCardContent>
    </StyledCard>
  );
};

export default DrugInfoPanel;
