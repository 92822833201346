import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import { TextField, styled } from "@mui/material";

interface Props {
  placeholderText: string;
  onEnter: (text: string) => void;
  dataTestId: string;
}

const SearchTextField = styled(TextField)`
  & input {
    border: 0;
    margin-left: 8px;
    flex: 1;
    min-width: 360px;
  }
  input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
`;

const SearchField = ({ placeholderText, onEnter, dataTestId }: Props) => {
  const [text, setText] = useState("");

  // Trigger onEnter when the user stops typing
  useEffect(() => {
    const searchAfterNoInput = setTimeout(() => {
      onEnter(text);
    }, 300);
    return () => clearTimeout(searchAfterNoInput);
    // eslint-disable-next-line
  }, [text]);

  return (
    <SearchTextField
      inputProps={{ "data-test-id": dataTestId }}
      placeholder={placeholderText}
      onChange={(e) => {
        setText(e.target.value);
      }}
      onKeyPress={(ev) => {
        if (ev.key === "Enter") {
          onEnter(text);
        }
      }}
      type="search"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchField;
