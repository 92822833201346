import { gql } from "@apollo/client";

export const GET_SITES = gql`
  query SiteList($templateId: ID!) {
    siteList(templateId: $templateId) {
      id
      name
    }
  }
`;

export const GET_SITE_TEMPLATE = gql`
  query SiteTemplate($siteId: ID!) {
    siteTemplate(siteId: $siteId) {
      id
      siteGroupTemplate {
        id
        siteGroupRules {
          id
          field {
            id
            name
          }
          isShown
        }
      }
      site {
        id
        name
      }
      ruleSite {
        id
        field {
          id
          name
        }
        defaultValue
        isShown
        isActive
      }
    }
  }
`;

export const GET_ALL_SITES_FOR_GROUP = gql`
  query SiteGroupTemplate($groupId: ID!) {
    siteGroupTemplate(groupId: $groupId) {
      id
      siteTemplate {
        id
        site {
          name
        }
        ruleSite {
          id
          field {
            id
            name
          }
          defaultValue
        }
      }
    }
  }
`;

export const CREATE_SITE_TEMPLATE = gql`
  mutation createSiteTemplate(
    $templateId: ID
    $siteName: String
    $siteGroupId: ID
  ) {
    createSiteTemplate(
      templateId: $templateId
      siteName: $siteName
      siteGroupId: $siteGroupId
    ) {
      siteTemplate {
        id
      }
    }
  }
`;

export const DELETE_SITE_TEMPLATE = gql`
  mutation deleteSiteTemplate($siteId: ID) {
    deleteSiteTemplate(siteId: $siteId) {
      ok
    }
  }
`;

export const UPDATE_SITE_TEMPLATE = gql`
  mutation updateSiteTemplate($siteGroupId: ID, $siteId: ID) {
    updateSiteTemplate(siteGroupId: $siteGroupId, siteId: $siteId) {
      siteTemplate {
        id
      }
    }
  }
`;

export const UPDATE_TEMPLATE_SITE_FIELDS = gql`
  mutation UpdateTreatmentRuleSite(
    $fieldName: String!
    $siteId: ID!
    $defaultValue: String
    $isShown: Boolean
  ) {
    updateTreatmentRuleSite(
      fieldName: $fieldName
      siteId: $siteId
      defaultValue: $defaultValue
      isShown: $isShown
    ) {
      treatmentRuleSite {
        id
        defaultValue
      }
    }
  }
`;

export const UPDATE_SITE_TEMPLATE_NAME = gql`
  mutation updateSiteTemplateName($siteId: ID, $name: String) {
    updateSiteTemplateName(siteId: $siteId, name: $name) {
      siteTemplate {
        id
        site {
          id
          name
        }
      }
    }
  }
`;
