import React, { useEffect } from "react";
import {
  TableRow,
  TableBody,
  TablePagination,
  Grid,
  Button,
  styled,
  useTheme,
  Theme,
  Typography,
  TableCell,
} from "@mui/material";
import { ReviewTemplateInterface } from "./TableInterfaces";
import { constraints } from "../oars/OarTable";
import Tooltip from "components/base/Tooltip";
import {
  StyledPaper,
  StyledTableHead,
  StyledTableContainer,
  StyledTable,
  StyledTableCell,
  SmallTableCell,
  MediumTableCell,
} from "components/TableStyles";
import { Link, useLocation } from "react-router-dom";

import { directionalComparitor, sortArrayByComparitor } from "shared/utils";
import { PlaceholderRow, ROWS_PER_PAGE } from "./tableUtils";
import Checkbox from "components/base/Checkbox";
import BulkEditSelectionModal from "./BulkEditSelectionModal/BulkEditSelectionModal";
import { APPROVE } from "i18n/constants";
import { Close, Create } from "@mui/icons-material";

interface Props {
  careplans: ReviewTemplateInterface[];
  getCareplans(options: any, pageCount: number, rowsCount: number): void;
  careplansLength: number;
  filterChange: boolean;
  bulkEditActive: boolean;
  setBulkEditActive: (templates: boolean) => void;
  bulkEditList: any[];
  setBulkEditList: (templates: any[]) => void;
  loading: boolean;
  selectedRegion: string;
}

const SIM_COLUMN_WIDTHS = ["15%", "15%", "40%", "15%", "15%"];

const PRES_COLUMN_WIDTHS = ["40%", "15%", "15%", "15%", "15%"];

const FittedTableCell = styled(StyledTableCell)`
  && {
    width: 6vw;
    max-width: 6vw;
    min-width: 6vw;
  }
`;

const LargeTableCell = styled(StyledTableCell)`
  && {
    width: 20vw;
    max-width: 20vw;
    min-width: 20vw;
    font-weight: bold;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.palette.primary.main};
`;

interface BulkEditCheckboxProps {
  template: ReviewTemplateInterface;
  bulkEditList: any[];
  setBulkEditList: (templates: ReviewTemplateInterface[]) => void;
}

const bulkEditValidForTemplate = (template: ReviewTemplateInterface) =>
  template.careplanTreatment.length === 1 && template.status === APPROVE;
const disableBulkEditForTemplate = (template: ReviewTemplateInterface) =>
  !bulkEditValidForTemplate(template);

const BulkEditCheckbox = ({
  template,
  bulkEditList,
  setBulkEditList,
}: BulkEditCheckboxProps) => {
  const [checked, setChecked] = React.useState<boolean>(false);

  const disabled = disableBulkEditForTemplate(template);

  const tooltipTitle =
    template.status !== APPROVE
      ? `Bulk Edit is unavailable for this template as it is in a ${template.status} status`
      : "Bulk Edit is unavailable for this template as it contains more than one treatment group. Please edit via the normal workflow";

  const handleChange = () => {
    const newBulkEditList = [...bulkEditList];
    const i = bulkEditList.findIndex(
      (t: ReviewTemplateInterface) => t.id === template.id,
    );
    if (i > -1) {
      newBulkEditList.splice(i, 1);
    } else {
      newBulkEditList.push(template);
    }
    setBulkEditList(newBulkEditList);
  };

  useEffect(() => {
    if (bulkEditList.some((t) => t.id === template.id)) {
      setChecked(true);
    } else {
      setChecked(false);
    }
    // eslint-disable-next-line
  }, [bulkEditList]);

  return disabled ? (
    <Tooltip title={tooltipTitle}>
      <span>
        <Checkbox
          disabled={disabled}
          onChange={handleChange}
          data-test-id={`bulk-edit-select-${template.id}`}
        />
      </span>
    </Tooltip>
  ) : (
    <Checkbox
      checked={checked}
      onChange={handleChange}
      data-test-id={`bulk-edit-select-${template.id}`}
    />
  );
};
const careplanPrescriptionRow = (
  careplan: ReviewTemplateInterface,
  bulkEditActive: boolean,
  bulkEditList: any[],
  setBulkEditList: (templates: ReviewTemplateInterface[]) => void,
  theme: Theme,
): JSX.Element[] => {
  const backgroundStyling = { backgroundColor: undefined };
  const siteBackgroundStyling = {
    backgroundColor: theme.palette.grey[100],
  };

  const groupRows = careplan.careplanTreatment.map(
    (values: any, groupIndex: number) => {
      const siteRows = values.siteTemplate.map((site: any, index: number) => {
        const isLastSite = index === values.siteTemplate.length - 1;
        const hasMultipleGroups = careplan.careplanTreatment.length > 1;
        const isLastGroup =
          groupIndex === careplan.careplanTreatment.length - 1;
        const showBottomBorder =
          hasMultipleGroups && isLastSite && !isLastGroup;
        const cellStyling = {
          color: !careplan.isActive
            ? theme.palette.grey[600]
            : theme.palette.text.primary,
          borderBottomWidth: showBottomBorder ? 3 : undefined,
        };
        const siteCell = { ...cellStyling, paddingLeft: "50px" };

        const getSiteValue = (field: string): string => {
          const defaultValue = site.ruleSite.find(
            (x: any) => x.field.name === field,
          )?.defaultValue;
          return defaultValue && JSON.parse(defaultValue);
        };

        // Getting field values
        const name = site.site.name;
        const dose = getSiteValue("Dose");
        const fractions = getSiteValue("Fractions");
        const technique = getSiteValue("Technique");
        const modality = getSiteValue("Modality");

        return (
          <TableRow key={site.id} style={siteBackgroundStyling}>
            <MediumTableCell style={siteCell}>{name}</MediumTableCell>
            <SmallTableCell style={cellStyling}>{dose}</SmallTableCell>
            <SmallTableCell style={cellStyling}>{fractions}</SmallTableCell>
            <SmallTableCell style={cellStyling}>{technique}</SmallTableCell>
            <SmallTableCell style={cellStyling}>{modality}</SmallTableCell>
          </TableRow>
        );
      });
      return siteRows;
    },
  );

  const header = (
    <TableRow
      style={backgroundStyling}
      data-test-id={`review-results-${careplan.id}`}
      key={careplan.id}
    >
      <LargeTableCell colSpan={5}>
        {bulkEditActive && (
          <BulkEditCheckbox
            template={careplan}
            bulkEditList={bulkEditList}
            setBulkEditList={setBulkEditList}
          ></BulkEditCheckbox>
        )}
        <span>
          {careplan.name} (ID: {careplan.id}){" "}
        </span>
        <StyledLink to={`/template/${careplan.id}`}>Open</StyledLink>
      </LargeTableCell>
    </TableRow>
  );
  return [header, ...groupRows];
};

const careplanSimRow = (
  careplan: ReviewTemplateInterface,
  bulkEditActive: boolean,
  bulkEditList: any[],
  setBulkEditList: (templates: ReviewTemplateInterface[]) => void,
  theme: any,
): JSX.Element[] => {
  // We want a row per site group -> organ
  const groupRows = careplan.careplanTreatment.map(
    (group: any, groupIndex: number) => {
      // For each site group
      const parsedOARs = group?.oar;
      const sites = group.siteTemplate.map((site: any) => site.site.name);
      const siteNames = sites.join(", ");

      const simValues = group.siteGroupRules
        .filter((x: any) => x.field.name !== "OAR" && x.isShown)
        .map((simValue: any) => {
          return (
            <div key={simValue.id} style={{ whiteSpace: "pre-wrap" }}>
              <Typography variant="subtitle2" display="inline">
                {simValue.field.name}:{" "}
              </Typography>
              <Typography variant="body2" display="inline">
                {JSON.parse(simValue.defaultValue)}
              </Typography>
            </div>
          );
        });

      if (!parsedOARs || parsedOARs.length === 0) {
        // If there's no OARs defined, don't need to do anything fancy
        const showCareplanName = groupIndex === 0;
        const careplanRowSpan = careplan.careplanTreatment.length;
        return (
          <TableRow key={group.id}>
            {showCareplanName && (
              <SmallTableCell rowSpan={careplanRowSpan}>
                <Grid container wrap={"nowrap"}>
                  {bulkEditActive && (
                    <Grid item>
                      <BulkEditCheckbox
                        template={careplan}
                        bulkEditList={bulkEditList}
                        setBulkEditList={setBulkEditList}
                      />
                    </Grid>
                  )}
                  <Grid item>
                    {careplan.name} <br /> ID: {careplan.id}{" "}
                    <StyledLink to={`/template/${careplan.id}`}>
                      Open
                    </StyledLink>
                  </Grid>
                </Grid>
              </SmallTableCell>
            )}
            <SmallTableCell>{siteNames}</SmallTableCell>
            <SmallTableCell>{simValues}</SmallTableCell>
            <SmallTableCell colSpan={2}>No OARs Defined</SmallTableCell>
          </TableRow>
        );
      }

      const rows = parsedOARs.map((oarValues: any, organIndex: number) => {
        // Each organ will be its own row
        const oarConstraints = constraints(oarValues).map(
          (cont: any, index: number) => (
            <span key={`${group.id}-${index}`}>
              {cont}
              <br />
            </span>
          ),
        );

        // The careplan.name has to span the whole thing (groups * organs) (should only be on the first organ)
        const showCareplanName = organIndex === 0 && groupIndex === 0;
        const careplanRowSpan =
          parsedOARs.length * careplan.careplanTreatment.length;
        // The group.name has to span the group (should be on the first organ in a group)
        const showSiteNames = organIndex === 0;
        const groupNameRowSpan = parsedOARs.length;

        return (
          <TableRow key={`${group.id}-${organIndex}`}>
            {showCareplanName && (
              <SmallTableCell rowSpan={careplanRowSpan}>
                <Grid container wrap={"nowrap"}>
                  {bulkEditActive && (
                    <Grid item>
                      <BulkEditCheckbox
                        template={careplan}
                        bulkEditList={bulkEditList}
                        setBulkEditList={setBulkEditList}
                      />
                    </Grid>
                  )}
                  <Grid item>
                    {careplan.name} <br /> ID: {careplan.id}{" "}
                    <StyledLink to={`/template/${careplan.id}`}>
                      Open
                    </StyledLink>
                  </Grid>
                </Grid>
              </SmallTableCell>
            )}
            {showSiteNames && (
              <SmallTableCell rowSpan={groupNameRowSpan}>
                {siteNames}
              </SmallTableCell>
            )}
            {showSiteNames && (
              <SmallTableCell rowSpan={groupNameRowSpan}>
                {simValues}
              </SmallTableCell>
            )}
            <SmallTableCell>{oarValues.organ}</SmallTableCell>
            <FittedTableCell>{oarConstraints}</FittedTableCell>
          </TableRow>
        );
      });
      return rows;
    },
  );

  return groupRows;
};

const CareplanReviewTable = ({
  getCareplans,
  careplans,
  careplansLength,
  filterChange,
  bulkEditList,
  setBulkEditList,
  bulkEditActive,
  setBulkEditActive,
  selectedRegion,
  loading,
}: Props) => {
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(
    ROWS_PER_PAGE[0],
  );
  const theme = useTheme();
  const [page, setPage] = React.useState<number>(0);
  const orderDirection = "asc";
  const orderBy = "";
  const location = useLocation();

  const isActiveItem = (itemLocation: string): boolean => {
    return location.pathname.startsWith(itemLocation);
  };

  const [bulkEditAllSelected, setBulkEditAllSelected] =
    React.useState<boolean>(false);
  const [bulkEditModalOpen, setBulkEditModalOpen] =
    React.useState<boolean>(false);
  const [totalBulkEditSelected, setTotalBulkEditSelected] =
    React.useState<number>(0);

  useEffect(() => {
    if (!careplans.length) {
      return;
    }
    // Counter for display in button
    setTotalBulkEditSelected(bulkEditList.length);

    // Determine whether or not the 'Select All' box should be checked
    const potentialPageEnd = page * rowsPerPage + rowsPerPage;
    const lastCarePlanOnPage =
      potentialPageEnd < careplansLength ? potentialPageEnd : careplansLength;
    let allSelected = true;
    let listHasSelectableCareplans = false;
    for (let i = page * rowsPerPage; i < lastCarePlanOnPage; ++i) {
      const bulkEditValid = bulkEditValidForTemplate(careplans[i]);
      if (bulkEditValid) listHasSelectableCareplans = true;
      if (!bulkEditList.some((c) => c.id === careplans[i].id) && bulkEditValid)
        allSelected = false;
    }
    if (!listHasSelectableCareplans) allSelected = false;
    setBulkEditAllSelected(allSelected);
    // eslint-disable-next-line
  }, [bulkEditList, careplans]);

  const bulkEditSelectAllToggle = () => {
    const newBulkEditList = [...bulkEditList];
    const potentialPageEnd = page * rowsPerPage + rowsPerPage;
    const lastCarePlanOnPage =
      potentialPageEnd < careplansLength ? potentialPageEnd : careplansLength;
    for (let i = page * rowsPerPage; i < lastCarePlanOnPage; ++i) {
      const careplan = careplans[i];
      const isCurrentlySelected = bulkEditList.some(
        (c) => c.id === careplan.id,
      );
      const disabled = disableBulkEditForTemplate(careplan);
      if (disabled) continue;
      if (isCurrentlySelected && bulkEditAllSelected) {
        newBulkEditList.splice(newBulkEditList.indexOf(careplan), 1);
      }

      if (
        !isCurrentlySelected &&
        !bulkEditAllSelected &&
        bulkEditValidForTemplate(careplan)
      ) {
        newBulkEditList.push(careplan);
      }
    }
    setBulkEditList(newBulkEditList);
  };

  useEffect(() => {
    if (filterChange) {
      setPage(0);
      handlePagination(0, false, 0);
    }
    // eslint-disable-next-line
  }, [filterChange]);

  const handlePagination = (
    pageNumber: any,
    includesRowsPerPage: boolean,
    row: any,
  ) => {
    // If attempting to reach a page beyond current results amount, exit function
    if (pageNumber && row && pageNumber * row >= careplans.length) return;
    setPage(pageNumber);
    if (includesRowsPerPage) setRowsPerPage(row);
    const pageCount = pageNumber ? pageNumber : 0;
    const rowsCount = row ? row : rowsPerPage;
    getCareplans(null, pageCount, rowsCount);
  };

  const careplanRow = isActiveItem("/review/sim")
    ? careplanSimRow
    : careplanPrescriptionRow;
  const HEADINGS = isActiveItem("/review/sim")
    ? ["Careplan", "Sites", "Group Info", "Organ", "Constraints"]
    : ["Template & Site", "Dose", "Fractions", "Technique", "Modality"];
  return (
    <>
      <StyledPaper>
        {bulkEditActive && (
          <Grid container spacing={1}>
            <Grid item>
              <Checkbox
                checked={bulkEditAllSelected}
                onChange={bulkEditSelectAllToggle}
                data-test-id={"select-all-bulk-edit"}
              />
              <label>Select All</label>
            </Grid>

            <Grid item>
              <Button
                variant="text"
                color="primary"
                data-test-id="bulk-edit-clear"
                disabled={false}
                onClick={() => {
                  setBulkEditList([]);
                }}
              >{`${totalBulkEditSelected} Selected/clear`}</Button>
            </Grid>

            <Grid item>
              <Button
                variant="text"
                color="primary"
                data-test-id="bulk-edit-modal-button"
                disabled={bulkEditList.length === 0}
                onClick={() => {
                  setBulkEditModalOpen(true);
                }}
                startIcon={<Create />}
              >
                Bulk Edit OAR
              </Button>
            </Grid>

            <Grid item>
              <Button
                variant="text"
                color="primary"
                data-test-id="bulk-edit-cancel"
                onClick={() => {
                  setBulkEditList([]);
                  setBulkEditActive(false);
                }}
                startIcon={<Close />}
              >
                {"Cancel Bulk Edit"}
              </Button>
            </Grid>
          </Grid>
        )}
        <StyledTableContainer>
          <StyledTable stickyHeader data-test-id={"care-plan-table"}>
            <StyledTableHead>
              <TableRow>
                {HEADINGS.map((text: string, index: number) => {
                  const width = isActiveItem("/review/sim")
                    ? SIM_COLUMN_WIDTHS[index]
                    : PRES_COLUMN_WIDTHS[index];
                  return (
                    <TableCell
                      key={`heading-${text}`}
                      sx={{
                        maxWidth: width,
                        width,
                        fontWeight: "bold",
                        paddingY: 1,
                      }}
                    >
                      {text}
                    </TableCell>
                  );
                })}
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {careplans.length ? (
                sortArrayByComparitor(
                  careplans,
                  directionalComparitor(orderDirection, orderBy),
                )
                  // eslint-disable-next-line
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((careplan: ReviewTemplateInterface) => {
                    return careplanRow(
                      careplan,
                      bulkEditActive,
                      bulkEditList,
                      setBulkEditList,
                      theme,
                    );
                  })
              ) : (
                <PlaceholderRow loading={loading} colSpan={5} />
              )}
            </TableBody>
          </StyledTable>
        </StyledTableContainer>
        <TablePagination
          rowsPerPageOptions={ROWS_PER_PAGE}
          component="div"
          count={careplansLength}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(_, newPage) => handlePagination(newPage, false, 0)}
          onRowsPerPageChange={(e) => {
            handlePagination(0, true, parseInt(e.target.value, 10));
          }}
        />
      </StyledPaper>
      <BulkEditSelectionModal
        open={bulkEditModalOpen}
        setOpen={setBulkEditModalOpen}
        bulkEditList={bulkEditList}
        selectedRegion={selectedRegion}
      />
    </>
  );
};

export default CareplanReviewTable;
