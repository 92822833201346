import React from "react";
// eslint-disable-next-line
import { FieldProps, useFormikContext } from "formik";

import MenuItem from "@mui/material/MenuItem";
import Select from "components/base/Select";
import { FormControl, InputLabel, SelectChangeEvent } from "@mui/material";

interface Props extends FieldProps {
  options: any[];
  handleMutation: any;
  noEmptyOption: boolean;
  disabled: boolean;
  placeholder?: string;
}

const NakedSelectField = ({
  field,
  options,
  handleMutation,
  placeholder = "Please select",
  disabled,
}: Props) => {
  const { setFieldValue } = useFormikContext();
  const { name = "", value = "" } = field;

  const handleChange = ({ target: { value } }: SelectChangeEvent<unknown>) => {
    setFieldValue(name, value);
    handleMutation && handleMutation(name, value);
  };

  return (
    <FormControl variant="outlined" fullWidth={true}>
      <InputLabel shrink={false} id="select-label">
        {value.length ? "" : placeholder}
      </InputLabel>
      <Select
        labelId={`${name}-select-label-id`}
        id={`${name}-select-id`}
        value={value}
        disabled={disabled}
        onChange={handleChange}
        width={"100%"}
        onClear={() => setFieldValue(name, "")}
        inputProps={{ "data-test-id": `${name}-select` }}
      >
        {options?.map((option: { label: string; value: any }, idx: number) => (
          <MenuItem key={idx} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default NakedSelectField;
