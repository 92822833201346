import React, { RefObject } from "react";
import { styled, useTheme } from "@mui/material";

export const ScrollbarDiv = styled("div")`
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    all: auto !important;
    background-color: ${(props) => props.theme.palette.grey[100]};
  }
  &::-webkit-scrollbar-thumb {
    all: auto !important;
    background-color: ${(props) => props.theme.palette.grey[300]};
    border-radius: 8px;
  }
  &::-webkit-scrollbar-corner {
    background-color: ${(props) => props.theme.palette.grey[100]};
  }
`;

const VScrollbarPaddingDiv = styled("div")<{ height: number | undefined }>`
  min-height: ${(props: { height: number | undefined }) =>
    props.height ? `${props.height}px` : "40px"};
  width: 20px;
`;

const HScrollbarPaddingDiv = styled("div")<{ width: number | undefined }>`
  min-width: ${(props: { width: number | undefined }) =>
    props.width ? `${props.width}px` : "40px"};
  height: 15px;
`;

const VScrollbarVirtualContainer = styled("div")<{ height: number }>`
  min-height: ${(props: { height: number }) => props.height}px;
  width: 20px;
  background-color: ${(props) => props.theme.palette.grey[100]};
`;

const HScrollbarVirtualContainer = styled("div")<{ width: number }>`
  min-width: ${(props: { width: number }) => props.width}px;
  height: 6px;
  background-color: ${(props) => props.theme.palette.grey[100]};
`;

interface VerticalScrollBarProps {
  parentRef?: any;
  scrollbarRef?: RefObject<HTMLDivElement>;
  paddingHeight: number;
  bounds: {
    clientHeight: number;
    clientWidth: number;
    scrollHeight: number;
    scrollWidth: number;
  };
}

interface HorizontalScrollBarProps {
  parentRef?: any;
  scrollbarRef?: RefObject<HTMLDivElement>;
  paddingWidth: number;
  bounds: {
    clientHeight: number;
    clientWidth: number;
    scrollHeight: number;
    scrollWidth: number;
  };
}

export const VerticalScrollBar = (props: VerticalScrollBarProps) => {
  const { paddingHeight, bounds, scrollbarRef, parentRef } = props;
  return (
    <div style={{ display: "flex", flexDirection: "column", width: "15px" }}>
      <VScrollbarPaddingDiv height={paddingHeight} />
      <ScrollbarDiv
        ref={scrollbarRef}
        style={{
          maxHeight: `${bounds.clientHeight - paddingHeight}px`,
          overflowY: "auto",
          overflowX: "hidden",
          width: "15px",
        }}
        onScroll={(event) => {
          if (parentRef.current)
            parentRef.current.scrollTop = event.currentTarget.scrollTop;
        }}
      >
        <VScrollbarVirtualContainer
          height={bounds.scrollHeight - paddingHeight}
        />
      </ScrollbarDiv>
    </div>
  );
};

export const HorizontalScrollBar = (props: HorizontalScrollBarProps) => {
  const { paddingWidth, bounds, scrollbarRef, parentRef } = props;
  const theme = useTheme();
  return (
    <div
      style={{
        display: "flex",
        height: "15px",
        width: "fit-content",
        backgroundColor: theme.palette.grey[100],
      }}
    >
      <HScrollbarPaddingDiv width={paddingWidth} />
      <ScrollbarDiv
        ref={scrollbarRef}
        style={{
          maxWidth: `${bounds.clientWidth - paddingWidth + 15}px`,
          overflowX: "auto",
          overflowY: "hidden",
          height: "15px",
          backgroundColor: theme.palette.grey[100],
        }}
        onScroll={(event) => {
          if (parentRef && parentRef.current) {
            parentRef.current.scrollLeft = event.currentTarget.scrollLeft;
          }
        }}
      >
        <HScrollbarVirtualContainer
          width={bounds.scrollWidth - paddingWidth + 15}
        />
      </ScrollbarDiv>
    </div>
  );
};
