import React, { Component, Fragment } from "react";
import { Grid, Button, MenuItem, IconButton, Divider } from "@mui/material";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutline from "@mui/icons-material/RemoveCircleOutline";
import PeerReviewSelect from "./PeerReviewSelect";
import { prescriptionContent } from "i18n/constants";
import OarDivider from "../oars/OarDivider";
import { GenericRule } from "./constants";
import PeerReviewTextField from "./PeerReviewTextField";
import PeerReviewBooleanField from "./PeerReviewBooleanField";

interface SharedProps {
  id: string;
  label: string;
  readOnly?: boolean;
  onChange: (index: number, value: GenericRule) => void;
  onBlur?: () => void;
}

interface NoListProps extends SharedProps {
  index: number;
  length: number;
  value: GenericRule;
  removeRow: () => void;
}

interface Props extends SharedProps {
  value: GenericRule[];
  addRow: () => void;
  removeRow: (item: any) => void;
}

const OPERATOR_OPTIONS = [
  { value: "<=", label: "<=" },
  { value: "<", label: "<" },
  { value: "=", label: "=" },
];

class PeerReviewRow extends Component<NoListProps> {
  public render(): JSX.Element {
    const rule = this.props.value;
    const fieldName = rule?.field?.name || "";

    const defaultValue =
      rule?.defaultValue?.length > 0 ? rule?.defaultValue : [""];
    const operator = rule.operator;
    const id = `${this.props.id}-${this.props.index}`;
    const fieldList = Object.entries(prescriptionContent?.fields)
      .map((field) => ({
        label: field[1],
        value: field[0],
      }))
      .concat({ label: "Is Multidose", value: "Is_Multidose" });

    const isBooleanField =
      !!fieldName &&
      prescriptionContent.booleanFields.includes(String(fieldName));

    return (
      <Fragment>
        <Grid container alignItems="center" direction="row">
          <Grid item xs={4}>
            <PeerReviewSelect
              width="96%"
              id={`field-name-${id}`}
              inputProps={{ "data-test-id": `field-name-${id}-select` }}
              onChange={(e: any) => {
                const newValue = {
                  id: rule?.id,
                  field: {
                    name: e.target.value,
                  },
                  defaultValue: rule?.defaultValue,
                  operator: rule?.operator,
                };
                this.props.onChange(this.props.index, newValue);
              }}
              value={fieldName}
              disabled={this.props.readOnly}
            >
              {fieldList.map(
                (option: { label: string; value: any }, idx: number) => (
                  <MenuItem key={idx} value={option.value}>
                    {option.label}
                  </MenuItem>
                ),
              )}
            </PeerReviewSelect>
          </Grid>
          <Grid item xs={1}>
            <Grid container>
              <Divider orientation="vertical" flexItem />
              <PeerReviewSelect
                id={`operator-${id}`}
                inputProps={{ "data-test-id": `operator-${id}-select` }}
                onChange={(event) => {
                  const newValue = {
                    id: rule?.id,
                    field: rule?.field,
                    defaultValue: rule?.defaultValue,
                    operator: event.target.value as string,
                  };
                  this.props.onChange(this.props.index, newValue);
                }}
                value={operator}
                disabled={this.props.readOnly || isBooleanField}
              >
                {OPERATOR_OPTIONS.map(
                  (option: { label: string; value: any }, idx: number) => (
                    <MenuItem key={idx} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ),
                )}
              </PeerReviewSelect>
              <Divider orientation="vertical" flexItem />
            </Grid>
          </Grid>
          {isBooleanField ? (
            <PeerReviewBooleanField
              id={id}
              rule={rule}
              defaultValue={defaultValue.map(
                (val: string | boolean): boolean | undefined => {
                  if (val === true || val === "true") return true;
                  if (val === false || val === "false") return false;
                  return undefined;
                },
              )}
              index={this.props.index}
              readOnly={this.props.readOnly}
              onBlur={this.props.onBlur}
              onChange={this.props.onChange}
            />
          ) : (
            <PeerReviewTextField
              id={id}
              rule={rule}
              defaultValue={defaultValue.map((val: string | boolean): string =>
                String(val),
              )}
              index={this.props.index}
              readOnly={this.props.readOnly}
              onBlur={this.props.onBlur}
              onChange={this.props.onChange}
            />
          )}
          <Grid item xs={1}>
            <IconButton
              id={`remove-${id}`}
              style={{ padding: "4px" }}
              onClick={(): void => this.props.removeRow()}
              size="large"
            >
              <RemoveCircleOutline color="error" />
            </IconButton>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

class PeerReviewField extends Component<Props, { value: GenericRule[] }> {
  public constructor(props: any) {
    super(props);
    this.state = {
      value: [],
    };
    this.onAddRow = this.onAddRow.bind(this);
    this.onRemoveRow = this.onRemoveRow.bind(this);
  }

  private onAddRow(): void {
    this.props.addRow();
  }

  private onRemoveRow(item: any): void {
    this.props.removeRow(item);
  }

  public render(): JSX.Element {
    const { id, label } = this.props;

    const defaultValues: GenericRule[] = [
      {
        field: undefined,
        defaultValue: [],
        operator: "=",
      },
    ];
    const valueList =
      this.props.value && this.props.value.length
        ? this.props.value
        : defaultValues;
    return (
      <>
        {valueList.map((item: any, index: number) => (
          <div key={`${label} + ${index}`}>
            <PeerReviewRow
              removeRow={() => this.onRemoveRow(index)}
              index={index}
              value={item}
              id={id}
              label={this.props.label}
              onChange={this.props.onChange}
              readOnly={this.props.readOnly}
              length={valueList.length}
            />
            <OarDivider />
          </div>
        ))}
        <Button
          id={`add-row-${id}`}
          onClick={() => this.onAddRow()}
          disabled={this.props.readOnly}
          startIcon={<AddCircleOutline color="primary" />}
        >
          Add Another
        </Button>
      </>
    );
  }
}

export default PeerReviewField;
