import {
  StyledExternalLink,
  StyledOpenInNewIcon,
} from "components/CommonStyledComponents";
import { formatEvidence } from "i18n/constants";
import { TemplateInterface } from "interfaces/templates";
import React from "react";

interface Props {
  template: TemplateInterface;
}
const EvidenceLink = ({ template }: Props) => {
  if (!template.evidenceLink)
    return (
      <span>
        {formatEvidence(template.evidenceId, template.evidenceVersion)}
      </span>
    );

  return (
    <StyledExternalLink
      href={
        template.evidenceLink.includes("//")
          ? template.evidenceLink
          : `https://${template.evidenceLink}`
      }
      target="_blank"
      rel="noopener noreferrer"
    >
      {formatEvidence(template.evidenceId, template.evidenceVersion)}
      <StyledOpenInNewIcon />
    </StyledExternalLink>
  );
};

export default EvidenceLink;
