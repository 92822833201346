import { CancelOutlined } from "@mui/icons-material";
import React from "react";
import { styled } from "@mui/material";

interface Props {
  text?: string;
}
const ErrorInvalidMessage = styled("div")`
  color: ${(props) => props.theme.palette.error.main};
  font-size: 13px;
  padding: 8px 0px 0px 0px;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const InvalidMessage = ({ text = "This value is required" }: Props) => (
  <ErrorInvalidMessage>
    <CancelOutlined fontSize="inherit" />
    <span>{text}</span>
  </ErrorInvalidMessage>
);

export default InvalidMessage;
