import { TextField as MUITextField, styled } from "@mui/material";

const Input = styled(MUITextField)`
  width: 100%;
  border: ${(props) => props.theme.palette.error.main};
  ${(props) =>
    props.disabled
      ? {
          backgroundColor: props.theme.palette.grey[100],
        }
      : ""};
`;

export default Input;
