import React from "react";
import { FieldProps } from "formik";
import Input from "components/base/Input";
import { formatInputValue } from "shared/utils";

interface Props extends FieldProps {
  disabled: boolean;
  handleMutation: any;
  type: string;
  multiline: boolean;
  id: any;
  inputProps: any;
  error?: boolean;
  inBulkEdit?: boolean;
}

const NakedInputField = ({
  field,
  id,
  handleMutation,
  disabled,
  type = "text",
  multiline = false,
  error = false,
  inputProps,
}: Props) => {
  const { onBlur, name, value, onChange } = field;
  const handleBlur = (event: React.ChangeEvent<any>) => {
    onBlur(event);
    handleMutation && handleMutation(name, event.target.value);
  };

  return (
    <Input
      name={name}
      value={formatInputValue(value)}
      onChange={onChange}
      id={`${id}-input`}
      variant="outlined"
      onBlur={handleBlur}
      size="medium"
      disabled={disabled}
      type={type}
      multiline={multiline}
      error={error}
      inputProps={{ ...inputProps, "data-test-id": `${name}-input` }}
    />
  );
};

export default NakedInputField;
