import SideBarNav from "components/SideBarNav/SideBarNav";
import ConfirmationDialog from "components/base/ConfirmationDialog";
import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Route, Switch, useParams } from "react-router-dom";
import * as yup from "yup";
import Voluming from "pages/Voluming";
import Review from "pages/bulkEdit/Review";
import { GET_CAREPLAN_TEMPLATE } from "graphql/TemplateQueries";
import { GET_SITE_GROUP_TEMPLATE } from "graphql/SiteGroupQueries";
import { SUBMIT_BULK_EDIT } from "graphql/BulkEditQueries";
import BottomNav from "components/base/BottomNav";
import { getActiveStep } from "shared/utils";
import { StandardPaddedContainer } from "components/CommonStyledComponents";
import { BULK_EDIT_REVIEW, VOLUMING } from "i18n/constants";
import { styled } from "@mui/material";

export const tempValidationSchema = yup.object({
  temp: yup.boolean().oneOf([true], "Click this to be valid"),
});

interface ParamTypes {
  templateId: string;
  form: string;
  groupId: string;
}

const TemplateMainContainer = styled("div")`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: calc(100% - 64px);
`;

const TemplateFormContainer = styled("div")`
  flex: 1;
  height: 100%;
  overflow: auto;
`;

const BulkEdit = () => {
  const { templateId, groupId } = useParams<ParamTypes>();
  const history = useHistory();

  const [siteGroupIds, setSiteGroupIds] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const bulkEditIds = JSON.parse(sessionStorage.getItem("bulkEditIds") || "[]");

  const activeStep = getActiveStep();

  useQuery(GET_CAREPLAN_TEMPLATE, {
    variables: { templateId },
    onCompleted: (data) => {
      const siteGroups = data.template.careplanTreatment;
      setSiteGroupIds(siteGroups.map((siteGroup: any) => siteGroup.id));
    },
  });

  const [submit] = useMutation(SUBMIT_BULK_EDIT);
  const handleSubmit = () => {
    sessionStorage.removeItem("bulkEditActive");
    sessionStorage.removeItem("bulkEditList");
    submit({ variables: { templateId, bulkEditIds } });
    history.push("/review");
  };

  const { data } = useQuery(GET_SITE_GROUP_TEMPLATE, {
    fetchPolicy: "cache-and-network",
    variables: { groupId },
  });
  const groupRules =
    data?.siteGroupTemplate?.siteGroupRules.filter(
      (rule: any) => rule.isSelectedForBulkEdit,
    ) || [];

  const navigationVars: any = {
    [VOLUMING]: {
      nextUrl: `/bulk_edit/${templateId}/review/${groupId}`,
      backUrl: "",
      nextLabel: "Continue",
    },

    [BULK_EDIT_REVIEW]: {
      nextUrl: "",
      backUrl: `/bulk_edit/${templateId}/voluming/${groupId}`,
      nextLabel: "Submit Changes",
      nextFunction: () => setIsModalVisible(true),
      disableNext: groupRules.length === 0,
    },
  };

  return (
    <>
      <ConfirmationDialog
        title="Submit Changes"
        bodyText={
          <p>
            Submitting will apply all changes to the selected templates and set
            them to the &apos;Test&apos; status. Each template will need to be
            manually verified and approved.
            <br />
            <br />
            Please note when editing a large number of templates there may be a
            delay between submission and the templates being updated on the
            Search page
          </p>
        }
        confirmationText="Submit"
        cancelText="Cancel"
        setIsVisible={setIsModalVisible}
        isVisible={isModalVisible}
        onConfirmation={handleSubmit}
      />

      <TemplateMainContainer>
        <SideBarNav valid={true} isRo={true} isBulkEdit={true} />
        <TemplateFormContainer>
          <StandardPaddedContainer>
            <Switch>
              <Route
                path={"/bulk_edit/:templateId/voluming/:groupId"}
                render={() => (
                  <Voluming disableEditing={false} groupIds={siteGroupIds} />
                )}
              />
              <Route
                path={"/bulk_edit/:templateId/review"}
                render={() => (
                  <Review
                    data={groupRules}
                    oar={data?.siteGroupTemplate?.oar}
                  />
                )}
              />
            </Switch>
          </StandardPaddedContainer>
        </TemplateFormContainer>

        <BottomNav
          backUrl={navigationVars[activeStep].backUrl}
          nextUrl={navigationVars[activeStep].nextUrl}
          disableBack={false}
          disableNext={false}
          nextLabel={navigationVars[activeStep].nextLabel}
          backLabel={navigationVars[activeStep].backLabel}
          nextFunction={navigationVars[activeStep].nextFunction}
        />
      </TemplateMainContainer>
    </>
  );
};

export default BulkEdit;
