import { ColumnInfo } from "./TableComponents";

const constructEmptyString = (days: number[]): string => {
  if (days.length === 1) return days[0].toString();
  if (days.length === 2) return `${days[0]},${days[1]}`;
  return `${days[0]}-${days[days.length - 1]}`;
};
export const mergeEmptyDays = (days: {
  [day: number]: boolean;
}): ColumnInfo[] => {
  const mergedDays = Object.keys(days)
    .map((day) => parseInt(day))
    .sort((a, b) => a - b)
    .reduce((result: { id: number; days: number[]; empty: boolean }[], day) => {
      if (days[day]) {
        result.push({ id: day, days: [day], empty: false });
        return result;
      }
      const lastEntry = result[result.length - 1];
      if (lastEntry && lastEntry.empty) {
        lastEntry.days.push(day);
        return result;
      }
      result.push({ id: day, days: [day], empty: true });
      return result;
    }, []);
  //convert to column info
  return mergedDays.map(
    (mergedDay): ColumnInfo => {
      return mergedDay.empty
        ? {
            key: mergedDay.id,
            title: `EMPTY ${constructEmptyString(
              mergedDay.days.map((day) => day + 1)
            )}`,
          }
        : { key: mergedDay.id, title: `DAY ${mergedDay.id + 1}` };
    }
  );
};
