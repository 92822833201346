import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  styled,
} from "@mui/material";
import Checkbox from "components/base/Checkbox";

const StyledFormLabel = styled(FormLabel)`
&& {
  text-align: left;
}}
`;

interface Props {
  id?: string;
  options: string[];
  selectedOptionsUpdated: any;
  selectedOptions?: string[];
  placeholder: string;
}

const CheckboxGroupField = ({
  options,
  selectedOptions,
  selectedOptionsUpdated,
  placeholder,
}: Props) => {
  const [selectedItems, setSelectedItems] = React.useState<string[]>(
    selectedOptions || [],
  );
  const handleChange = (
    event: React.ChangeEvent<{ checked: boolean; name: string }>,
  ) => {
    const checked = event.target.checked;
    const name = event.target.name;
    const newItems = checked
      ? [...selectedItems, name]
      : selectedItems.filter((item) => item !== name);
    setSelectedItems(newItems);
    selectedOptionsUpdated(newItems);
  };
  return (
    <FormControl>
      <StyledFormLabel>{placeholder}</StyledFormLabel>
      <FormGroup row>
        {options.map((option) => (
          <FormControlLabel
            key={option}
            control={
              <Checkbox
                checked={selectedItems.indexOf(option) > -1}
                onChange={handleChange}
                name={option}
              />
            }
            label={option}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

export default CheckboxGroupField;
