import React from "react";
import { ToggleButton as MUIToggleButton, useTheme } from "@mui/material";

const ToggleButton = (props: any) => {
  const theme = useTheme();
  return (
    <MUIToggleButton
      sx={{
        color: theme.palette.text.primary,
        minWidth: "140px",
        "&.MuiToggleButton-root.Mui-selected": {
          // TODO: replace with MUI theme override
          backgroundColor: theme.palette.action.selected,
          color: theme.palette.text.primary,
        },
        ":hover": {
          backgroundColor: theme.palette.action.hover,
        },
      }}
      selected={props.selected}
      disableRipple
      disabled={props.disabled}
      {...props}
    />
  );
};

export default ToggleButton;
