import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { Field, useFormikContext } from "formik";
import FormControl from "@mui/material/FormControl";
import FormPage from "components/base/FormPage";
import Button from "components/base/Button";
import FormRow from "components/base/FormRow";
import InputField from "components/formFields/InputField";
import SelectField from "components/formFields/SelectField";
import MultiSelectField from "components/formFields/MultiSelectField";
import TreatmentSites from "components/TreatmentSites/TreatmentSites";
import CheckboxField from "components/formFields/CheckboxField";

import { informationContent, shared, getNationalName } from "i18n/constants";
import {
  GET_CAREPLAN_TEMPLATE,
  UPDATE_TEMPLATE_FIELDS,
  UPDATE_TEMPLATE_INFORMATION,
} from "graphql/TemplateQueries";
import { CHECK_DUPLICATE_NAME } from "graphql/SearchQueries";
import { GET_SUBREGIONS } from "graphql/ListDataQueries";
import { SelectOptionsInterface } from "interfaces/common";

interface Props {
  template: any;
  selectOptions: SelectOptionsInterface;
  diagnosisCategoryOptions: any[];
  siteOptions: any[];
  disableEditing: boolean;
  setFormValid: (value: boolean) => void;
}

const MAX_NEGATIVE_DAYS = 7; // locally

const DEFAULT_NEGATIVE_DAYS = [...Array(MAX_NEGATIVE_DAYS).keys()].map(
  (day) => day - MAX_NEGATIVE_DAYS
);

const Information = ({
  template,
  selectOptions,
  diagnosisCategoryOptions,
  siteOptions,
  disableEditing,
  setFormValid,
}: Props) => {
  const [updateTemplateInformation] = useMutation(UPDATE_TEMPLATE_INFORMATION);
  const { templateId } = useParams<{ templateId: string }>();
  const { heading, fields } = informationContent;
  const { values, setFieldValue, isValid }: any = useFormikContext();
  const { isMo, isRo } = template;
  const negativeDayOptions = useMemo(() => {
    const templateDays = template.negativeDays.map((a: number) => a + 1);
    return [...new Set([...DEFAULT_NEGATIVE_DAYS, ...templateDays])].sort();
  }, [template]);

  const handleMutation = (name: string, value: any) => {
    if (
      informationContent.numberFields.includes(name) &&
      (isNaN(value) || value === "")
    ) {
      // don't send if an invalid number has been passed to a numeric field
      return;
    }
    if (informationContent.numberListFields.includes(name)) {
      value = JSON.parse(value);
    }
    if (name === "negativeDays") {
      value = value.sort((a: number, b: number) => a - b);
      setFieldValue("negativeDays", value);
    }
    if (name === "daysPerCycle") {
      value = value !== "" ? parseInt(value) : null;
    }
    if (name === "numberOfCycles") {
      value = value !== "" ? parseInt(value) : null;
    }
    setFormValid(isValid);
    updateTemplateInformation({ variables: { templateId, [name]: value } });
  };
  // Sorts Tumour Stream by the number
  const extractLabel = (label: string) => Number(label.match("\\d+")![0]);

  const [updateTemplateField] = useMutation(UPDATE_TEMPLATE_FIELDS, {
    refetchQueries: [
      { query: GET_CAREPLAN_TEMPLATE, variables: { templateId } },
    ],
  });
  const handleTemplateFieldMutation = (fieldName: string, value: any) => {
    const updateVariables = { templateId, fieldName, defaultValue: value };
    updateTemplateField({ variables: updateVariables });
  };

  const { data } = useQuery(CHECK_DUPLICATE_NAME, {
    variables: {
      name: values.name,
      tumourStream: values.tumourStream,
      templateId: templateId,
    },
    fetchPolicy: "network-only",
  });
  const regions = template?.regions?.map((r: any) => r.name);
  // Prevents the subregion list from being cleared when the region list is updated
  const { data: subregions } = useQuery(GET_SUBREGIONS, {
    variables: { regions: template?.regions?.map((r: any) => r.name) },
    fetchPolicy: "cache-and-network",
  });

  const disableSubregions =
    !subregions || subregions?.subregionList.length === 0;
  const validateName = () =>
    data && data.nameSearch ? "This template name is already in use." : "";
  const validateSubregions = () =>
    !disableSubregions && !values.subregions?.length
      ? "Please select a subregion"
      : "";
  if (!subregions && !data) return <></>;
  return (
    <FormPage heading={heading}>
      <Field
        component={SelectField}
        id="tumour-stream-id"
        name="tumourStream"
        label={fields.tumourStream}
        options={diagnosisCategoryOptions.sort((a, b) =>
          extractLabel(a.label) > extractLabel(b.label) ? 1 : -1
        )}
        handleMutation={handleMutation}
        noEmptyOption
        isActive={!disableEditing}
      />
      {isRo && (
        <Field
          component={InputField}
          id="site-filter-id"
          name="siteFilter"
          label={fields.siteFilter}
          handleMutation={handleMutation}
          isActive={!disableEditing}
        />
      )}
      {isRo && (
        <Field
          component={InputField}
          id="dose-filter-id"
          name="doseFilter"
          label={fields.doseFilter}
          handleMutation={handleMutation}
          isActive={!disableEditing}
        />
      )}
      {isRo && (
        <Field
          component={InputField}
          id="technique-filter-id"
          name="techniqueFilter"
          label={fields.techniqueFilter}
          handleMutation={handleMutation}
          isActive={!disableEditing}
        />
      )}
      <Field
        component={InputField}
        id="name-id"
        name="name"
        label={fields.name}
        handleMutation={handleMutation}
        isActive={!disableEditing}
        //validate={validateName}
      />
      {isRo && (
        <Field
          component={CheckboxField}
          id="mr-linac-id"
          name="mrLinac"
          label={fields.mrLinac}
          handleMutation={handleMutation}
          isActive={!disableEditing}
          readOnly={disableEditing}
        />
      )}
      {isRo && (
        <Field
          component={CheckboxField}
          id="is-planning-automation-enabled-id"
          name="isPlanningAutomationEnabled"
          label={fields.isPlanningAutomationEnabled}
          handleMutation={handleMutation}
          isActive={!disableEditing}
          readOnly={disableEditing}
        />
      )}
      {isRo && (
        <Field
          component={CheckboxField}
          id="exclude-from-sigma-recommendations"
          name="excludeFromSigmaRecommendations"
          label={fields.excludeFromSigmaRecommendations}
          handleMutation={handleMutation}
          isActive={!disableEditing}
          readOnly={disableEditing}
        />
      )}
      {isMo && (
        <>
          <Field
            component={InputField}
            id="evidence-id"
            name="evidenceId"
            label={fields.evidenceId}
            handleMutation={handleMutation}
            isActive={!disableEditing}
          />
          <Field
            component={InputField}
            id="evidence-version"
            name="evidenceVersion"
            label={fields.evidenceVersion}
            handleMutation={handleMutation}
            isActive={!disableEditing}
          />
          <Field
            component={InputField}
            id="evidence-link"
            name="evidenceLink"
            label={fields.evidenceLink}
            handleMutation={handleMutation}
            isActive={!disableEditing}
          />
          <Field
            component={InputField}
            id="numberOfCycles"
            name="numberOfCycles"
            label={fields.numberOfCycles}
            handleMutation={handleMutation}
            readOnly={values.continuous}
            isActive={!disableEditing}
            type="number"
            inputProps={{ min: 1 }}
          />
          <Field
            component={CheckboxField}
            id="continuous"
            name="continuous"
            label={fields.continuous}
            handleMutation={handleMutation}
            isActive={!disableEditing}
            readOnly={disableEditing}
          />
          <Field
            component={InputField}
            id="daysPerCycle"
            name="daysPerCycle"
            label={fields.daysPerCycle}
            handleMutation={handleMutation}
            isActive={!disableEditing}
            type="number"
            inputProps={{ min: 1 }}
          />
          <Field
            component={CheckboxField}
            id="hasNegativeDays"
            name="hasNegativeDays"
            label={"Negative Days"}
            handleMutation={(_name: string, checked: boolean) => {
              if (!checked) {
                handleMutation("negativeDays", "[]");
                setFieldValue("negativeDays", []);
              }
            }}
            isActive={!disableEditing}
            readOnly={disableEditing}
          />
          {values.hasNegativeDays && (
            <Field
              component={MultiSelectField}
              id="negativeDays"
              name="negativeDays"
              data-cy="negative-days"
              handleMutation={handleMutation}
              isActive={!disableEditing}
              options={negativeDayOptions.map((n) => ({
                label: `${n}`,
                value: n - 1,
              }))}
              diffValueToLabel
            />
          )}
        </>
      )}
      {!disableSubregions && isRo && (
        <>
          <Field
            component={MultiSelectField}
            id="subregion"
            name="subregions"
            label={fields.subregions}
            options={subregions?.subregionList.map(
              (subregion: { name: string }) => ({
                label: subregion.name,
                value: subregion.name,
              })
            )}
            handleMutation={handleMutation}
            isActive={!disableEditing && !disableSubregions}
            validate={validateSubregions}
            placeholder={
              disableSubregions
                ? "No subregions are available for the selected regions"
                : "Select one or more subregions"
            }
            allSelected={
              values.subregions.length === subregions?.subregionList.length
            }
            allSelectedLabel={getNationalName(regions.pop())}
          />
          <FormRow label="" name="">
            <FormControl>
              <Button
                data-test-id="info-all-subregions-button"
                icon={undefined}
                disabled={disableEditing}
                onClick={() => {
                  const allSubregions = subregions?.subregionList.map(
                    (subregion: { name: string }) => subregion.name
                  );
                  setFieldValue("subregions", allSubregions);
                  handleMutation("subregions", JSON.stringify(allSubregions));
                }}
              >
                {shared.selectAllSubregions}
              </Button>
            </FormControl>
          </FormRow>
        </>
      )}
      {selectOptions.treatingDepartment && isRo && (
        <Field
          component={MultiSelectField}
          id="departments-id"
          name="Treating_Department"
          label={fields.Treating_Department}
          options={selectOptions.treatingDepartment}
          handleMutation={handleTemplateFieldMutation}
          isActive={!disableEditing}
          placeholder="Leave empty to select all departments"
        />
      )}
      {isRo && (
        <Field
          component={TreatmentSites}
          id="treatment-sites-id"
          name="treatmentSites"
          label={fields.treatmentSites}
          autocompleteLabel="Select a treatment site"
          options={siteOptions}
          templateId={templateId}
          template={template}
          isActive={!disableEditing}
        />
      )}
    </FormPage>
  );
};

export default Information;
