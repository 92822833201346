import React, { useEffect } from "react";
import { useField, useFormikContext } from "formik";
import FormRow from "components/formFields/FormikFormRow";
import { TextField, Checkbox, SliderToggle } from "./FormikFields";
import _ from "lodash";

interface Props {
  id: string;
  label: string;
  textFieldName: string;
  checkboxFieldName: string;
  checkboxType: string;
  multiline: boolean;
  disabled: boolean;
}

// This component is a composition of a checkbox/slider toggle and a text input.
// it is used in the forms where a slider/checkbox is needed to specify if the text input is
// enabled.
// On unchecking the checkbox, the text input is cleared and disabled.
const FormikInputField = (props: Props) => {
  const [textField, , helpers] = useField({
    name: props.textFieldName,
    type: "text",
  });
  let checkboxField: "" | JSX.Element | undefined = "";
  const { values, touched } = useFormikContext<any>();
  const checkboxFieldValue = _.get(values, props.checkboxFieldName);
  const checkboxFieldTouched = _.get(touched, props.checkboxFieldName);
  // Reset the text field value if the checkbox is unchecked
  useEffect(() => {
    if (checkboxFieldValue === false && textField.value !== "") {
      helpers.setValue("");
    }
  }, [checkboxFieldValue, checkboxFieldTouched, textField, helpers]);

  if (props.checkboxFieldName && props.checkboxType) {
    if (props.checkboxType === "checkbox") {
      checkboxField = (
        <Checkbox
          data-cy={`${props.id}-checkbox`}
          name={props.checkboxFieldName}
          disabled={props.disabled}
        />
      );
    } else if (props.checkboxType === "slider") {
      checkboxField = (
        <SliderToggle
          data-cy={`${props.id}-switch`}
          name={props.checkboxFieldName}
          disabled={props.disabled}
        />
      );
    }
  }
  return (
    <FormRow>
      {checkboxField}
      <div>{props.label}</div>
      <TextField
        dataCy={`${props.id}-input`}
        name={textField.name}
        disabled={
          _.get(values, props.checkboxFieldName) === false || props.disabled
        }
        multiline={props.multiline}
      />
    </FormRow>
  );
};

export default FormikInputField;
