import React from "react";
import { useParams, Link } from "react-router-dom";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";

interface ParamTypes {
  templateId: string;
}

interface Props {
  lastGroupId: string;
}

const Completed = ({ lastGroupId }: Props) => {
  const { templateId } = useParams<ParamTypes>();
  return (
    <div>
      <Typography
        data-test-id="template-completed"
        variant="h3"
        component="h3"
        style={{ marginLeft: "10px" }}
      >
        Template Completed
      </Typography>
      <Typography
        data-test-id="completed-text"
        variant="h6"
        component="div"
        style={{ marginLeft: "10px", maxWidth: "50%" }}
      >
        You have completed the workflow for the template. If you are happy with
        your changes, click Landing Page to return to the template details page
        where you can click Validate to send the template to HRO for testing.
      </Typography>
      <Link to={`/template/${templateId}/voluming/${lastGroupId}`}>
        <Button variant="contained">Back</Button>
      </Link>
      <Link to={`/template/${templateId}`}>
        <Button variant="contained">Landing Page</Button>
      </Link>
    </div>
  );
};

export default Completed;
