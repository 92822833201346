import React from "react";
import FilterList from "@mui/icons-material/FilterList";
import { Button, styled, Menu, MenuProps, useTheme } from "@mui/material";

const StyledButton = styled(Button)`
&& {
    text-transform: none;
    color: ${(props) => props.theme.palette.text.primary};
    border-color: ${(props) => props.theme.palette.grey[600]};
    min-width: 100px;
    border-radius: 2em;
    min-height: 2em;
    font-size: 16px;
    border-width: 2px;
    }
  }}
`;

const StyledMenu = (props: MenuProps) => (
  <Menu
    elevation={0}
    hideBackdrop={false}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
);

interface Props {
  children: any;
}

const SearchFilter = (props: Props): any => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <StyledButton
        aria-controls="customized-menu"
        aria-haspopup="true"
        startIcon={<FilterList />}
        data-test-id={"search-filters"}
        onClick={handleClick}
      >
        Filter
      </StyledButton>
      <StyledMenu
        sx={{
          paper: {
            border: `1px solid ${theme.palette.grey[300]}`,
          },
        }}
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {props.children}
      </StyledMenu>
    </div>
  );
};

export default SearchFilter;
