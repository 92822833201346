import React from "react";
import { Theme, Typography, TypographyProps, styled } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoIcon from "@mui/icons-material/Info";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { APPROVE, TEST, ARCHIVE, getNiceStatus } from "i18n/constants";
import { useTheme } from "@mui/material";

const getIcon = (status: string, theme: Theme) => {
  if (status === APPROVE)
    return <CheckCircleIcon style={{ color: theme.palette.success.main }} />;
  if (status === TEST)
    return <InfoIcon style={{ color: theme.palette.info.main }} />;
  if (status === ARCHIVE)
    return <RemoveCircleIcon style={{ color: theme.palette.error.main }} />;
  return <FiberManualRecordIcon style={{ color: theme.palette.grey[600] }} />;
};

const StyledDiv = styled("div")`
  display: flex;
  align-items: center;
`;

interface StyledTypographyProps extends TypographyProps {
  status: string;
}

const StyledTypography = styled(Typography)<StyledTypographyProps>`
  margin-left: 10px;
  color: ${(props) =>
    props.status === "archive"
      ? props.theme.palette.grey[600]
      : props.theme.palette.text.primary};
`;

const Status = (props: any) => {
  const theme = useTheme();
  return (
    <StyledDiv>
      {getIcon(props.status, theme)}
      <StyledTypography
        data-test-id="template-status"
        variant="h5"
        status={props.status}
      >
        {getNiceStatus(props.status)}
      </StyledTypography>
    </StyledDiv>
  );
};

export default Status;
