import { useMutation, useQuery } from "@apollo/client";
import { Form, Formik, FormikProps } from "formik";
import {
  GET_SITE_GROUP_TEMPLATE,
  UPDATE_TEMPLATE_GROUP_FIELDS,
} from "graphql/SiteGroupQueries";
import { volumingContent } from "i18n/constants";
import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import { getSelectedForBulkEdit } from "shared/utils";
import AutoSave from "./AutoSave";
import FormPage from "./base/FormPage";
import FormikInputField from "./formFields/FormikInputField";

interface AdditionalOARInfoProps {
  disableEditing: boolean;
}
interface SiteGroupRules {
  defaultValue: string;
  field: { name: string };
  isActive: boolean;
  isSelectedForBulkEdit: boolean;
  isShown: boolean;
}

interface ParamTypes {
  templateId: string;
  groupId: string;
}

interface FormikValues {
  Additional_OAR_Info: {
    text: string;
    isActive: boolean;
  };
}

const mapSiteGroupRulesToFormikValues = (
  siteGroupRules: SiteGroupRules[],
  variables: string[],
  inBulkEdit: boolean
) => {
  return siteGroupRules.reduce(
    (fieldValues: any, fieldDetails: any) => {
      const { field, defaultValue, isShown } = fieldDetails;
      const value = JSON.parse(defaultValue);
      if (variables.includes(field.name)) {
        const variableSelectedForBulkEdit = getSelectedForBulkEdit(
          siteGroupRules,
          field.name
        );
        fieldValues[field.name] = {
          text: value,
          isActive: inBulkEdit ? variableSelectedForBulkEdit : isShown,
        };
      }
      return fieldValues;
    },
    variables.reduce((fieldValues: any, variable: string) => {
      fieldValues[variable] = { text: "", isActive: false };
      return fieldValues;
    }, {})
  );
};

const AdditionalOARInfo = (props: AdditionalOARInfoProps) => {
  const { disableEditing } = props;
  const { groupId } = useParams<ParamTypes>();
  const inBulkEdit = window.location.pathname.includes("bulk_edit");
  const formikRef = useRef<FormikProps<FormikValues>>(null);
  const { data, loading } = useQuery(GET_SITE_GROUP_TEMPLATE, {
    fetchPolicy: "cache-first",
    variables: { groupId },
    onCompleted: (data) => {
      if (data?.siteGroupTemplate?.siteGroupRules) {
        // This is when the group id changes. reset the formik values
        const newFormValues = mapSiteGroupRulesToFormikValues(
          data.siteGroupTemplate.siteGroupRules,
          ["Additional_OAR_Info"],
          inBulkEdit
        );
        formikRef.current?.resetForm({ values: newFormValues });
      }
    },
  });
  const [updateTemplateGroupFields] = useMutation(
    UPDATE_TEMPLATE_GROUP_FIELDS,
    {
      refetchQueries: [
        { query: GET_SITE_GROUP_TEMPLATE, variables: { groupId } },
      ],
    }
  );

  const handleMutation = (
    fieldName: string,
    value: any,
    isActive?: boolean
  ) => {
    let variables = { groupId, fieldName, defaultValue: value.toString() };
    if (inBulkEdit) {
      variables = Object.assign({ isSelectedForBulkEdit: isActive }, variables);
    } else if (typeof isActive === "boolean") {
      variables = Object.assign({ isShown: isActive, isActive }, variables);
    }
    updateTemplateGroupFields({ variables });
  };

  const volumingFields = volumingContent.fields;
  if (loading || !data) return <div>Loading...</div>;
  // Formik requires values to be intialized when the component is mounted. If the default
  // values are initialised to empty values then upon data loading it resets the form to correct
  // values but will trigger a network update as the default values didn't match values from
  // network.
  const newFormValues = mapSiteGroupRulesToFormikValues(
    data.siteGroupTemplate.siteGroupRules,
    ["Additional_OAR_Info"],
    inBulkEdit
  );
  return (
    <Formik
      innerRef={formikRef}
      initialValues={newFormValues}
      onSubmit={(values: FormikValues) => {
        if (!disableEditing)
          handleMutation(
            "Additional_OAR_Info",
            values.Additional_OAR_Info.text,
            values.Additional_OAR_Info.isActive
          );
      }}
    >
      <Form>
        <FormPage heading="">
          <FormikInputField
            id="additional-oar-info"
            label={volumingFields.Additional_OAR_Info}
            textFieldName="Additional_OAR_Info.text"
            checkboxFieldName="Additional_OAR_Info.isActive"
            checkboxType={inBulkEdit ? "checkbox" : "slider"}
            multiline={true}
            disabled={disableEditing}
          />
          {!disableEditing && <AutoSave />}
        </FormPage>
      </Form>
    </Formik>
  );
};

export default AdditionalOARInfo;
