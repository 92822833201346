import { gql } from "@apollo/client";

export const USER_QUERY = gql`
  query User {
    user {
      id
      firstName
      lastName
      roAccess
      moAccess
      regions {
        id
        name
      }
      editRegions {
        id
        name
      }
    }
  }
`;

export const LOGOUT = gql`
  mutation Logout {
    logout {
      errors
    }
  }
`;
