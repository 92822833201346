import React from "react";
import { styled } from "@mui/material";

const Page = styled("div")`
  display: flex;
  flex-direction: column;
  margin-top: 0px;

  > div:first-of-type {
    color: ${(props) => props.theme.palette.grey[600]};
  }
`;

interface Props {
  heading?: string;
  siteHeading?: string;
  subheading?: string;
  children: any;
}

const FormPage = ({ heading, siteHeading, subheading, children }: Props) => (
  <Page>
    {heading ? <h2 data-test-id="heading">{heading}</h2> : null}
    <h3>{siteHeading}</h3>
    <div>{subheading}</div>
    <div>{children}</div>
  </Page>
);

export default FormPage;
