import React from "react";
import { useParams } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { useQuery, useMutation } from "@apollo/client";
import Divider from "@mui/material/Divider";

import FormPage from "components/base/FormPage";
import InputField from "components/formFields/InputField";
import DoseField, { DoseOperation } from "components/formFields/DoseField";
import FractionDose from "components/formFields/FractionDose";
import ToggleButtonGroupField from "components/formFields/ToggleButtonGroupField";
import SelectField from "components/formFields/SelectField";

import { yesNoOptions, prescriptionContent, isUS } from "i18n/constants";

import {
  GET_SITE_TEMPLATE,
  UPDATE_TEMPLATE_SITE_FIELDS,
} from "../graphql/SiteQueries";
import { SelectOptionsInterface } from "interfaces/common";

interface Props {
  selectOptions: SelectOptionsInterface;
  disableEditing: boolean;
  regions: string[];
}

interface ParamTypes {
  templateId: string;
  siteId: string;
}

const [prescriptionFields] = [prescriptionContent.fields];

export const prescriptionPageBack = (
  templateId: string,
  siteId: string,
  lastGroupId: string,
  siteIds: any
) => {
  const siteIndex: number = siteIds.indexOf(siteId);
  const back =
    siteIndex === 0
      ? `/template/${templateId}/simulation/${lastGroupId}`
      : `/template/${templateId}/prescription/${siteIds[siteIndex - 1]}`;
  return back;
};

export const prescriptionPageNext = (
  templateId: string,
  siteId: string,
  firstGroupId: string,
  siteIds: any
) => {
  const siteIndex: number = siteIds.indexOf(siteId);
  const next =
    siteIndex < siteIds.length - 1
      ? `/template/${templateId}/prescription/${siteIds[siteIndex + 1]}`
      : `/template/${templateId}/voluming/${firstGroupId}`;
  return next;
};

const Prescription = ({ selectOptions, disableEditing, regions }: Props) => {
  const { siteId } = useParams<ParamTypes>();
  const { heading, fields } = prescriptionContent;

  const [updateTemplateSiteFields] = useMutation(UPDATE_TEMPLATE_SITE_FIELDS);
  const { data, loading, error } = useQuery(GET_SITE_TEMPLATE, {
    fetchPolicy: "no-cache",
    variables: { siteId },
  });

  // Needed before data manipulation to prevent stale graphql data changing values
  if (loading) return <div>Loading ...</div>;
  if (error) return <div>Error, please contact support</div>;

  const ruleSite = data?.siteTemplate?.ruleSite || [];
  const initActiveFields = ruleSite.reduce(
    (prescriptionFields: any, fieldDetails: any) => {
      const { field, isShown } = fieldDetails;
      prescriptionFields[field.name] = isShown;
      return prescriptionFields;
    },
    {}
  );

  const defaultValues = ruleSite.reduce(
    (fieldValues: any, fieldDetails: any) => {
      const { field, defaultValue } = fieldDetails;
      const value = JSON.parse(defaultValue);
      if (!prescriptionContent.textFields.includes(field.name)) {
        fieldValues[field.name] = ["true", "false", "null"].includes(value)
          ? JSON.parse(value)
          : value;
      } else {
        fieldValues[field.name] = value;
      }
      return fieldValues;
    },
    {}
  );

  Object.keys(prescriptionFields)
    .filter(
      (fieldName: any) =>
        !Object.prototype.hasOwnProperty.call(defaultValues, fieldName)
    )
    .map(
      (fieldName: any) =>
        (defaultValues[fieldName] = [
          "Dose",
          "Fraction_Dose",
          "CTV",
          "PTV",
        ].includes(fieldName)
          ? [""]
          : "")
    );

  const updateTreatmentBreakActiveValues = (isActive: boolean): void => {
    const tbDuration = {
      siteId,
      fieldName: "Treatment_Break_Duration",
      defaultValue: isActive ? defaultValues.Treatment_Break_Duration : "",
      isShown: isActive,
      isActive,
    };
    const tbFraction = {
      siteId,
      fieldName: "Treatment_Break_Fraction",
      defaultValue: isActive ? defaultValues.Treatment_Break_Fraction : "",
      isShown: isActive,
      isActive,
    };
    updateTemplateSiteFields({ variables: tbDuration });
    updateTemplateSiteFields({ variables: tbFraction });
  };

  const handleMutation = (
    fieldName: string,
    value: any,
    isActive?: boolean,
    operation?: DoseOperation,
    index?: number
  ) => {
    let updateVariables = { siteId, fieldName, defaultValue: value };
    if (typeof isActive === "boolean") {
      if (fieldName === "Treatment_Break") {
        updateTreatmentBreakActiveValues(isActive);
      }
      updateVariables = Object.assign(
        { isShown: isActive, isActive },
        updateVariables
      );
    }
    if (fieldName === "Dose") {
      if (typeof operation !== "undefined" && index) {
        const ctv = defaultValues.CTV ? [...defaultValues.CTV] : [""];
        const ptv = defaultValues.PTV ? [...defaultValues.PTV] : [""];
        const dose = JSON.parse(value);
        if (operation === DoseOperation.Add) {
          ctv.splice(index, 0, "");
          ptv.splice(index, 0, "");
        } else if (operation === DoseOperation.Delete) {
          ctv.splice(index, 1);
          ptv.splice(index, 1);
        }
        const ctvUpdateVariables = {
          siteId,
          fieldName: "CTV",
          defaultValue: JSON.stringify(
            ctv.concat(new Array(dose.length).fill("")).slice(0, dose.length)
          ), // Brute force array to be same size as dose
          isShown: true,
          isActive: true,
        };
        const ptvUpdateVariables = {
          siteId,
          fieldName: "PTV",
          defaultValue: JSON.stringify(
            ptv.concat(new Array(dose.length).fill("")).slice(0, dose.length)
          ), // Brute force array to be same size as dose
          isShown: true,
          isActive: true,
        };
        updateTemplateSiteFields({ variables: ctvUpdateVariables });
        updateTemplateSiteFields({ variables: ptvUpdateVariables });
      }
    }
    updateTemplateSiteFields({ variables: updateVariables });
  };

  const dose_to_custom_enabled = (values: any) => {
    return ["Depth", "Isodose", "Custom"].includes(values.Dose_To);
  };
  return (
    <>
      {data && data.siteTemplate && data.siteTemplate.ruleSite && (
        <Formik
          initialValues={defaultValues}
          enableReinitialize
          onSubmit={() => {}}
        >
          {({ values }: any) => (
            <Form>
              <FormPage
                heading={heading}
                siteHeading={data.siteTemplate.site.name}
              >
                <Field
                  component={SelectField}
                  id="phase-id"
                  name="Phase"
                  label={isUS(regions) ? fields.Sequence : fields.Phase}
                  options={selectOptions.phase}
                  handleMutation={handleMutation}
                  isActive={!disableEditing}
                />
                <Field
                  component={SelectField}
                  id="technique-id"
                  name="Technique"
                  label={fields.Technique}
                  options={selectOptions.technique}
                  handleMutation={handleMutation}
                  isActive={!disableEditing}
                />
                <Field
                  component={SelectField}
                  id="modality-id"
                  name="Modality"
                  label={fields.Modality}
                  options={selectOptions.modality}
                  handleMutation={handleMutation}
                  isActive={!disableEditing}
                />
                <Field
                  component={DoseField}
                  id="dose-id"
                  name="Dose"
                  label={fields.Dose}
                  handleMutation={handleMutation}
                  isActive={!disableEditing}
                />
                <Field
                  component={InputField}
                  id="fractions-id"
                  name="Fractions"
                  label={fields.Fractions}
                  handleMutation={handleMutation}
                  isActive={!disableEditing}
                  type="number"
                />
                <Field
                  component={FractionDose}
                  id="fraction-dose-id"
                  name="Fraction_Dose"
                  label={fields.Fraction_Dose}
                  handleMutation={handleMutation}
                  isActive={false}
                  type="number"
                />
                <Field
                  component={SelectField}
                  id="dose-id"
                  name="Dose_To"
                  label={fields.Dose_To}
                  options={selectOptions.doseto}
                  handleMutation={handleMutation}
                  isActive={!disableEditing}
                />
                <Field
                  component={InputField}
                  id="custom-dose-to-id"
                  name="Custom_Dose_To"
                  label={fields.Custom_Dose_To}
                  options={selectOptions.doseto}
                  handleMutation={handleMutation}
                  isActive={!disableEditing && dose_to_custom_enabled(values)}
                />
                <Field
                  component={SelectField}
                  id="frequency-id"
                  name="Dose_Frequency"
                  label={fields.Dose_Frequency}
                  options={selectOptions.frequency}
                  handleMutation={handleMutation}
                  isActive={!disableEditing}
                />
                <Field
                  component={InputField}
                  id="frequency-custom-id"
                  name="Custom_Dose_Frequency"
                  label={fields.Custom_Dose_Frequency}
                  handleMutation={handleMutation}
                  isActive={
                    !disableEditing && values.Dose_Frequency === "Custom"
                  }
                />
                <Field
                  component={SelectField}
                  id="imaging-id"
                  name="Imaging"
                  label={fields.Imaging}
                  options={selectOptions.imaging}
                  handleMutation={handleMutation}
                  isActive={!disableEditing}
                />
                <Field
                  component={SelectField}
                  id="bowel-prep-id"
                  name="Imaging_Technique"
                  label={fields.Imaging_Technique}
                  options={selectOptions.imagingTechnique}
                  handleMutation={handleMutation}
                  isActive={!disableEditing}
                />
                <Divider />
                <Field
                  component={ToggleButtonGroupField}
                  id="bolus-id"
                  name="Bolus"
                  label={fields.Bolus}
                  options={yesNoOptions}
                  handleMutation={handleMutation}
                  isActive={!disableEditing && initActiveFields.Bolus}
                  toggleActive={!disableEditing}
                />
                <Divider />
                <Field
                  component={ToggleButtonGroupField}
                  id="treatment-break-id"
                  name="Treatment_Break"
                  label={fields.Treatment_Break}
                  options={yesNoOptions}
                  handleMutation={handleMutation}
                  isActive={!disableEditing && initActiveFields.Treatment_Break}
                  toggleActive={!disableEditing}
                />
                <Field
                  component={InputField}
                  id="treatment-break-duration-id"
                  name="Treatment_Break_Duration"
                  label={fields.Treatment_Break_Duration}
                  handleMutation={handleMutation}
                  isActive={!disableEditing && values.Treatment_Break}
                  type="number"
                />
                <Field
                  component={InputField}
                  id="treatment-break-fraction-id"
                  name="Treatment_Break_Fraction"
                  label={fields.Treatment_Break_Fraction}
                  handleMutation={handleMutation}
                  isActive={!disableEditing && values.Treatment_Break}
                  type="number"
                />
                <Divider />
                <Field
                  component={InputField}
                  id="additional-prescription-info-id"
                  name="Additional_Prescription_Info"
                  label={fields.Additional_Prescription_Info}
                  handleMutation={handleMutation}
                  isActive={!disableEditing}
                  multiline
                />
              </FormPage>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default Prescription;
