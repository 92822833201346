import { gql } from "@apollo/client";

export const DRUG_ORDER_FRAGMENT = gql`
  fragment drugOrderFragment on DrugOrderType {
    id
    name
    route
    type
    doseUnit {
      id
      name
      isCalculated
    }
    doseBasis
    roundToNearest
    minimumDose
    maximumDose
    doseIsRange
    solution
    frequency
    prn
    minimumDuration
    durationIsRange
    maximumDuration
    durationUnits
    administrationInstructions
    patientInstructions
    singleDoseLimit
    lifetimeDoseLimit
    isActive
    version
    createdAt
    editTime
    doseBands {
      id
      minimum
      maximum
      bandedDose
    }
    updatedBy {
      firstName
      lastName
    }
  }
`;

export const DRUG_FRAGMENT = gql`
  fragment drugFragment on DrugType {
    id
    genericName
    brandName
    route
    form
    doseUnit {
      id
      name
      isCalculated
    }
    drugCategory
    singleDoseLimit
    lifetimeDoseLimit
    concentration
    solution
    strength
    roundToNearest
    minimumDuration
    durationIsRange
    maximumDuration
    durationUnits
    administrationInstructions
    patientInstructions
    isActive
    createdAt
    editTime
    drugOrders {
      ...drugOrderFragment
    }
    updatedBy {
      firstName
      lastName
    }
  }
`;

export const GET_FORMULARY = gql`
  ${DRUG_ORDER_FRAGMENT}
  ${DRUG_FRAGMENT}
  query Formulary($first: Int, $skip: Int, $searchTerm: String) {
    formulary {
      id
      name
      drugs(first: $first, skip: $skip, searchTerm: $searchTerm) {
        drugs {
          ...drugFragment
        }
        totalCount
      }
    }
  }
`;

export const GET_DRUG = gql`
  ${DRUG_ORDER_FRAGMENT}
  ${DRUG_FRAGMENT}
  query Drug($drugId: ID!) {
    drug(drugId: $drugId) {
      ...drugFragment
    }
  }
`;

export const SEARCH_DRUG_ORDER = gql`
  query DrugOrderSearch($searchTerm: String!) {
    drugOrderSearch(searchTerm: $searchTerm) {
      drugOrders {
        id
        name
      }
    }
  }
`;

export const GET_DRUG_ORDER = gql`
  ${DRUG_ORDER_FRAGMENT}
  query DrugOrder($id: ID!) {
    drugOrder(id: $id) {
      ...drugOrderFragment
    }
  }
`;

export const UPDATE_DRUG = gql`
  ${DRUG_ORDER_FRAGMENT}
  ${DRUG_FRAGMENT}
  mutation updateDrug(
    $id: ID!
    $genericName: String!
    $brandName: String!
    $route: String!
    $form: String!
    $doseUnitId: ID!
    $drugCategory: String!
    $singleDoseLimit: Float
    $lifetimeDoseLimit: Float
    $concentration: String!
    $solution: String!
    $strength: String!
    $roundToNearest: Float
    $minimumDuration: Int
    $durationIsRange: Boolean!
    $maximumDuration: Int
    $durationUnits: String!
    $administrationInstructions: String!
    $patientInstructions: String!
  ) {
    updateDrug(
      id: $id
      genericName: $genericName
      brandName: $brandName
      route: $route
      form: $form
      doseUnitId: $doseUnitId
      drugCategory: $drugCategory
      singleDoseLimit: $singleDoseLimit
      lifetimeDoseLimit: $lifetimeDoseLimit
      concentration: $concentration
      solution: $solution
      strength: $strength
      roundToNearest: $roundToNearest
      minimumDuration: $minimumDuration
      durationIsRange: $durationIsRange
      maximumDuration: $maximumDuration
      durationUnits: $durationUnits
      administrationInstructions: $administrationInstructions
      patientInstructions: $patientInstructions
    ) {
      drug {
        ...drugFragment
      }
    }
  }
`;

export const CREATE_DRUG = gql`
  ${DRUG_ORDER_FRAGMENT}
  ${DRUG_FRAGMENT}
  mutation createDrug(
    $genericName: String!
    $brandName: String!
    $route: String!
    $form: String!
    $doseUnitId: ID!
    $drugCategory: String!
    $singleDoseLimit: Float
    $lifetimeDoseLimit: Float
    $concentration: String!
    $solution: String!
    $strength: String!
    $roundToNearest: Float
    $minimumDuration: Int
    $durationIsRange: Boolean!
    $maximumDuration: Int
    $durationUnits: String!
    $administrationInstructions: String!
    $patientInstructions: String!
  ) {
    createDrug(
      genericName: $genericName
      brandName: $brandName
      route: $route
      form: $form
      doseUnitId: $doseUnitId
      drugCategory: $drugCategory
      singleDoseLimit: $singleDoseLimit
      lifetimeDoseLimit: $lifetimeDoseLimit
      concentration: $concentration
      solution: $solution
      strength: $strength
      roundToNearest: $roundToNearest
      minimumDuration: $minimumDuration
      durationIsRange: $durationIsRange
      maximumDuration: $maximumDuration
      durationUnits: $durationUnits
      administrationInstructions: $administrationInstructions
      patientInstructions: $patientInstructions
    ) {
      drug {
        ...drugFragment
      }
    }
  }
`;

export const CREATE_DRUG_ORDER = gql`
  mutation createDrugOrder(
    $drugId: ID!
    $name: String!
    $route: String!
    $type: String!
    $doseUnitId: ID!
    $doseBasis: Float
    $roundToNearest: Float
    $minimumDose: Float
    $maximumDose: Float
    $doseIsRange: Boolean!
    $solution: String!
    $frequency: String!
    $prn: Boolean!
    $minimumDuration: Int
    $durationIsRange: Boolean!
    $maximumDuration: Int
    $durationUnits: String!
    $administrationInstructions: String!
    $patientInstructions: String!
    $singleDoseLimit: Float
    $lifetimeDoseLimit: Float
    $doseBands: [DoseBandInputType]
  ) {
    createDrugOrder(
      drugId: $drugId
      name: $name
      route: $route
      type: $type
      doseUnitId: $doseUnitId
      doseBasis: $doseBasis
      roundToNearest: $roundToNearest
      minimumDose: $minimumDose
      maximumDose: $maximumDose
      doseIsRange: $doseIsRange
      solution: $solution
      frequency: $frequency
      prn: $prn
      minimumDuration: $minimumDuration
      durationIsRange: $durationIsRange
      maximumDuration: $maximumDuration
      durationUnits: $durationUnits
      administrationInstructions: $administrationInstructions
      patientInstructions: $patientInstructions
      singleDoseLimit: $singleDoseLimit
      lifetimeDoseLimit: $lifetimeDoseLimit
      doseBands: $doseBands
    ) {
      drugOrder {
        id
      }
    }
  }
`;

export const UPDATE_DRUG_ORDER = gql`
  mutation updateDrugOrder(
    $id: ID!
    $name: String!
    $route: String!
    $type: String!
    $doseUnitId: ID!
    $roundToNearest: Float
    $solution: String!
    $frequency: String!
    $prn: Boolean!
    $minimumDuration: Int
    $durationIsRange: Boolean!
    $maximumDuration: Int
    $durationUnits: String!
    $administrationInstructions: String!
    $patientInstructions: String!
    $singleDoseLimit: Float
    $lifetimeDoseLimit: Float
    $doseBands: [DoseBandInputType]
  ) {
    updateDrugOrder(
      id: $id
      name: $name
      route: $route
      type: $type
      doseUnitId: $doseUnitId
      roundToNearest: $roundToNearest
      solution: $solution
      frequency: $frequency
      prn: $prn
      minimumDuration: $minimumDuration
      durationIsRange: $durationIsRange
      maximumDuration: $maximumDuration
      durationUnits: $durationUnits
      administrationInstructions: $administrationInstructions
      patientInstructions: $patientInstructions
      singleDoseLimit: $singleDoseLimit
      lifetimeDoseLimit: $lifetimeDoseLimit
      doseBands: $doseBands
    ) {
      drugOrder {
        id
      }
    }
  }
`;

export const ARCHIVE_DRUG_ORDER = gql`
  mutation archiveDrugOrder($id: ID!) {
    archiveDrugOrder(id: $id) {
      drugOrder {
        id
      }
    }
  }
`;
