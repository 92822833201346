import { gql } from "@apollo/client";

export const LOAD_LIST_DATA = gql`
  query DataReferenceList($listCategories: [String!]!, $templateId: ID!) {
    dataReferenceList(
      listCategories: $listCategories
      templateId: $templateId
    ) {
      id
      data: option {
        option
      }
      listWeight
      category {
        category
      }
    }
  }
`;

export const LOAD_FORMULARY_LIST_DATA = gql`
  query FormularyReferenceList {
    formularyReferenceList {
      id
      data: option {
        option
      }
      listWeight
      category {
        category
      }
    }
    doseUnitList {
      id
      name
      isCalculated
      listWeight
    }
  }
`;

export const GET_DIAGNOSIS_CATEGORIES = gql`
  query DiagnosisCategoryList {
    diagnosisCategoryList {
      id
      anatomicSiteNum
      name
    }
  }
`;

export const GET_REGIONS = gql`
  query RegionList {
    regionList {
      id
      name
    }
  }
`;

export const GET_SUBREGIONS = gql`
  query SubregionList($regions: [String]) {
    subregionList(regions: $regions) {
      id
      name
      region {
        id
        name
      }
    }
  }
`;

export const GET_SYSTEMS = gql`
  query SystemList($regions: [String], $subregions: [String]) {
    SystemList(regions: $regions, subregions: $subregions) {
      id
      name
    }
  }
`;
