import Input from "components/base/Input";
import { useField } from "formik";
import React from "react";
import { default as FormCheckbox } from "components/base/Checkbox";
import GreenSwitch from "components/base/GreenSwitch";

interface TextInputProps {
  name: string;
  disabled?: boolean;
  multiline?: boolean;
  dataCy?: string;
}

interface InputProps {
  name: string;
  disabled?: boolean;
}

const TextField = (props: TextInputProps) => {
  const [field] = useField({ name: props.name, type: "text" });
  const { name, disabled, multiline } = props;
  return (
    <Input
      value={field.value ?? ""}
      onChange={field.onChange}
      variant="outlined"
      size="medium"
      name={name}
      disabled={disabled}
      multiline={multiline}
      inputProps={{ "data-cy": props.dataCy }}
    />
  );
};

const Checkbox = (props: InputProps) => {
  const [field] = useField({ name: props.name, type: "checkbox" });
  return <FormCheckbox {...field} {...props}></FormCheckbox>;
};

const SliderToggle = (props: InputProps) => {
  const { disabled } = props;
  const [field] = useField({ name: props.name, type: "checkbox" });
  return <GreenSwitch {...field} disabled={disabled} />;
};

export { TextField, Checkbox, SliderToggle };
