import React, { useState, useEffect } from "react";

const DebouncedButton = (props: any) => {
  const { Component, delay = 1000, onClick, ...restProps } = props;

  const [isDisabled, setDisabled] = useState(false);

  useEffect(() => {
    if (!isDisabled) return; // timeout elapsed, nothing to do

    // disabled was changed to true, set back to false after `delay`
    const handle = setTimeout(() => {
      setDisabled(false);
    }, delay);
    return () => clearTimeout(handle);
  }, [isDisabled, delay]);

  const handleClick = (e: React.ChangeEvent<any>) => {
    if (isDisabled) return;

    setDisabled(true);
    return onClick(e);
  };

  return (
    <Component
      {...restProps}
      disabled={isDisabled || props?.disabled}
      onClick={handleClick}
    >
      {props.children}
    </Component>
  );
};

export default DebouncedButton;
