import { informationContent, sortSites } from "i18n/constants";

const InformationDefaultValues = (template: any) => {
  const [informationFields] = [informationContent.fields];
  const siteGroups = template.careplanTreatment;
  const siteTemplate =
    siteGroups.map((siteGroup: any) => sortSites(siteGroup.siteTemplate)) || [];
  const treatmentSites = siteTemplate.map(
    (siteDetails: any) => siteDetails.site?.name
  );

  const extractValueByFieldName = (fieldName: string) => {
    if (fieldName === "hasNegativeDays")
      return template?.negativeDays && template.negativeDays.length !== 0;
    if (fieldName === "negativeDays") return template?.negativeDays ?? [];
    if (fieldName === "tumourStream") return template?.tumourStream?.name;
    if (fieldName === "treatmentSites") return treatmentSites;
    if (fieldName === "siteGroupSites") return siteGroups;
    if (fieldName === "site") return siteTemplate;
    if (fieldName === "siteGroupNames")
      return siteGroups?.map((siteGroup: any) => siteGroup.name);
    if (fieldName === "Treating_Department") {
      const defaultValue = template?.templateRules?.find(
        (templateRule: any) =>
          templateRule?.field?.name === "Treating_Department"
      )?.defaultValue;
      return defaultValue ? JSON.parse(defaultValue) : [];
    }
    if (fieldName === "subregions") {
      return template?.subregions?.map((subregion: any) => subregion.name);
    }
    return template[fieldName];
  };

  return Object.keys(informationFields).reduce(
    (fieldValues: any, fieldName: string) => {
      fieldValues[fieldName] = extractValueByFieldName(fieldName);
      return fieldValues;
    },
    {}
  );
};

export default InformationDefaultValues;
