import { styled } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

export const StandardPaddedContainer = styled("div")`
  padding: 24px 8px;
`;

export const StyledOpenInNewIcon = styled(OpenInNewIcon)`
  vertical-align: middle;
  margin-left: 8px;
  margin-bottom: 3px;
  font-size: 14px;
`;

export const StyledExternalLink = styled("a")`
  text-decoration: none;
  color: ${(props) => props.theme.palette.info.main};
`;
