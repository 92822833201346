import React, { Component, Fragment } from "react";
import {
  TextField,
  Grid,
  Button,
  MenuItem,
  IconButton,
  Divider,
  Select,
  SelectChangeEvent,
  styled,
} from "@mui/material";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutline from "@mui/icons-material/RemoveCircleOutline";
import OarTwoValueTextField from "./OarTwoValueTextField";
import OarSelect from "./OarSelect";
import { LESS_THAN_OR_EQUAL } from "shared/utils";

const StyledTextField = styled(TextField)`
  border-color: ${(props) => props.theme.palette.grey[300]};
  margin: 6px;
  padding-left: 6px;

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

interface FieldValue {
  dosetoPrefixOperator: string | undefined;
  firstValue: string | undefined;
  firstValueUnit: string | undefined;
  operator: string | undefined;
  secondValue: string[] | undefined;
  secondValueUnit: string | undefined;
}

interface NoListProps {
  id: string;
  label: string;
  placeholder?: string;
  value: FieldValue;
  readOnly?: boolean;
  onChange?: (index: number, value: FieldValue) => void;
  onBlur?: () => void;
  index: number;
  isVolumeData: boolean;
  length: number;
  removeConstraint: () => void;
}

interface Props {
  id: string;
  label: string;
  placeholder?: string;
  value: FieldValue[];
  readOnly?: boolean;
  onChange?: (index: number, value: FieldValue) => void;
  onBlur?: () => void;
  isVolumeData: boolean;
  addConstraint?: () => void;
  removeConstraint?: (item: any) => void;
}

const CONSTRAINT_OPERATOR_OPTIONS = [
  { value: LESS_THAN_OR_EQUAL, label: LESS_THAN_OR_EQUAL },
  { value: "<", label: "<" },
];

const GRAY_PERCENTAGE_OPTIONS = [
  { value: "Gy", label: "Gy" },
  { value: "%", label: "%" },
];

const PERCENTAGE_CC_OPTIONS = [
  { value: "%", label: "%" },
  { value: "cc", label: "cc" },
];

class OarConstraintRow extends Component<NoListProps> {
  public render(): JSX.Element {
    const objValue = this.props.value;
    const firstValueUnit = objValue.firstValueUnit || "Gy";
    const secondValueUnit = objValue.secondValueUnit || "%";
    const operator = objValue.operator || LESS_THAN_OR_EQUAL;
    const isVolumeData = this.props.isVolumeData;
    const firstValueUnitOptions = isVolumeData
      ? GRAY_PERCENTAGE_OPTIONS
      : PERCENTAGE_CC_OPTIONS;
    const secondValueUnitOptions = isVolumeData
      ? PERCENTAGE_CC_OPTIONS
      : GRAY_PERCENTAGE_OPTIONS;
    const id = `${this.props.id}-${this.props.index}`;

    return (
      <Fragment>
        <Grid container alignItems="center" direction="row">
          {!isVolumeData && (
            <Grid item xs={1}>
              <Select
                value={objValue.dosetoPrefixOperator}
                disabled={this.props.readOnly}
                onChange={(event: SelectChangeEvent) => {
                  if (this.props.onChange) {
                    objValue.dosetoPrefixOperator = event.target.value;
                    this.props.onChange(this.props.index, objValue);
                  }
                }}
              >
                <MenuItem value="">blank</MenuItem>
                <MenuItem value=">">{">"}</MenuItem>
              </Select>
            </Grid>
          )}
          <Grid item xs>
            <StyledTextField
              id={`firstValue-${id}`}
              type="number"
              value={this.props.value.firstValue || undefined}
              onChange={(e) => {
                if (this.props.onChange) {
                  objValue.firstValue = e.target.value || undefined;
                  this.props.onChange(this.props.index, objValue);
                }
              }}
              disabled={this.props.readOnly}
              onBlur={(): void => {
                if (this.props.onBlur) this.props.onBlur();
              }}
              InputProps={{ disableUnderline: true }}
            />
          </Grid>
          <Grid item xs>
            <OarSelect
              disableUnderline
              id={`firstValueUnit-${id}`}
              inputProps={{ "data-test-id": `firstValueUnit-${id}-select` }}
              onChange={(event) => {
                if (this.props.onChange) {
                  objValue.firstValueUnit = event.target.value as string;
                  this.props.onChange(this.props.index, objValue);
                }
              }}
              value={firstValueUnit}
              disabled={this.props.readOnly}
            >
              {firstValueUnitOptions.map(
                (option: { label: string; value: any }, idx: number) => (
                  <MenuItem key={idx} value={option.value}>
                    {option.label}
                  </MenuItem>
                ),
              )}
            </OarSelect>
          </Grid>
          <Grid item xs>
            <Grid container>
              <Divider orientation="vertical" flexItem />
              <OarSelect
                disableUnderline
                id={`operator-${id}`}
                inputProps={{ "data-test-id": `operator-${id}-select` }}
                onChange={(event) => {
                  if (this.props.onChange) {
                    objValue.operator = event.target.value as string;
                    this.props.onChange(this.props.index, objValue);
                  }
                }}
                value={operator}
                disabled={this.props.readOnly}
              >
                {CONSTRAINT_OPERATOR_OPTIONS.map(
                  (option: { label: string; value: any }, idx: number) => (
                    <MenuItem key={idx} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ),
                )}
              </OarSelect>
              <Divider orientation="vertical" flexItem />
            </Grid>
          </Grid>
          <Grid item xs>
            <OarTwoValueTextField
              id={`secondValue-${id}`}
              value={this.props.value.secondValue || ""}
              onChange={(values: string[]): void => {
                if (this.props.onChange) {
                  objValue.secondValue = values || undefined;
                  this.props.onChange(this.props.index, objValue);
                }
              }}
              onBlur={(): void => {
                if (this.props.onBlur) this.props.onBlur();
              }}
              readOnly={this.props.readOnly}
            />
          </Grid>
          <Grid item xs>
            <OarSelect
              disableUnderline
              id={`secondValueUnit-${id}`}
              inputProps={{ "data-test-id": `secondValueUnit-${id}-select` }}
              onChange={(event): void => {
                if (this.props.onChange) {
                  objValue.secondValueUnit = event.target.value as string;
                  this.props.onChange(this.props.index, objValue);
                }
              }}
              value={secondValueUnit}
              disabled={this.props.readOnly}
            >
              {secondValueUnitOptions.map(
                (option: { label: string; value: any }, idx: number) => (
                  <MenuItem key={idx} value={option.value}>
                    {option.label}
                  </MenuItem>
                ),
              )}
            </OarSelect>
          </Grid>
          <Grid item xs={1}>
            {this.props.length > 1 && (
              <IconButton
                id={`remove-${id}`}
                style={{ padding: "4px" }}
                onClick={(): void => this.props.removeConstraint()}
                size="large"
              >
                <RemoveCircleOutline color="error" />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

class OarConstraintField extends Component<
  Props,
  { numberOfRows: number[]; value: [] }
> {
  public constructor(props: any) {
    super(props);
    this.state = {
      numberOfRows: this.props.value
        ? this.props.value.map((_value, index): number => index)
        : [0],
      value: [],
    };
  }

  private onAddConstraint(): void {
    this.props.addConstraint && this.props.addConstraint();
  }

  private onRemoveConstraint(item: any): void {
    this.props.removeConstraint && this.props.removeConstraint(item);
  }

  public render(): JSX.Element {
    const { id, label } = this.props;

    const defaultValues = [
      {
        firstValue: undefined,
        firstValueUnit: this.props.isVolumeData ? "Gy" : "%",
        operator: LESS_THAN_OR_EQUAL,
        secondValue: undefined,
        secondValueUnit: this.props.isVolumeData ? "%" : "Gy",
      },
    ];
    const valueList =
      this.props.value && this.props.value.length
        ? this.props.value
        : defaultValues;

    return (
      <>
        {valueList.map((item: any, index: number): any => (
          <OarConstraintRow
            key={`${label} + ${index}`}
            removeConstraint={() => this.onRemoveConstraint(index)}
            index={index}
            value={item}
            id={id}
            placeholder={this.props.placeholder}
            label={this.props.label}
            onChange={this.props.onChange}
            readOnly={this.props.readOnly}
            length={valueList.length}
            isVolumeData={this.props.isVolumeData}
          />
        ))}
        <Grid container>
          {!this.props.isVolumeData && <Grid item xs={1} />}
          <Grid item xs>
            <Button
              id={`addConstraint-${id}`}
              onClick={() => {
                if (!this.props.readOnly) {
                  this.setState({
                    numberOfRows: [
                      ...this.state.numberOfRows,
                      this.state.numberOfRows.length,
                    ],
                  });
                }
                this.onAddConstraint();
              }}
              disabled={this.props.readOnly}
              startIcon={<AddCircleOutline color="primary" />}
            >
              Add Constraint
            </Button>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default OarConstraintField;
