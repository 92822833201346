import React, { useState, useContext } from "react";
import {
  IconButton,
  TableCell,
  TableRow,
  TableBody,
  Collapse,
  TableContainer,
  Button,
  TablePagination,
  styled,
  useTheme,
} from "@mui/material";
import { AddCircleOutline, KeyboardArrowRight } from "@mui/icons-material";
import {
  StyledTableHead,
  StyledTableSortLabel,
  StyledTable,
  SmallTableCell,
} from "../../TableStyles";
import {
  directionalComparitor,
  formatUserName,
  prettyDate,
  sortArrayByComparitor,
} from "shared/utils";
import {
  DrugInterface,
  DrugOrderActionType,
  emptyDrugOrder,
  FormularyColumnInterface,
} from "interfaces/formulary";
import DrugContext, { DrugAction } from "contexts/DrugContext";
import DrugOrderContext from "contexts/DrugOrderContext";
import DrugOrderTable from "components/Formulary/DrugOrderTable/DrugOrderTable";
import {
  StatusTrafficLight,
  EditIcon,
  CloneIcon,
  ColorFromStatus,
  PlaceholderRow,
  ROWS_PER_PAGE,
} from "components/TemplateSearchTables/tableUtils";

interface TableProps {
  drugs: DrugInterface[];
  totalDrugCount: number;
  loading: boolean;
  page: number;
  rowsPerPage: number;
  onPageChange: (page: number) => void;
  onRowsPerPageChange: (rows: number) => void;
}

interface RowProps {
  drug: DrugInterface;
}

const columns: FormularyColumnInterface[] = [
  { label: "Generic Name", attribute: "genericName" },
  { label: "Brand Name", attribute: "brandName" },
  { label: "Route", attribute: "route" },
  { label: "Dose Form", attribute: "form" },
  { label: "Strength", attribute: "strength" },
  { label: "Concentration", attribute: "concentration" },
  { label: "Default Solution", attribute: "solution" },
  { label: "Last Modified", attribute: "createdAt" },
  { label: "Status", attribute: "status" },
];

const InlineContainer = styled("div")`
  display: inline-flex;
  flex-wrap: none;
  align-items: center;
`;

const DrugOrderCount = styled("div")`
  color: white;
  background-color: ${(props) => props.theme.palette.primary.main};
  border-radius: 100%;
  font-size: 14px;
  width: 24px;
  height: 24px;
  min-width: 24px;
  line-height: 24px;
  display: inline-block;
  text-align: center;
  font-weight: 700;
`;

const StyledRow = styled(TableRow)`
  box-shadow:
    0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%),
    0px 1px 8px 0px rgb(0 0 0 / 12%);
  border-radius: 10px;
`;

const StyledFormularyTable = styled(StyledTable)`
  padding-left: 4px;
  padding-right: 4px;
  table-layout: fixed;
  padding-bottom: 100px;
`;

const StyledCell = styled(SmallTableCell)`
  color: ${(props) => props.theme.palette.text.primary};
  background: ${(props) => props.theme.palette.grey[100]};
  padding: 16px;
`;

const StyledTableContainer = styled(TableContainer)`
  && {
    max-height: calc(100vh - 245px);
    height: calc(100vh - 245px);
  }
`;

const FormularyRow = ({ drug }: RowProps): JSX.Element => {
  const drugContext = useContext(DrugContext);
  const drugOrderContext = useContext(DrugOrderContext);
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const startAddDrugOrder = () => {
    const newDrugOrder = Object.assign(emptyDrugOrder, { drugId: drug.id });
    // Autopopulate some fields from the drug
    newDrugOrder.route = drug.route;
    newDrugOrder.doseUnit = drug.doseUnit;
    newDrugOrder.singleDoseLimit = drug.singleDoseLimit;
    newDrugOrder.lifetimeDoseLimit = drug.lifetimeDoseLimit;
    newDrugOrder.solution = drug.solution;
    newDrugOrder.roundToNearest = drug.roundToNearest;
    newDrugOrder.minimumDuration = drug.minimumDuration;
    newDrugOrder.durationIsRange = drug.durationIsRange;
    newDrugOrder.maximumDuration = drug.maximumDuration;
    newDrugOrder.durationUnits = drug.durationUnits;
    newDrugOrder.patientInstructions = drug.patientInstructions;
    newDrugOrder.administrationInstructions = drug.administrationInstructions;

    // Set the context and show the modal
    drugOrderContext.setDrugOrder(newDrugOrder);
    drugOrderContext.setActionType(DrugOrderActionType.ADD);
    drugOrderContext.setModalIsVisible(true);

    // Set the drug context as some values are displayed in the drugOrder modal
    drugContext.setDrug(drug);
  };

  const startEditDrug = () => {
    drugContext.setDrug(drug);
    drugContext.setAction(DrugAction.EDIT);
    drugContext.setModalIsVisible(true);
  };

  const startCloneDrug = () => {
    drugContext.setDrug(drug);
    drugContext.setAction(DrugAction.CLONE);
    drugContext.setModalIsVisible(true);
  };

  const borderRadiusTransition = (open: boolean) =>
    open ? "" : "all 50ms linear 300ms";
  const formattedName = formatUserName(drug.updatedBy);

  return (
    <>
      <TableRow style={{ height: "16px" }} />

      <StyledRow data-test-id={`drug-row-${drug.id}`}>
        <StyledCell
          style={{
            color: "white",
            borderRadius: `10px 0 0 ${open ? "0" : "10px"}`,
            transition: borderRadiusTransition(open),
          }}
          onClick={() => setOpen(!open)}
          data-test-id={`drug-expander-${drug.id}`}
        >
          <InlineContainer>
            <DrugOrderCount>{drug.drugOrders.length}</DrugOrderCount>
            <IconButton
              size="small"
              style={{
                transform: open ? "rotate(90deg)" : "",
                transition: "all 100ms linear",
                outline: "0",
              }}
            >
              <KeyboardArrowRight />
            </IconButton>
          </InlineContainer>
        </StyledCell>

        <StyledCell>{drug.genericName}</StyledCell>

        <StyledCell>{drug.brandName}</StyledCell>

        <StyledCell>{drug.route}</StyledCell>

        <StyledCell>{drug.form}</StyledCell>

        <StyledCell>{drug.strength}</StyledCell>

        <StyledCell>{drug.concentration}</StyledCell>

        <StyledCell>{drug.solution}</StyledCell>

        <StyledCell>
          {" "}
          {formattedName && `${formattedName}, `}
          {prettyDate(drug.editTime)}
        </StyledCell>

        <StyledCell>
          <StatusTrafficLight
            color={ColorFromStatus(drug.isActive ? "approve" : "draft")}
          >
            {drug.isActive ? "Active" : "Inactive"}
          </StatusTrafficLight>
        </StyledCell>

        <StyledCell
          align="right"
          style={{
            color: theme.palette.grey[300],
            borderRadius: `0 10px ${open ? "0" : "10px"} 0`,
            transition: borderRadiusTransition(open),
          }}
        >
          <InlineContainer>
            <CloneIcon onClick={startCloneDrug} id={`clone-drug-${drug.id}`} />
            <EditIcon onClick={startEditDrug} id={`edit-drug-${drug.id}`} />
          </InlineContainer>
        </StyledCell>
      </StyledRow>

      <StyledRow>
        <TableCell
          style={{
            padding: "0",
            borderWidth: open ? "1px" : "0",
            borderRadius: open ? "0 0 10px 10px" : "0",
          }}
          colSpan={11}
        >
          <Collapse in={open} mountOnEnter>
            <DrugOrderTable drug={drug} />
            <div style={{ padding: "8px" }}>
              <Button
                data-test-id={`add-drug-order-button-${drug.id}`}
                variant={"text"}
                onClick={startAddDrugOrder}
                startIcon={<AddCircleOutline />}
              >
                Add Drug Order
              </Button>
            </div>
          </Collapse>
        </TableCell>
      </StyledRow>
    </>
  );
};

const formularyHeader = (
  col: FormularyColumnInterface,
  key: string,
  orderBy: string,
  orderDirection: "asc" | "desc",
  onClickCallback: any,
): JSX.Element => {
  return (
    <TableCell
      key={key}
      sortDirection={orderBy === col.attribute ? orderDirection : false}
    >
      <StyledTableSortLabel
        active={orderBy === col.attribute}
        direction={orderDirection}
        onClick={() => onClickCallback(col.attribute)}
      >
        {col.label}
      </StyledTableSortLabel>
    </TableCell>
  );
};

const FormularyTable = ({
  drugs,
  totalDrugCount,
  loading,
  page,
  onPageChange,
  rowsPerPage,
  onRowsPerPageChange,
}: TableProps) => {
  const [orderDirection, setOrderDirection] = useState<"asc" | "desc">("asc");
  const [orderBy2, setOrderBy] = useState<string>("");

  const handleSortingCallback = (attribute: string) => {
    const isAsc = orderBy2 === attribute && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(attribute);
  };

  return (
    <>
      <StyledTableContainer>
        <StyledFormularyTable stickyHeader data-test-id={"formulary-table"}>
          <StyledTableHead>
            <TableRow>
              <TableCell width={"5%"} />
              {columns.map((header, index) => {
                return formularyHeader(
                  header,
                  `formulary-header-${index}`,
                  orderBy2,
                  orderDirection,
                  handleSortingCallback,
                );
              })}
              <TableCell />
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {drugs.length ? (
              sortArrayByComparitor(
                drugs,
                directionalComparitor(orderDirection, orderBy2),
              ).map((drug: DrugInterface, index: number) => (
                <FormularyRow drug={drug} key={`formulary-row-${index}`} />
              ))
            ) : (
              <PlaceholderRow loading={loading} colSpan={11} />
            )}
          </TableBody>
        </StyledFormularyTable>
      </StyledTableContainer>
      <TablePagination
        component="div"
        count={totalDrugCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(_, pageNum) => onPageChange(pageNum)}
        onRowsPerPageChange={(ev) =>
          onRowsPerPageChange(parseInt(ev.target.value))
        }
        rowsPerPageOptions={ROWS_PER_PAGE}
      />
    </>
  );
};

export default FormularyTable;
