import React from "react";
import createTheme from "@mui/material/styles/createTheme";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import AppIcon from "./carepath_logo_and_name.svg?react";

const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (Material UI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});
LinkBehavior.displayName = "LinkBehavior";

const Logo = React.forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <div ref={ref}>
      <AppIcon />
    </div>
  );
});
Logo.displayName = "Logo";

const favicon =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAvVJREFUSImllU1oU1kUx3/nJnlpY5s2RapUm3bRAUUtSqJuFDJ1MbgSFLWLdlCo1VUXIijMQlwNunIlflcbhynjQgWhmxHX9ktRqAhFKSPtwsGP2No0H++4aBOb1/T1Fc/qvf+55/zOu+fde0RV8WKnBpNxjF5V5EHDdOPF84lEzkuceAGcGr77uyI3AGtBeoKkD12LdX/5aUD3UPKEiF4DZLGuMJi2pK2vtXPGLd64OU+MJttE9IozOfPCrsqM9rlW5wboefZX2NjaC/hd4g92DyWPrQiQh8dr5e+Ta+XChSJwzuTPANGVKhTRP0+OXA8V3u9/GKvq/+95Q9Gvqsj9rqcoCWAaZMQyMtAe3f1HhQlUrwQAqLNCV7ZHmkJAAmgGFNvEjzbFRoV/umKoDjuDAsbHtvBGdkSi+GRJC0osHKgkXtdcoqlyrz26s9OgerhcUNbOM/p5ggeTo0zn0q6Ar9lZsna+RBM4MDA+HjTAHrfgj3MzPJx8wVcXiC5AHITqVPBTqwE2uZYHfMtlGJh6Rc5R5WKbtbNLwSqbDFCzEgDgc3aWwU/vlvXn1S6jasQAGS8AgLHU1LJbZcr8CGL0mwHeewXYqrxOTZX1BWTpeRTMpAEd8QoAeDvzf1m9yl/hlDSTl+cGkcerAaSys6TzpQ0NGB8hn+VcOtzRFJsypDOPgA+rg5T2YV1FGGcLBL0NYLSzbwa4tBpAxv4xawRoqIw4sutE9VxdLxRu0/rGy4DnXviNr/hcXxGmyh9c7FaxTdf+lpa5IkAT53P4OQRMegEUElrGzy/V60uLFz13JBr/t/BevJ714M0JRNtQ3rolD/ksqvxBDMKWmgasH1+jInr2yMZdJdtdMnD08K03GHaiJJm/YpZY85q1GBG21m4gYq1ZKFsnbJXfnMnnXcvMZOnv2oHhNHAAWJgLQntjnF/rN1NjVQIMoXIrnKm9U9hzz4DigoGeIKl0K+Q3x+qa9/a07HsZDFjj2by86GiKlT/Wi+w78ccEKdy0/R0AAAAASUVORK5CYII=";

const palette = {
  text: {
    primary: "#111111",
    secondary: "#222222",
    disabled: "#081e4360",
  },
  primary: {
    main: "#008850",
    dark: "#006D40",
    light: "#222222",
    contrastText: "#FFFFFF",
  },
  secondary: {
    main: "#008850",
    dark: "#745de5",
    light: "#cdc6fe",
    contrastText: "#081E43",
  },
  action: {
    active: "#008850",
    activatedOpacity: 1,
    hover: "#0088500A",
    hoverOpacity: 0.04,
    selected: "#e0efe9",
    selectedOpacity: 0.12,
    focus: "#00885029",
    focusOpacity: 0.16,
    disabledBackground: "#0000000F",
    disabled: "#00000061",
    disabledOpacity: 0.38,
  },
  error: {
    main: "#FB6A76",
    dark: "#ec0031",
    light: "#FF969D",
    contrastText: "#081E43",
  },
  warning: {
    main: "#E57C00",
    dark: "#C55011",
    light: "#FCF2E5",
    contrastText: "#081E43",
  },
  info: {
    main: "#036497",
    dark: "#226bc0",
    light: "#E6EFF5",
    contrastText: "#081E43",
  },
  success: {
    main: "#3EC490",
    dark: "#007840",
    light: "#81D4B0",
    contrastText: "#081e43",
  },
  background: {
    default: "#ffffff",
    // all paper elevations are pure white
    paper: "#ffffff",
  },
  divider: "#081e431e",
};
const theme = createTheme({
  custom: {
    logo: <Logo />,
    favicon: favicon,
    title: "Carepath",
  },
  palette: palette,
  typography: {
    fontFamily: "Poppins",
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      },
      styleOverrides: {
        root: {
          ":focus-visible": {
            outlineColor: palette.secondary.main,
          },
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: "black",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        // white text when hovered
        contained: {
          ":hover": { color: "white" },
        },
        containedInherit: {
          ":hover": { color: "unset" },
        },
        // darken text of outlined and text variants
        outlinedError: { color: palette.error.dark },
        outlinedInfo: { color: palette.info.dark },
        outlinedWarning: { color: palette.warning.dark },
        outlinedSuccess: { color: palette.success.dark },
        outlinedSecondary: { color: palette.secondary.dark },
        textError: { color: palette.error.dark },
        textInfo: { color: palette.info.dark },
        textWarning: { color: palette.warning.dark },
        textSuccess: { color: palette.success.dark },
        textSecondary: { color: palette.secondary.dark },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        // darken error helper text
        root: {
          "&.Mui-error": {
            color: palette.error.dark,
          },
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          ":hover": { color: "white" },
          ":is(.MuiFab-colorInherit, .MuiFab-default):hover": {
            color: "inherit",
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: palette.secondary.main,
          color: palette.primary.dark,
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        // "default" colours are error colours
        badge: {
          ":not([class*='MuiBadge-color'])": {
            backgroundColor: palette.error.main,
            color: palette.error.contrastText,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        // white text when hovered
        filled: {
          "&.MuiChip-clickable:hover": { color: "white" },
          "&.MuiChip-colorDefault:hover": { color: "unset" },
        },
        root: {
          // all avatar colour variants use text.primary besides primary
          ".MuiChip-avatar": {
            color: palette.text.primary,
          },
          ".MuiChip-avatarColorPrimary": {
            color: palette.primary.contrastText,
            backgroundColor: palette.primary.light,
          },
          // avatar background colours are color.light
          ".MuiChip-avatarColorSecondary": {
            backgroundColor: palette.secondary.light,
          },
          ".MuiChip-avatarColorInfo": {
            backgroundColor: palette.info.light,
          },
          ".MuiChip-avatarColorWarning": {
            backgroundColor: palette.warning.light,
          },
          ".MuiChip-avatarColorError": {
            backgroundColor: palette.error.light,
          },
          ".MuiChip-avatarColorDefault": {
            // except default
            backgroundColor: palette.secondary.main,
          },
          // arbitrary delete icon colour :(
          ".MuiChip-deleteIconColorDefault": {
            color: "#430098",
            opacity: 0.26,
          },
          // outlined variant uses dark version of respective colour
          "&.MuiChip-outlinedPrimary": {
            color: palette.primary.dark,
          },
          "&.MuiChip-outlinedSecondary": {
            color: palette.secondary.dark,
          },
          "&.MuiChip-outlinedInfo": {
            color: palette.info.dark,
          },
          "&.MuiChip-outlinedWarning": {
            color: palette.warning.dark,
          },
          "&.MuiChip-outlinedError": {
            color: palette.error.dark,
          },
          "&.MuiChip-outlinedDefault": {
            color: palette.primary.dark,
            borderColor: palette.action.active,
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          // left line and background for selected
          ".MuiListItemButton-root.Mui-selected": {
            backgroundColor: palette.secondary.light,
            borderLeft: `4px solid ${palette.primary.main}`,
          },
          // border radius for all top-level list items
          ".MuiListItem-root, .MuiListItemButton-root": {
            borderRadius: "4px",
          },
          ".MuiListItem-gutters, .MuiListItemButton-gutters": {
            paddingLeft: "12px",
            paddingRight: 0,
          },
          // get rid of jank overlay radius onClick
          ".Mui-selected>.MuiTouchRipple-root": {
            borderRadius: "0px 4px 4px 0px",
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          // close icon of filled variant must be dark
          "&.MuiAlert-filled.MuiAlert-closeIcon": {
            color: palette.text.primary,
          },
        },
      },
    },
    // prefer secondary colour for progress
    MuiLinearProgress: {
      defaultProps: {
        color: "secondary",
      },
    },
    MuiCircularProgress: {
      defaultProps: {
        color: "secondary",
      },
    },
    // snackbar action text should be secondary.light
    MuiSnackbarContent: {
      styleOverrides: {
        action: {
          color: palette.secondary.light,
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          // text and border colours
          ".MuiPaginationItem-outlinedSecondary": {
            borderColor: "#43009839", // totally arbitrary opacity, love it
            "&.Mui-selected": {
              color: palette.secondary.dark,
            },
          },
        },
      },
    },
    MuiStepLabel: {
      // step label uses dark version of color
      styleOverrides: {
        label: {
          "&.Mui-error": {
            color: palette.error.dark,
          },
        },
      },
    },
    MuiStepIcon: {
      // step icons must have 24px min width
      styleOverrides: {
        root: {
          minWidth: "24px",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        // tab text is darker when secondary
        textColorSecondary: {
          "&.Mui-selected": {
            color: palette.secondary.dark,
          },
          "&.Mui-selected svg": {
            color: palette.secondary.main,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        // tab indicator is darker when secondary
        indicator: ({ ownerState: { indicatorColor } }) => ({
          backgroundColor:
            indicatorColor === "secondary"
              ? palette.secondary.dark
              : palette.primary.main,
        }),
      },
    },
    MuiIconButton: {
      styleOverrides: {
        // default colour is primary
        root: {
          ":not([class*='MuiIconButton-color'])": {
            color: palette.primary.main,
          },
        },
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          "&.MuiAutocomplete-popper>.MuiAutocomplete-paper": {
            ".Mui-selected": {
              backgroundColor: palette.action.focus,
              ":hover": {
                backgroundColor: palette.action.focus,
              },
            },
          },
        },
      },
    },
  },
});

export default theme;
