import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ProtocolTable from "components/TemplateSearchTables/ProtocolTable";
import SearchField from "components/formFields/SearchField";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { GET_DIAGNOSIS_CATEGORIES, GET_REGIONS } from "graphql/ListDataQueries";
import Button from "components/base/Button";
import SearchMultiSelectField from "components/formFields/SearchMultiSelectField";
import CheckboxGroupField from "components/formFields/CheckboxGroupField";
import SearchFilter from "components/SearchFilter/SearchFilter";
import { SEARCH_CAREPLAN_TEMPLATES } from "graphql/SearchQueries";
import { CREATE_TEMPLATE } from "graphql/TemplateQueries";
import { canUserCreate } from "shared/utils";
import UserContext from "contexts/UserContext";
import { DEFAULT_SEARCH_ROWS, shared } from "i18n/constants";
import {
  ACTIVE,
  INACTIVE,
  TUMOUR_STREAMS,
  ACTIVE_STATUS,
  REGIONS,
  StyledMenuItem,
  activeStatusToBoolean,
} from "./searchUtils";
import { StandardPaddedContainer } from "components/CommonStyledComponents";

const ProtocolSearch = () => {
  const user = useContext(UserContext);
  const history = useHistory();

  const DEFAULT_REGIONS = user?.regions?.map(
    (r: { name: string }): string => r.name,
  );
  const defaultRegions = JSON.stringify(DEFAULT_REGIONS);
  const defaultActiveStatus = JSON.stringify([ACTIVE]);

  const [searchResultsLength, setSearchResultsLength] = useState<number>(0);
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [filterChange, setFilterChange] = useState<boolean>(false);
  const [currentSearch, setCurrentSearch] = useState<string>("");
  const [selectedTumourStreams, setSelectedTumourStreams] = useState<string[]>(
    JSON.parse(sessionStorage.getItem(TUMOUR_STREAMS) || "[]"),
  );
  const [selectedActiveStatus, setSelectedActiveStatus] = useState<string[]>(
    JSON.parse(sessionStorage.getItem(ACTIVE_STATUS) || defaultActiveStatus),
  );
  const [selectedRegions, setSelectedRegions] = useState<string[]>(
    JSON.parse(sessionStorage.getItem(REGIONS) || defaultRegions),
  );

  useEffect(() => {
    sessionStorage.setItem(
      TUMOUR_STREAMS,
      JSON.stringify(selectedTumourStreams),
    );
  }, [selectedTumourStreams]);

  useEffect(() => {
    sessionStorage.setItem(ACTIVE_STATUS, JSON.stringify(selectedActiveStatus));
  }, [selectedActiveStatus]);

  useEffect(() => {
    sessionStorage.setItem(REGIONS, JSON.stringify(selectedRegions));
  }, [selectedRegions]);

  const [getSearchTerm, result] = useLazyQuery(SEARCH_CAREPLAN_TEMPLATES, {
    fetchPolicy: "network-only",
  });

  const { loading: loadingTumourStreams, data: tumourStreams } = useQuery(
    GET_DIAGNOSIS_CATEGORIES,
    {
      fetchPolicy: "cache-first",
    },
  );
  const { data: regions } = useQuery(GET_REGIONS, {
    fetchPolicy: "cache-first",
  });

  const [createTemplate] = useMutation(CREATE_TEMPLATE, {
    variables: { isMo: true, isRo: false, isTemporary: false },
    onCompleted: (data) => {
      if (data?.createTemplate?.template) {
        const templateId = data.createTemplate.template.id;
        history.push(`/template/${templateId}/information`);
      }
    },
  });

  const createEnabled = canUserCreate(user, selectedRegions);

  const tumourStreamOptions =
    tumourStreams?.diagnosisCategoryList
      ?.map((ts: { name: string }): string => ts.name)
      .sort() || [];
  const regionOptions =
    regions?.regionList?.map((r: { name: string }): string => r.name).sort() ||
    [];

  const getTemplateResults = (
    searchTerm: string,
    pageNumber = 0,
    rowsPerPage = 0,
  ) => {
    const manualSearch = searchTerm ? searchTerm : "";
    let start: number | null;
    let limit: number | null;
    const getSearchResults = (): void => {
      setCurrentSearch(searchTerm);
      getSearchTerm({
        variables: {
          searchTerm: manualSearch,
          tumourStreams: selectedTumourStreams,
          activeStatus: selectedActiveStatus.map((status: string) =>
            activeStatusToBoolean(status),
          ),
          regions: selectedRegions,
          subregions: [],
          start: start ? start : null,
          limit: limit ? limit : null,
          isMo: true,
          isRo: false,
          isTemporary: false,
          includeTreatment: false,
        },
      });
    };
    // Not been provided pagination values so use defaults (initial load)
    if ((!pageNumber && !rowsPerPage) || filterChange) {
      start = null;
      limit = DEFAULT_SEARCH_ROWS;

      setFilterChange(false);
      getSearchResults();
      return;
    }

    // Otherwise get values with pagination values
    start = rowsPerPage * pageNumber;
    limit = rowsPerPage * pageNumber + 100;
    setFilterChange(false);
    getSearchResults();
  };

  useEffect(() => {
    if (result.data) {
      setSearchResultsLength(parseInt(result.data.templateFilter.totalLength));
      const templates = result.data.templateFilter.templates.length
        ? result.data.templateFilter.templates
        : [];
      if (!searchResults.length || !templates.length || filterChange) {
        setSearchResults(templates);
      } else {
        const templateIds = new Set(
          searchResults.map((template) => template.id),
        );
        const combinedResults = [
          ...searchResults,
          ...result.data.templateFilter.templates.filter(
            (template: any) => !templateIds.has(template.id),
          ),
        ];
        setSearchResults(combinedResults);
      }
    }
    // eslint-disable-next-line
  }, [result]);

  // Perform a search when a drop down filter changes
  useEffect(() => {
    // Ckear results before replacing with new filtered data
    setSearchResults([]);
    getTemplateResults(currentSearch);
    // eslint-disable-next-line
  }, [selectedTumourStreams, selectedActiveStatus, selectedRegions]);

  if (loadingTumourStreams) return <div>Loading</div>;

  return (
    <StandardPaddedContainer>
      <Typography variant="h4" gutterBottom>
        Protocol Templates
      </Typography>
      <Grid
        container
        spacing={1}
        style={{ marginBottom: "5px", flexWrap: "unset" }}
        alignItems="center"
      >
        <Grid item>
          <SearchFilter>
            <StyledMenuItem>
              <SearchMultiSelectField
                selectedOptionsUpdated={setSelectedTumourStreams}
                resetSearchResults={() => setFilterChange(true)}
                selectedOptions={selectedTumourStreams}
                options={tumourStreamOptions}
                placeholder="Tumour Stream"
                allSelectedLabel={shared.allTumorStreams}
              />
            </StyledMenuItem>
            <StyledMenuItem>
              <SearchMultiSelectField
                selectedOptionsUpdated={setSelectedRegions}
                resetSearchResults={() => setFilterChange(true)}
                selectedOptions={selectedRegions}
                options={regionOptions}
                placeholder="Region"
                allSelectedLabel={shared.allRegions}
              />
            </StyledMenuItem>
            <StyledMenuItem>
              <CheckboxGroupField
                selectedOptionsUpdated={setSelectedActiveStatus}
                selectedOptions={selectedActiveStatus}
                options={[ACTIVE, INACTIVE]}
                placeholder="Template Status"
              />
            </StyledMenuItem>
          </SearchFilter>
        </Grid>
        <Grid item>
          <SearchField
            dataTestId="template-search"
            placeholderText="Search protocol templates..."
            onEnter={(text) => {
              if (text !== currentSearch) {
                setSearchResults([]);
                setFilterChange(true);
                getTemplateResults(text);
              }
            }}
          />
        </Grid>
        <Grid item container justifyContent="flex-end">
          <Button
            data-test-id="create-mo-template"
            disabled={!createEnabled}
            onClick={() => {
              createTemplate();
            }}
          >
            Create New Protocol
          </Button>
        </Grid>
      </Grid>
      <ProtocolTable
        filterChange={false}
        getTemplates={getTemplateResults}
        templatesLength={searchResultsLength}
        templates={searchResults}
        loading={result.loading}
      />
    </StandardPaddedContainer>
  );
};

export default ProtocolSearch;
