import { styled } from "@mui/material";

export interface ColumnInfo {
  key: number;
  title: string;
  subTitle?: string;
}
export const StyledTable = styled("table")`
  display: block;
  overflow-y: auto;
  max-height: calc(100vh - 457px);
  background-color: ${(props) => props.theme.palette.grey[100]};
  border-top: 0 solid ${(props) => props.theme.palette.grey[300]};
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledTableHeaderRow = styled("tr")`
  height: 40px;
  background-color: ${(props) => props.theme.palette.grey[100]};
  & th:first-child {
    cursor: inherit;
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 2;
    text-align: left;
    background-color: ${(props) => props.theme.palette.grey[100]};
    color: ${(props) => props.theme.palette.text.primary};
    border-left: 1px solid ${(props) => props.theme.palette.grey[100]} !important;
  }
`;

export const StyledTableHeaderCell = styled("th")<{ empty: boolean }>`
  text-align: center;
  border-left: 1px solid ${(props) => props.theme.palette.grey[300]} !important;
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]} !important;
  border-right: 1px solid ${(props) => props.theme.palette.grey[300]} !important;
  background-color: white;
  vertical-align: middle;
  position: sticky;
  top: 0;
  z-index: 1;
  border-top: 0 solid ${(props) => props.theme.palette.grey[300]} !important;
  top: -1px !important;
  min-width: 60px !important;

  background-color: ${(props) =>
    props.empty
      ? props.theme.palette.grey[300]
      : props.theme.palette.primary.contrastText};
  color: ${(props) =>
    props.empty
      ? props.theme.palette.grey[600]
      : props.theme.palette.text.primary};
  cursor: default;
`;

export const StyledTableCell = styled("td")<{
  empty?: boolean;
  minWidth?: number;
}>`
  background-color: white;
  border: 1px solid ${(props) => props.theme.palette.grey[300]};
  vertical-align: middle;
  background-color: ${(props) =>
    props.empty
      ? props.theme.palette.grey[300]
      : props.theme.palette.primary.contrastText};
  min-width: ${(props: { empty?: boolean; minWidth?: number }) =>
    props.minWidth}px;
`;

export const StyledRow = styled("tr")`
  height: 48px;
  & td:first-child {
    left: 0;
    border-left: 0;
    position: -webkit-sticky;
    position: sticky;
  }
`;
