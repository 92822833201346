import React, { useState, useEffect } from "react";
import { TextField, useTheme } from "@mui/material";

interface OarTwoValueTextFieldProps {
  id?: string;
  label?: string;
  required?: boolean;
  block?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  value?: any;
  onChange?: any;
  onBlur?: any;
}

export const OarTwoValueTextFieldWidget = (props: any): JSX.Element => {
  const [state, setState] = useState("");
  const theme = useTheme();
  useEffect((): void => {
    if (props.value) {
      if (Array.isArray(props.value)) {
        setState(props.value.join("/"));
      } else {
        setState(props.value);
      }
    }
  }, [props.value]);
  const onBlur = (): void => {
    if (props.onBlur) {
      props.onBlur();
    }
  };
  const { placeholder, required, readOnly, id } = props;
  return (
    <TextField
      id={id}
      type={"text"}
      placeholder={placeholder || ""}
      value={state || ""}
      style={{
        borderColor: theme.palette.grey[300],
        margin: "6px",
        paddingLeft: "6px",
      }}
      onChange={(event): void => {
        const target = event.target as HTMLInputElement;
        const { value } = target;
        const pattern = /^$|^(\d+(\.\d{0,3})?)((\/)(\d+(\.\d{0,3})?)?)?$/g;
        if (pattern.test(value)) {
          setState(value);
        }
      }}
      onBlur={(): void => {
        if (props.onChange) {
          let numbers = state.split("/");
          numbers = numbers.filter((number: any): boolean => number !== "");
          props.onChange(numbers);
        }
        if (onBlur) onBlur();
      }}
      required={required || false}
      disabled={readOnly || false}
      InputProps={{ disableUnderline: true }}
    />
  );
};

const OarTwoValueTextField = (
  props: OarTwoValueTextFieldProps,
): JSX.Element => {
  const { required, placeholder, readOnly, value, onChange, onBlur, id } =
    props;
  return (
    <OarTwoValueTextFieldWidget
      id={id}
      placeholder={placeholder}
      value={value}
      readOnly={readOnly}
      required={required}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

export default OarTwoValueTextField;
