import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import Status from "components/base/Status";
import TemplateHistory from "components/landingPage/TemplateHistory";
import LandingPageButtons from "components/landingPage/LandingPageButtons";
import TemplateDetails from "components/landingPage/TemplateDetails";

import { GET_CAREPLAN_TEMPLATE } from "graphql/TemplateQueries";
import { GET_TEMPLATE_GROUP } from "graphql/TemplateGroupQueries";
import { canUserEdit, canUserDuplicate } from "shared/utils";
import UserContext from "contexts/UserContext";
import { StandardPaddedContainer } from "components/CommonStyledComponents";

const StyledCard = styled(Card)`
  margin: 10px;
`;

const StyledDetailsCard = styled(Card)`
  margin: 10px;
  min-width: 530px;
`;

const StyledGrid = styled(Grid)`
  margin-left: 20px;
`;

interface ParamTypes {
  templateId: string;
}

const Template = () => {
  const { templateId } = useParams<ParamTypes>();

  const { loading, error, data } = useQuery(GET_CAREPLAN_TEMPLATE, {
    variables: { templateId },
  });

  const templateGroupId = data?.template?.group?.id || undefined;
  const { data: templateGroup } = useQuery(GET_TEMPLATE_GROUP, {
    variables: { templateGroupId },
    fetchPolicy: "network-only",
  });

  if (loading || !data) return <div>Loading</div>;
  if (error) return <div>Error</div>;

  return (
    data &&
    data.template && (
      <TemplateView
        templateId={templateId}
        template={data?.template}
        templateGroup={templateGroup}
      />
    )
  );
};

const TemplateView = (props: any): JSX.Element => {
  const { isActive, isLatestInGroup, status } = props.template;
  const theme = useTheme();
  const titleColour =
    isActive && isLatestInGroup
      ? theme.palette.text.primary
      : theme.palette.grey[600];
  const user = useContext(UserContext);
  return (
    <StandardPaddedContainer>
      <Grid container>
        <StyledGrid>
          <Typography
            data-test-id="template-name"
            variant="h4"
            component="h4"
            style={{ color: titleColour }}
          >
            {props.template.name}
          </Typography>
          <Status status={!isActive ? "archive" : status} />
        </StyledGrid>
        <Grid container justifyContent="flex-start">
          <Grid item>
            <StyledDetailsCard>
              <CardContent>
                <TemplateDetails template={props.template} />
              </CardContent>
            </StyledDetailsCard>
            <StyledCard>
              <CardContent>
                <TemplateHistory
                  templateGroup={props.templateGroup?.templateGroup}
                />
              </CardContent>
            </StyledCard>
          </Grid>
          <Grid item xs>
            <LandingPageButtons
              templateName={props.template.name}
              isActive={isActive}
              status={status}
              isLatestInGroup={isLatestInGroup}
              templateId={props.template.id}
              canUserEdit={canUserEdit(user, props.template)}
              canUserDuplicate={canUserDuplicate(user)}
              isMo={props.template.isMo}
            />
          </Grid>
        </Grid>
      </Grid>
    </StandardPaddedContainer>
  );
};

export default Template;
