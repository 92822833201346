import React from "react";
import { FieldProps, getIn, useFormikContext } from "formik";
import Input from "components/base/Input";
import FormRow from "components/base/FormRow";
import { formatInputValue } from "shared/utils";
import InvalidMessage from "components/base/InvalidMessage";

interface Props extends FieldProps {
  label: string;
  isActive?: boolean;
  toggleActive?: boolean;
  handleMutation: any;
  type: string;
  multiline: boolean;
  rows?: number;
  id: any;
  readOnly: boolean;
  inputProps: any;
  inBulkEdit?: boolean;
  labelXs?: any;
  simpleErrorDisplayLogic?: boolean;
}

const InputField = ({
  field,
  form,
  label,
  id,
  handleMutation,
  isActive,
  toggleActive,
  type = "text",
  multiline = false,
  rows = undefined,
  readOnly,
  inputProps,
  inBulkEdit,
  labelXs,
  simpleErrorDisplayLogic = false,
}: Props) => {
  const { onBlur, name, value, onChange } = field;
  const { values }: { values: any } = useFormikContext();
  const { errors, touched, setFieldValue } = form;
  const [disabled, setDisabled] = React.useState<boolean>(!isActive);
  const fieldError = getIn(errors, name);
  const fieldTouched = getIn(touched, name);
  const handleBlur = (event: React.ChangeEvent<any>) => {
    onBlur(event);
    handleMutation && handleMutation(name, event.target.value);
  };
  const handleDisable = (value: boolean) => {
    setDisabled(!value);
    handleMutation && handleMutation(name, "", value);
  };

  // handle change on custom fields to toggle sub fields
  // TODO: Move these input field custom changes to the page logic instead
  // of component..
  // ***** WARNING ***** : Please don't add the custom logic here.
  React.useEffect(() => {
    if (["Dose"].includes(name) || ["Fractions"].includes(name)) {
      const fractionDose =
        Math.round((values.Dose / values.Fractions + Number.EPSILON) * 100) /
        100;
      setFieldValue("Fraction_Dose", fractionDose || 0);
    }
    // ***** WARNING ***** : Please DON'T add the custom logic here.
    // eslint-disable-next-line
  }, [values]);

  // When isActive changes, update the disabled state
  React.useEffect(() => {
    if (isActive) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [isActive]);

  return (
    <FormRow
      label={label}
      disabled={disabled}
      name={name}
      toggleActive={toggleActive}
      handleDisable={handleDisable}
      inBulkEdit={inBulkEdit}
      readOnly={readOnly}
      labelXs={labelXs}
      errorChildren={
        (simpleErrorDisplayLogic || fieldTouched) &&
        fieldError && <InvalidMessage text={fieldError} />
      }
    >
      <Input
        name={name}
        defaultValue={formatInputValue(value)}
        onChange={onChange}
        id={`${id}-input`}
        variant="outlined"
        onBlur={handleBlur}
        size="medium"
        disabled={disabled || readOnly}
        type={type}
        rows={rows}
        multiline={multiline}
        inputProps={{ ...inputProps, "data-test-id": `${name}-input` }}
        error={Boolean(fieldError) && Boolean(fieldTouched)}
      />
    </FormRow>
  );
};

export default InputField;
