/* eslint-disable no-prototype-builtins */
import React, { useContext, useEffect, useRef, useState } from "react";
import { Switch, Route, useParams, useHistory } from "react-router-dom";
import { Formik, Form, FormikProps } from "formik";
import { styled } from "@mui/material";
import {
  sortSites,
  APPROVE,
  TEST,
  isAu,
  INFORMATION,
  CYCLE,
  PEER_REVIEW,
  PRESCRIPTION,
  SIMULATION,
  VOLUMING,
} from "i18n/constants";
import Information from "./Information";
import Simulation, {
  simulationPageBack,
  simulationPageNext,
} from "./Simulation";
import Completed from "./Completed";
import Prescription, {
  prescriptionPageBack,
  prescriptionPageNext,
} from "./Prescription";
import Voluming, {
  volumingPageBack,
  volumingPageNext,
  volumingPageNextText,
} from "./Voluming";
import Cycle from "./cycle/Cycle";
import PeerReview from "./PeerReview";
import { roValidationSchema, moValidationSchema } from "../validation";
import InformationDefaultValues from "./InformationDefaultValues";
import SideBarNav from "components/SideBarNav/SideBarNav";
import { canUserEdit, getActiveStep } from "shared/utils";
import UserContext from "contexts/UserContext";
import BottomNav from "components/base/BottomNav";
import { StandardPaddedContainer } from "components/CommonStyledComponents";

interface ParamTypes {
  templateId: string;
  form: string;
  pageId: string;
}

interface Props {
  template: any;
  reloadTemplate: () => any;
  listData: any;
  diagnosisCategoryData: any;
  siteListData: any;
}

const TemplateMainContainer = styled("div")`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: calc(100% - 64px);
`;

const TemplateFormContainer = styled("div")`
  flex: 1;
  height: 100%;
  overflow: auto;
`;

const CareplanCreatorForm = ({
  template,
  listData,
  diagnosisCategoryData,
  siteListData,
  reloadTemplate,
}: Props) => {
  const diagnosisCategoryOptions = diagnosisCategoryData.map(
    (category: any) => {
      const { name, anatomicSiteNum } = category;
      return { label: `${anatomicSiteNum} (${name})`, value: name };
    },
  );
  const { templateId, pageId } = useParams<ParamTypes>();
  const user = useContext(UserContext);
  const [valid, setValid] = useState<boolean>();
  const showPeerReview = isAu(template?.regions);

  const siteOptions = siteListData.map((site: { name: string }) => ({
    label: site.name,
    value: site.name,
  }));

  const selectOptions = listData.reduce(
    (categoryOptions: any, categoryOption: any) => {
      const { category, data } = categoryOption;
      const { option } = data;
      categoryOptions[category.category] = !categoryOptions.hasOwnProperty(
        category.category,
      )
        ? [{ label: option, value: option }]
        : [
            ...categoryOptions[category.category],
            { label: option, value: option },
          ];
      return categoryOptions;
    },
    {},
  );

  const setDisableEditing = () => {
    // Don't allow editing of approved or test templates
    if ([APPROVE, TEST].includes(template.status)) {
      return true;
    }
    // Don't allow editing of archived templates
    if (!template.isActive) {
      return true;
    }
    // Don't allow editing by users from different regions
    if (!canUserEdit(user, template)) {
      return true;
    }
    return false;
  };

  const disableEditing = setDisableEditing();
  const siteGroups = template.careplanTreatment;
  const siteTemplate =
    siteGroups.map((siteGroup: any) => sortSites(siteGroup.siteTemplate)) || [];
  const siteGroupIds = siteGroups.map((siteGroup: any) => siteGroup.id);
  const siteIds = siteTemplate.flat().map((site: any) => site?.id);

  const firstGroupId = String(siteGroupIds[0]);
  const lastGroupId = String(siteGroupIds[siteGroupIds.length - 1]);

  const firstSiteId = String(siteIds[0]);
  const lastSiteId = String(siteIds[siteIds.length - 1]);
  const informationDefaultValues = InformationDefaultValues(template);

  // MO Protocol Template Stuff
  const { isRo } = template;

  const ref = useRef<FormikProps<any>>(null);
  const history = useHistory();

  const nextInformationUrl = isRo ? `simulation/${firstGroupId}` : "cycle/0";

  const activeStep = getActiveStep();
  const navigationVars: any = {
    [INFORMATION]: {
      nextUrl: `/template/${templateId}/${nextInformationUrl}`,
      backUrl: `/template/${templateId}`,
      disableBackOnError: false,
      disableNextOnError: false,
      nextFunction: () => {
        if (ref.current) {
          ref.current.submitForm().then(() => {
            ref.current?.validateForm().then((errors) => {
              if (Object.keys(errors).length === 0) {
                // reload the template
                reloadTemplate();
                history.push(`/template/${templateId}/${nextInformationUrl}`);
              }
            });
          });
        }
        if (disableEditing) {
          history.push(`/template/${templateId}/${nextInformationUrl}`);
        }
      },
      backFuction: () => {
        reloadTemplate();
        history.push(`/template/${templateId}`);
      },
    },
    [SIMULATION]: {
      nextUrl: simulationPageNext(
        templateId,
        pageId,
        siteGroupIds,
        firstSiteId,
      ),
      backUrl: simulationPageBack(templateId, pageId, siteGroupIds),
    },
    [CYCLE]: {
      nextUrl: "",
      backUrl: `/template/${templateId}/information`,
    },
    [PRESCRIPTION]: {
      nextUrl: prescriptionPageNext(templateId, pageId, firstGroupId, siteIds),
      backUrl: prescriptionPageBack(templateId, pageId, lastGroupId, siteIds),
    },
    [VOLUMING]: {
      nextUrl: volumingPageNext(
        templateId,
        pageId,
        siteGroupIds,
        showPeerReview,
      ),
      backUrl: volumingPageBack(templateId, pageId, siteGroupIds, lastSiteId),
      nextLabel: volumingPageNextText(pageId, siteGroupIds, showPeerReview),
    },
    [PEER_REVIEW]: {
      nextUrl: `/template/${templateId}`,
      backUrl: `/template/${templateId}/voluming/${lastGroupId}`,
      nextLabel: "Template Page",
    },
  };
  useEffect(() => {
    if (template && valid === undefined) {
      const schema = template.isMo ? moValidationSchema : roValidationSchema;
      schema.isValid(informationDefaultValues).then((valid) => {
        setValid(valid);
      });
    }
  }, [template, informationDefaultValues, valid]);

  useEffect(() => {
    if (reloadTemplate) {
      reloadTemplate();
    }
  }, [reloadTemplate, pageId]);
  return (
    <>
      <TemplateMainContainer>
        <SideBarNav
          showPeerReview={showPeerReview}
          valid={disableEditing || Boolean(valid)}
          isRo={isRo}
        />

        <TemplateFormContainer>
          <Switch>
            <Route
              path={"/template/:templateId/information"}
              render={() =>
                template && (
                  <Formik
                    enableReinitialize
                    initialValues={informationDefaultValues}
                    validationSchema={
                      template.isMo ? moValidationSchema : roValidationSchema
                    }
                    validateOnMount={false}
                    validateOnChange={true}
                    validateOnBlur={false}
                    innerRef={ref}
                    onSubmit={() => {}}
                  >
                    <Form>
                      <StandardPaddedContainer>
                        <Information
                          template={template}
                          selectOptions={selectOptions}
                          diagnosisCategoryOptions={diagnosisCategoryOptions}
                          siteOptions={siteOptions}
                          disableEditing={disableEditing}
                          setFormValid={(value: boolean) => {
                            setValid(value);
                          }}
                        />
                      </StandardPaddedContainer>
                    </Form>
                  </Formik>
                )
              }
            />

            <Route
              path={"/template/:templateId/simulation/:groupId"}
              render={() => (
                <StandardPaddedContainer>
                  <Simulation
                    selectOptions={selectOptions}
                    disableEditing={disableEditing}
                    groupIds={siteGroupIds}
                  />
                </StandardPaddedContainer>
              )}
            />

            <Route
              path={"/template/:templateId/cycle/:selectedCycle"}
              render={() => (
                <Cycle
                  selectOptions={selectOptions}
                  disableEditing={disableEditing}
                  template={template}
                />
              )}
            />

            <Route
              path={"/template/:templateId/prescription/:siteId"}
              render={() => (
                <StandardPaddedContainer>
                  <Prescription
                    selectOptions={selectOptions}
                    disableEditing={disableEditing}
                    regions={template?.regions}
                  />
                </StandardPaddedContainer>
              )}
            />

            <Route
              path={"/template/:templateId/voluming/:groupId"}
              render={() => (
                <StandardPaddedContainer>
                  <Voluming
                    disableEditing={disableEditing}
                    groupIds={siteGroupIds}
                  />
                </StandardPaddedContainer>
              )}
            />

            <Route
              path={"/template/:templateId/peerReview"}
              render={() => (
                <StandardPaddedContainer>
                  <PeerReview
                    template={template}
                    selectOptions={selectOptions}
                    disableEditing={disableEditing}
                  />
                </StandardPaddedContainer>
              )}
            />
            <Route
              path={"/template/:templateId/completed"}
              render={() => <Completed lastGroupId={lastGroupId} />}
            />
          </Switch>
        </TemplateFormContainer>
      </TemplateMainContainer>

      <BottomNav
        backUrl={navigationVars[activeStep].backUrl}
        nextUrl={navigationVars[activeStep].nextUrl}
        disableBack={navigationVars[activeStep].disableBackOnError}
        disableNext={navigationVars[activeStep].disableNextOnError}
        nextLabel={navigationVars[activeStep].nextLabel}
        backLabel={navigationVars[activeStep].backLabel}
        nextFunction={navigationVars[activeStep].nextFunction}
        backFunction={navigationVars[activeStep].backFuction}
      />
    </>
  );
};

export default CareplanCreatorForm;
