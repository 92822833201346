import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Typography, List, Divider, styled } from "@mui/material";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import { getNiceStatus } from "i18n/constants";

interface ParamTypes {
  templateId: string;
}

const StyledDiv = styled("div")`
  display: flex;
  align-items: center;
`;

const StyledDerivedFromDiv = styled("div")`
  padding-left: 15px;
  display: flex;
  align-items: center;
`;

const StyledClickableTypography = styled(Typography)`
  cursor: pointer;
`;

const StyledClickableOffsetTypography = styled(Typography)`
  cursor: pointer;
  margin-left: 12px;
`;

const StyledSelectedTypography = styled(Typography)`
  font-weight: bold;
  cursor: default;
`;

const goToTemplate = (history: any, templateId: string) =>
  history.push(`/template/${templateId}`);

const templateText = (template: any) => {
  const templateStatus = getNiceStatus(
    template.isActive ? template.status : "archive",
  ).replace(" in Progress", "");
  return `[ID: ${template.id}] ${template.name} (${templateStatus})`;
};

const TemplateHistory = (props: any) => {
  const { templateId } = useParams<ParamTypes>();
  const history = useHistory();

  const unsortedTemplates = props.templateGroup?.templates;
  const templates = unsortedTemplates
    ?.slice()
    .sort((a: any, b: any) => b.createdAt.localeCompare(a.createdAt));

  const showChildTemplates = templates?.find(
    (template: any) => template.children?.length > 0,
  );

  return (
    <List>
      <Typography variant="h5" component="h5">
        Versioning
      </Typography>
      {templates &&
        templates
          .sort((a: any, b: any) => b.id - a.id)
          .map((template: any, index: number) => {
            const isSelected = template.id === templateId;
            const historyRow = isSelected ? (
              <>
                <RadioButtonCheckedIcon style={{ fontSize: "14px" }} />
                <StyledSelectedTypography
                  data-test-id={`template-version-${index}`}
                >
                  {templateText(template)}
                </StyledSelectedTypography>
              </>
            ) : (
              <>
                <RadioButtonUncheckedIcon style={{ fontSize: "14px" }} />
                <StyledClickableTypography
                  data-test-id={`template-version-${index}`}
                  onClick={() => goToTemplate(history, template.id)}
                >
                  {templateText(template)}
                </StyledClickableTypography>
              </>
            );
            return (
              <div key={template.id}>
                <StyledDiv>{historyRow}</StyledDiv>
                {template.derivedFrom && (
                  <StyledDerivedFromDiv>
                    <SubdirectoryArrowRightIcon style={{ fontSize: "14px" }} />
                    <StyledClickableTypography
                      data-test-id="template-parent"
                      onClick={() =>
                        goToTemplate(history, template.derivedFrom.id)
                      }
                    >
                      {`Duplicated from ${templateText(template.derivedFrom)}`}
                    </StyledClickableTypography>
                  </StyledDerivedFromDiv>
                )}
              </div>
            );
          })}
      {showChildTemplates && (
        <>
          <Divider />
          <Typography variant="h6" component="h6" style={{ marginTop: "10px" }}>
            Duplicated from this template:
          </Typography>
          {templates?.map((template: any) =>
            template.children?.map((childTemplate: any, index: number) => (
              <StyledClickableOffsetTypography
                data-test-id={`template-child-${index}`}
                key={childTemplate.id}
                onClick={() => goToTemplate(history, childTemplate.id)}
              >
                {templateText(childTemplate)}
              </StyledClickableOffsetTypography>
            )),
          )}
        </>
      )}
    </List>
  );
};

export default TemplateHistory;
