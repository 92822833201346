import React, { useEffect, useState } from "react";
import { TableRow, TableBody, TablePagination, useTheme } from "@mui/material";
import { useHistory } from "react-router-dom";
import {
  StyledPaper,
  StyledTableHead,
  StyledTableContainer,
  StyledTable,
  SmallTableCell,
  MiniTableCell,
  MediumTableCell,
  LargeTableCell,
} from "components/TableStyles";
import {
  directionalComparitor,
  prettyDate,
  sortArrayByComparitor,
} from "shared/utils";
import {
  TemplateColumnInterface,
  ProtocolTemplateInterface,
} from "./TableInterfaces";
import { formatEvidence, cycles } from "i18n/constants";
import {
  templateColumn,
  getCountryList,
  ROWS_PER_PAGE,
  StatusTrafficLight,
  ColorFromStatus,
  BeautifyStatus,
  PlaceholderRow,
} from "./tableUtils";

interface Props {
  templates: ProtocolTemplateInterface[];
  getTemplates(options: string, pageCount: number, rowsCount: number): void;
  templatesLength: number;
  filterChange: boolean;
  loading: boolean;
}

const TEMPLATE_TABLE_COLUMNS: TemplateColumnInterface[] = [
  { label: "Tumour Stream", attribute: "tumourStreamName" },
  { label: "Protocol Name", attribute: "name" },
  { label: "Evidence", attribute: "evidenceId" },
  { label: "Region", attribute: "regions" },
  { label: "Status", attribute: "status" },
  { label: "Cycles", attribute: "numberOfCycles" },
  { label: "Days", attribute: "daysPerCycle" },
  { label: "Last Modified", attribute: "createdAt" },
];

const protocolRow = (
  template: ProtocolTemplateInterface,
  key: string,
  onClick: (path: string) => void,
  theme: any,
): JSX.Element => {
  // Gray out inactive templates
  const backgroundStyling = {
    backgroundColor: !template.isActive ? theme.palette.grey[100] : undefined,
    cursor: "pointer",
  };
  const cellStyling = {
    color: !template.isActive
      ? theme.palette.grey[600]
      : theme.palette.text.primary,
  };

  return (
    <TableRow
      style={backgroundStyling}
      data-test-id={`search-results-${template.id}`}
      hover
      onClick={() => onClick(`/template/${template.id}`)}
      key={key}
    >
      <MediumTableCell style={cellStyling}>
        {template?.tumourStreamName}
      </MediumTableCell>
      <LargeTableCell>
        {template.name}
        <br />
        {`ID: ${template.id}`}
      </LargeTableCell>
      <MediumTableCell style={cellStyling}>
        {formatEvidence(template.evidenceId, template.evidenceVersion)}
      </MediumTableCell>
      <MiniTableCell style={cellStyling}>
        {getCountryList(template.regions)}
      </MiniTableCell>
      <MiniTableCell style={cellStyling}>
        <StatusTrafficLight
          color={
            template.isActive
              ? ColorFromStatus(template.status)
              : theme.palette.error.main
          }
        >
          {template.isActive ? BeautifyStatus(template.status) : "Archived"}
        </StatusTrafficLight>
      </MiniTableCell>
      <SmallTableCell style={cellStyling}>
        {cycles(template?.continuous, template?.numberOfCycles)}
      </SmallTableCell>
      <SmallTableCell style={cellStyling}>
        {template.daysPerCycle}
      </SmallTableCell>
      <MediumTableCell style={cellStyling}>
        {prettyDate(template.createdAt)}
      </MediumTableCell>
    </TableRow>
  );
};

const ProtocolTable = ({
  getTemplates,
  templates,
  templatesLength,
  filterChange,
  loading,
}: Props) => {
  const history = useHistory();
  const theme = useTheme();
  const [rowsPerPage, setRowsPerPage] = useState<number>(ROWS_PER_PAGE[0]);
  const [page, setPage] = useState<number>(0);
  const [orderDirection, setOrderDirection] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<string>("");

  useEffect(() => {
    if (filterChange) {
      setPage(0);
      handlePagination(0, false, 0);
    }
    // eslint-disable-next-line
  }, [filterChange]);

  const handleSortingCallback = (attribute: string) => {
    const isAsc = orderBy === attribute && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(attribute);
    setPage(0);
  };

  const handlePagination = (
    pageNumber: number,
    includesRowsPerPage: boolean,
    row: number,
  ) => {
    // If attempting to reach a page beyond current results amount, exit function
    if (pageNumber && row && pageNumber * row >= templates.length) return;
    setPage(pageNumber);
    if (includesRowsPerPage) setRowsPerPage(row);
    const pageCount = pageNumber ? pageNumber : 0;
    const rowsCount = row ? row : rowsPerPage;
    getTemplates("", pageCount, rowsCount);
  };

  return (
    <StyledPaper>
      <StyledTableContainer>
        <StyledTable stickyHeader data-test-id={"care-plan-table"}>
          <StyledTableHead>
            <TableRow>
              {TEMPLATE_TABLE_COLUMNS.map((header, index) =>
                templateColumn(
                  header,
                  `careplan-header-${index}`,
                  orderBy,
                  orderDirection,
                  handleSortingCallback,
                ),
              )}
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {templates.length ? (
              sortArrayByComparitor(
                templates,
                directionalComparitor(orderDirection, orderBy),
              )
                // eslint-disable-next-line
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((template: ProtocolTemplateInterface, index: number) => {
                  return protocolRow(
                    template,
                    `careplan-row-${index}`,
                    (path) => history.push(path),
                    theme,
                  );
                })
            ) : (
              <PlaceholderRow loading={loading} colSpan={8} />
            )}
          </TableBody>
        </StyledTable>
      </StyledTableContainer>
      <TablePagination
        rowsPerPageOptions={ROWS_PER_PAGE}
        component="div"
        count={templatesLength}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(_, newPage) => handlePagination(newPage, false, 0)}
        onRowsPerPageChange={(e) => {
          handlePagination(0, true, parseInt(e.target.value, 10));
        }}
      />
    </StyledPaper>
  );
};

export default ProtocolTable;
