import moment from "moment";
import { Moment } from "moment";
import { detect } from "detect-browser";
import {
  INFORMATION,
  SIMULATION,
  PRESCRIPTION,
  VOLUMING,
  PEER_REVIEW,
  BULK_EDIT_REVIEW,
  CYCLE,
  moPages,
  bulkEditPages,
  basePages,
} from "i18n/constants";
import { DrugOrderInterface, User } from "interfaces/formulary";
import { UserContextType } from "contexts/UserContext";

// helper functions
export const getSiteName = (site: any): string => {
  let siteName = site.treatmentSite.treatmentSite;
  if (site.treatmentSite.treatmentSite.toLowerCase().includes("specify")) {
    const location = site.sitevaluesSet.filter(
      (values: any): any => values.field.name === "Location",
    );
    if (location[0]) {
      siteName = `${siteName} (${JSON.parse(location[0].value)})`;
    }
  }
  return siteName;
};

export const extractValueFromSite = (site: any, name: string): string => {
  const siteValue = site.sitevaluesSet.filter(
    (values: any): any => values.field.name.toLowerCase() === name,
  );
  return siteValue[0] ? JSON.parse(siteValue[0].value) : "";
};

export const getSiteRepresentation = (site: any) => {
  const siteName = getSiteName(site);
  const technique = extractValueFromSite(site, "technique");
  const dose = extractValueFromSite(site, "dose");
  const fractions = extractValueFromSite(site, "fractions");

  const doseString = fractions ? dose + "/" + fractions : dose;

  return [siteName, technique, doseString].filter(Boolean).join(" ");
};

export const prettyDate = (date: Moment) => {
  // Taken from everest code base
  return moment(date.toString()).format("DD/MM/YYYY") ===
    moment().format("DD/MM/YYYY")
    ? `Today ${moment(date.toString()).format("hh:mm A")}`
    : moment(date.toString()).format("DD/MM/YYYY hh:mm A");
};

// Sorting utilities
export const sortArrayByComparitor = (array: any, comparator: any) => {
  const data = array.map((el: any, index: number) => [el, index]);
  data.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return data.map((el: any) => el[0]);
};

export const directionalComparitor = (order: string, orderBy: any) => {
  const descendingComparator = (a: any, b: any, orderBy: string) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  return order === "desc"
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
};

export const formatToThreeDecimal = new Intl.NumberFormat("en-US", {
  style: "decimal",
  minimumFractionDigits: 0,
  maximumFractionDigits: 3,
});

export const decimalFormatter = (value: any) =>
  value ? formatToThreeDecimal.format(value) : value;

export const LESS_THAN_OR_EQUAL = "≤";
export const EQUALS = "=";

export const setBolusValues = (
  siteId: string,
  isActive: boolean,
  defaultValues: any,
) => {
  const Bolus_Location = defaultValues?.Bolus_Location || "";
  const Bolus_Thickness = defaultValues?.Bolus_Thickness || "";
  const Bolus_Thickness_Custom = defaultValues?.Bolus_Thickness_Custom || "";
  const Bolus_Frequency = defaultValues?.Bolus_Frequency || "";
  const Is_3d_Bolus = defaultValues?.Is_3d_Bolus || "";
  const bLocation = {
    siteId,
    groupId: siteId,
    fieldName: "Bolus_Location",
    defaultValue: isActive ? Bolus_Location : "",
    isShown: isActive,
    isActive,
  };
  const bThickness = {
    siteId,
    groupId: siteId,
    fieldName: "Bolus_Thickness",
    defaultValue: isActive ? Bolus_Thickness : "",
    isShown: isActive,
    isActive,
  };
  const bThicknessCustom = {
    siteId,
    groupId: siteId,
    fieldName: "Bolus_Thickness_Custom",
    defaultValue: isActive ? Bolus_Thickness_Custom : "",
    isShown: isActive,
    isActive,
  };
  const bFrequency = {
    siteId,
    groupId: siteId,
    fieldName: "Bolus_Frequency",
    defaultValue: isActive ? Bolus_Frequency || "" : "",
    isShown: isActive,
    isActive,
  };
  const bIs3d = {
    siteId,
    groupId: siteId,
    fieldName: "Is_3d_Bolus",
    defaultValue: isActive ? Is_3d_Bolus || "" : "",
    isShown: isActive,
    isActive,
  };
  return {
    bLocation,
    bThickness,
    bThicknessCustom,
    bFrequency,
    bIs3d,
  };
};

export const canUserEdit = (user: UserContextType, data: any) => {
  // Get only the region names
  const userRegions = user?.editRegions?.map((r: any) => r.name);
  const templateRegions = data?.regions?.map((r: any) => r.name);

  // Find if there are any intersecting regions between the user and the template
  if (
    userRegions.filter((name: string) => templateRegions.includes(name)).length
  ) {
    return true;
  }
  return false;
};

export const canUserDuplicate = (user: UserContextType) => {
  // If user has no regions return false
  return user?.regions?.length > 0;
};

export const navigateToExternalURL = (url: string) => {
  const browser = detect();
  if (browser && browser.name === "edge" && parseInt(browser.version) <= 18) {
    // Navigating to external page with href not supported in edge 18 and belo
    // @typescript-eslint/ban-ts-commen
    window.location.replace(encodeURI(url));
  } else {
    window.location.href = encodeURI(url);
  }
};

export const canUserCreate = (
  user: UserContextType,
  selectedRegions: string[] = [],
) => {
  const userEditRegions = user?.editRegions;
  if (selectedRegions?.length !== 1) return false;
  if (
    userEditRegions?.length &&
    userEditRegions
      ?.map((region: { name: string }) => region.name)
      .includes(selectedRegions[0])
  )
    return true;
  return false;
};

export const getUserSearch = (user: UserContextType) => {
  if (user.roAccess) return "/search";
  return "/protocol_search";
};

export interface RegionInterface {
  id: string;
  name: string;
}

export interface TemplateInterface {
  id: string;
  regions: RegionInterface[];
  subregions: RegionInterface[];
  isMo: boolean;
  isRo: boolean;
  tumourStream: { anatomicSiteNum: string; name: string };
  createdAt: string;
  createdBy: string;
  siteFilter: string;
  doseFilter: string;
  techniqueFilter: string;
  evidenceId: string;
  evidenceVersion: string;
  evidenceLink: string;
  continuous: boolean;
  numberOfCycles: number;
  daysPerCycle: string;
  isNational: boolean;
}

interface FieldInterface {
  field: {
    name: string;
    id: string;
  };
}

export const getSelectedForBulkEdit = (fieldArray: any[], name: string) =>
  fieldArray?.find((field: FieldInterface) => field.field.name === name)
    ?.isSelectedForBulkEdit || false;

export const formatFieldName = (field: string) => field.replace(/_/g, " ");

export const formatInputValue = (value: any) => {
  return value === 0 ? 0 : value || "";
};

export const getActiveStep = () => {
  const urlPath = window.location.pathname;
  if (urlPath.includes("information")) return INFORMATION;
  if (urlPath.includes("simulation")) return SIMULATION;
  if (urlPath.includes("prescription")) return PRESCRIPTION;
  if (urlPath.includes("voluming")) return VOLUMING;
  if (urlPath.includes("peerReview")) return PEER_REVIEW;
  if (urlPath.includes("review")) return BULK_EDIT_REVIEW;
  if (urlPath.includes("cycle")) return CYCLE;
  return "";
};

export const getTemplatePages = (
  isRo: boolean,
  showPeerReview?: boolean,
  isBulkEdit?: boolean,
): string[] => {
  if (!isRo) return moPages;
  if (isBulkEdit) return bulkEditPages;
  if (showPeerReview) return basePages.concat([PEER_REVIEW]);
  return basePages;
};

export const getDrugOrderDoseTitle = (
  drugOrder: DrugOrderInterface,
): string => {
  if (drugOrder.doseBasis) return "Dose Basis";
  if (drugOrder.doseIsRange) return "Dose Range";
  return "Dose";
};

export const getDrugOrderDoseValue = (
  drugOrder: DrugOrderInterface,
): string => {
  if (drugOrder.doseBasis)
    return `${drugOrder.doseBasis} ${drugOrder.doseUnit.name}`;
  if (drugOrder.doseIsRange)
    return `${drugOrder.minimumDose} - ${drugOrder.maximumDose} ${drugOrder.doseUnit.name}`;
  return `${drugOrder.minimumDose} ${drugOrder.doseUnit.name}`;
};

export const formatUserName = (user: User | null): string => {
  return user ? `${user.lastName} ${user.firstName && user.firstName[0]}` : "";
};

export const scrollToErrorField = (errors: any): void => {
  if (errors) {
    const keys = Object.keys(errors);
    if (keys.length > 0) {
      scrollToElementWithName(keys[0]);
    }
  }
};

export const scrollToElementWithName = (name: string): void => {
  const selector = `[name=${name}]`;
  const errorElement = document.querySelector(selector) as HTMLElement;
  if (errorElement) {
    errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
    errorElement.focus({ preventScroll: true });
  }
};
