import { gql } from "@apollo/client";
import { OAR_FRAGMENT } from "./OarQueries";

export const GET_SITE_GROUP_TEMPLATE = gql`
  ${OAR_FRAGMENT}
  query SiteGroupTemplate($groupId: ID!) {
    siteGroupTemplate(groupId: $groupId) {
      id
      name
      oar {
        ...oarFragment
      }
      siteGroupRules {
        id
        field {
          id
          name
        }
        defaultValue
        isShown
        isActive
        isSelectedForBulkEdit
      }
    }
  }
`;

export const ADD_SITE_GROUP_TEMPLATE = gql`
  mutation createSiteGroupTemplate($templateId: ID, $name: String) {
    createSiteGroupTemplate(templateId: $templateId, name: $name) {
      siteGroupTemplate {
        id
      }
    }
  }
`;

export const DELETE_SITE_GROUP_TEMPLATE = gql`
  mutation deleteSiteGroupTemplate($siteGroupId: ID) {
    deleteSiteGroupTemplate(siteGroupId: $siteGroupId) {
      ok
    }
  }
`;

export const UPDATE_TEMPLATE_GROUP_FIELDS = gql`
  mutation UpdateTreatmentRuleSiteGroup(
    $fieldName: String!
    $groupId: ID!
    $defaultValue: String
    $isShown: Boolean
    $isSelectedForBulkEdit: Boolean
  ) {
    updateTreatmentRuleSiteGroup(
      fieldName: $fieldName
      groupId: $groupId
      defaultValue: $defaultValue
      isShown: $isShown
      isSelectedForBulkEdit: $isSelectedForBulkEdit
    ) {
      treatmentRuleSiteGroup {
        id
        field {
          id
          name
        }
        defaultValue
        isShown
        isActive
        isSelectedForBulkEdit
        createdBy
      }
    }
  }
`;
