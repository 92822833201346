import React, { Fragment, useEffect, useRef } from "react";
import { FieldProps } from "formik";
import FormRow from "components/base/FormRow";
import Checkbox from "components/base/Checkbox";

interface Props extends FieldProps {
  label: string;
  handleMutation: any;
  isActive?: boolean;
  toggleActive?: boolean;
  readOnly: boolean;
  labelXs?: any;
}

const CheckboxField = ({
  field,
  form,
  label,
  handleMutation,
  readOnly,
  isActive,
  toggleActive,
  labelXs,
}: Props) => {
  const { name, value } = field;
  const { setFieldValue } = form;

  const [disabled, setDisabled] = React.useState<boolean>(!isActive);
  const handleChange = (
    event: React.ChangeEvent<{ checked: boolean; name: string }>
  ) => {
    const checked = event.target.checked;
    handleMutation && handleMutation(name, checked);
    setFieldValue(name, checked);
  };
  const handleDisable = (value: boolean) => {
    setDisabled(!value);
    handleMutation && handleMutation(name, "", value);
    // field has been disabled remove any set value
    // TODO: Should this be set which would trigger template update?
    if (!value) {
      setFieldValue(name, false);
    }
  };

  // Avoid running the handleDisabled the first time the component loads this would reset
  // current values.
  const firstUpdate = useRef(true);
  useEffect(() => {
    if (typeof isActive !== "undefined" && !firstUpdate.current)
      handleDisable(isActive);
    else firstUpdate.current = false;
    // eslint-disable-next-line
  }, [isActive]);

  return (
    <Fragment>
      <FormRow
        label={label}
        name={name}
        readOnly={readOnly}
        disabled={disabled}
        toggleActive={toggleActive}
        handleDisable={handleDisable}
        labelXs={labelXs}
      >
        <Checkbox
          checked={value}
          onChange={handleChange}
          disabled={readOnly}
          data-test-id={`${name}-checkbox`}
        />
      </FormRow>
    </Fragment>
  );
};

export default CheckboxField;
