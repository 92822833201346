import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
} from "@mui/material";

const ArchiveModal = ({
  onConfirm,
  onClose,
  open,
  title,
  bodyText,
  noteText,
}: any) => {
  const [busy, setBusy] = useState(false);
  useEffect(() => {
    if (open) {
      setBusy(false);
    }
  }, [open]);
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <p>{bodyText}</p>
        <p style={{ marginBottom: "-20px" }}>
          <b>{noteText}</b>
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          data-testid="archive-modal-confirm-button"
          onClick={() => {
            setBusy(true);
            onConfirm();
          }}
          color="primary"
          disabled={busy}
        >
          Archive
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ArchiveModal;
