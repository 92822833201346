import { Divider, styled } from "@mui/material";

const OarDivider = styled(Divider)`
&& {
  margin-top: 15px;
  margin-bottom: 15px;
  }
}}
`;

export default OarDivider;
