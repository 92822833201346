import { gql } from "@apollo/client";

export const GET_TEMPLATE_GROUP = gql`
  query templateGroup($templateGroupId: ID) {
    templateGroup(templateGroupId: $templateGroupId) {
      id
      name
      parentTemplateGroup {
        id
        name
      }
      templates {
        id
        name
        status
        isActive
        isLatestInGroup
        createdAt
        derivedFrom {
          id
          name
          createdAt
          status
          isActive
          isLatestInGroup
        }
        children {
          id
          name
          createdAt
          status
          isActive
          isLatestInGroup
        }
      }
    }
  }
`;

export const GET_TEMPLATE_STRUCTURE = gql`
  query templateStructure($templateId: ID!) {
    template(templateId: $templateId) {
      id
      name
      careplanTreatment {
        id
        name
        siteTemplate {
          id
          site {
            name
          }
        }
      }
    }
  }
`;
