import React from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { CREATE_TEMPLATE } from "graphql/TemplateQueries";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  styled,
} from "@mui/material";

interface Props {
  bulkEditList: any[];
  open: boolean;
  setOpen: (value: boolean) => void;
  selectedRegion: string;
}

const ScrollingModalBody = styled("div")`
&& {
  max-height: 300px;
}}
  color: ${(props) => props.theme.palette.text.primary};
`;

const TotalSelectedLine = styled("p")`
  font-weight: bold;
`;

const BulkEditSelectionModal = (props: Props) => {
  const history = useHistory();

  const [createTemplate] = useMutation(CREATE_TEMPLATE, {
    variables: {
      region: props.selectedRegion,
      isRo: true,
      isMo: false,
      isTemporary: true,
    },
    onCompleted: (data) => {
      if (data?.createTemplate?.template) {
        const templateId = data.createTemplate.template.id;
        const groupId = data.createTemplate.template.careplanTreatment[0].id;
        history.push(`/bulk_edit/${templateId}/voluming/${groupId}`);
      }
    },
  });

  const commenceBulkEdit = () => {
    sessionStorage.setItem(
      "bulkEditIds",
      JSON.stringify(props.bulkEditList.map((c) => c.id)),
    );
    createTemplate();
  };

  return (
    <Dialog open={props.open} onClose={() => props.setOpen(false)}>
      <DialogTitle>Selected careplan templates - Bulk Edit</DialogTitle>
      <DialogContent style={{ display: "flex", flexDirection: "column" }}>
        <ScrollingModalBody>
          <TotalSelectedLine>
            Total templates selected: {props.bulkEditList.length}
          </TotalSelectedLine>
          <ol>
            {props.bulkEditList.map((careplan) => (
              <li key={careplan.id}>{careplan.name}</li>
            ))}
          </ol>
        </ScrollingModalBody>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => props.setOpen(false)}
          variant="outlined"
          data-test-id="Cancel-button"
        >
          Cancel
        </Button>
        <Button
          onClick={() => commenceBulkEdit()}
          variant="outlined"
          color="primary"
          data-test-id="Continue edit-button"
        >
          Continue edit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BulkEditSelectionModal;
