import React from "react";
import ToggleButton from "components/base/ToggleButton";
import { Grid } from "@mui/material";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { GenericRule } from "./constants";

interface Props {
  id: string;
  index: number;
  rule: GenericRule;
  readOnly?: boolean;
  defaultValue: any[];
  onChange: (index: number, value: GenericRule) => void;
  onBlur?: () => void;
}

const BOOLEAN_OPTIONS = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

const PeerReviewBooleanField = (props: Props) => {
  const { id, index, readOnly, defaultValue, rule, onBlur, onChange } = props;

  // defaultValue is a list but we only want the first element if it is a boolean
  const value = defaultValue.length > 0 ? defaultValue[0] : undefined;
  const handleChange = (_e: any, newValue: boolean) => {
    onChange(index, {
      id: rule?.id,
      field: rule?.field,
      defaultValue: [newValue],
      operator: rule?.operator,
    });
  };
  return (
    <Grid item xs={6}>
      <ToggleButtonGroup
        style={{ maxHeight: "34px", marginLeft: "54px" }}
        value={value}
        exclusive
        onChange={handleChange}
        onBlur={onBlur}
        aria-label={`${id}-group`}
        data-test-id={`${id}-button-group`}
      >
        {BOOLEAN_OPTIONS.map(
          (option: { label: string; value: any }, idx: number) => (
            <ToggleButton
              key={idx}
              selected={value === option.value}
              value={option.value}
              aria-label={option.label}
              disabled={readOnly}
              data-test-id={`${id}-button-${option.value}`}
            >
              {option.label}
            </ToggleButton>
          )
        )}
      </ToggleButtonGroup>
    </Grid>
  );
};

export default PeerReviewBooleanField;
