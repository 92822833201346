import React from "react";
import SelectField from "components/formFields/SelectField";
import { TextField } from "@mui/material";
import { sortSites } from "i18n/constants";
import { Field } from "formik";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  IconButton,
  styled,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Autocomplete from "@mui/material/Autocomplete";

interface Props {
  siteTemplate: any;
  isActive: boolean;
  index: number;
  options: any[];
  handleUpdate: any;
  handleDelete: any;
  siteGroupCount: number;
  updateSiteName: any;
  siteOptions: any;
}

const StyledTableHead = styled(TableHead)`
  background-color: ${(props) => props.theme.palette.grey[100]};
`;

const StyledTableHeaderCell = styled(TableCell)`
  font-weight: bold;
  border-bottom-width: 0px;
`;

const StyledTable = styled(Table)`
  width: 100%;
  border-spacing: 0 16px;
  border-collapse: separate;
`;

const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-head {
    background-color: black;
    color: white;
  }
  &.MuiTableCell-body {
    font-size: 14;
    padding-top: 4px;
    padding-bottom: 4px;
    border-top-width: 1px;
    border-color: ${(props) => props.theme.palette.grey[300]};
    border-style: solid;
    border-right-width: 0px;
    border-left-width: 0px;
    &:first-of-type {
      border-left-width: 1px;
    }
    &:last-of-type {
      border-right-width: 1px;
    }
  }
`;

const treatmentSiteGroupCols = ["Site", "Move to Site Group", "Delete"];

const TreatmentSitesTable = ({
  siteTemplate,
  isActive,
  index,
  options,
  handleUpdate,
  handleDelete,
  siteGroupCount,
  updateSiteName,
  siteOptions,
}: Props) => (
  <TableContainer>
    <StyledTable aria-label="customized table">
      <colgroup>
        <col style={{ width: "40%" }} />
        <col style={{ width: "50%" }} />
        <col style={{ width: "10%" }} />
      </colgroup>
      <StyledTableHead>
        <TableRow>
          {treatmentSiteGroupCols.map((header, index) => (
            <StyledTableHeaderCell
              align="left"
              key={`site-group-header-${index}`}
            >
              {header}
            </StyledTableHeaderCell>
          ))}
        </TableRow>
      </StyledTableHead>
      <TableBody>
        {sortSites(siteTemplate).map((siteTemplate: any, rowIndex: number) => {
          const name = siteTemplate?.site?.name;
          const baseKey = `${index}-${rowIndex}`;
          return (
            <TableRow key={baseKey}>
              <StyledTableCell
                data-test-id={`name-${baseKey}`}
                id={`name-${baseKey}`}
                scope="row"
              >
                <Autocomplete
                  id={`site-select-${baseKey}`}
                  options={siteOptions}
                  autoHighlight
                  getOptionLabel={(option: { label: string; value: string }) =>
                    option.label
                  }
                  value={{ label: name, value: name }}
                  disableClearable
                  isOptionEqualToValue={(
                    option: { label: string; value: string },
                    value: { value: string },
                  ): boolean => value.value === option.value}
                  onChange={(
                    _event,
                    value: { label: string; value: string } | null,
                  ) => {
                    if (value !== null) {
                      updateSiteName({
                        variables: {
                          siteId: siteTemplate.id,
                          name: value.value,
                        },
                      });
                    }
                  }}
                  disabled={!isActive}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                {siteGroupCount > 1 && (
                  <Field
                    component={SelectField}
                    id={`moveGroup-${baseKey}`}
                    name={name}
                    width={"300px"}
                    showComponentOnly={true}
                    placeholder="Select Site Group"
                    options={options}
                    isActive={isActive}
                    handleMutation={(_: any, value: any) =>
                      handleUpdate(siteTemplate.id, value)
                    }
                  />
                )}
              </StyledTableCell>
              <StyledTableCell align="right">
                <IconButton
                  id={`delete-${baseKey}`}
                  disabled={!isActive}
                  onClick={() => handleDelete(rowIndex, siteTemplate.id)}
                  size="large"
                >
                  <DeleteIcon color="error" />
                </IconButton>
              </StyledTableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </StyledTable>
  </TableContainer>
);

export default TreatmentSitesTable;
