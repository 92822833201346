import { Switch, styled } from "@mui/material";

const GreenSwitch = styled(Switch)((props) => ({
  switchBase: {
    "&$checked": {
      color: props.theme.palette.primary.main,
    },
    "&$checked + $track": {
      backgroundColor: props.theme.palette.primary.main,
    },
  },
  checked: {},
  track: {},
}));

export default GreenSwitch;
