import { gql } from "@apollo/client";

export const GET_PEER_REVIEW_SITES = gql`
  query peerReviewSites($templateId: ID!) {
    peerReviewSites(templateId: $templateId) {
      isMultidose
      site
    }
  }
`;

export const GET_VIOLATIONS = gql`
  query violations($templateId: ID!) {
    violations(templateId: $templateId) {
      id
      site {
        id
        name
      }
      rule {
        id
        field {
          id
          name
        }
        defaultValue
        operator
        isRequirement
      }
    }
  }
`;

export const CREATE_VIOLATION = gql`
  mutation createViolation(
    $templateId: ID!
    $siteName: String!
    $rules: [RuleInputType]
  ) {
    createViolation(
      templateId: $templateId
      siteName: $siteName
      rules: $rules
    ) {
      violation {
        id
        site {
          id
          name
        }
        rule {
          id
          field {
            id
            name
          }
          defaultValue
          operator
          isRequirement
        }
      }
    }
  }
`;

export const UPDATE_VIOLATION = gql`
  mutation updateViolation(
    $violationId: ID!
    $siteName: String!
    $rules: [RuleInputType]!
  ) {
    updateViolation(
      violationId: $violationId
      siteName: $siteName
      rules: $rules
    ) {
      violation {
        id
        site {
          id
          name
        }
        rule {
          id
          field {
            id
            name
          }
          defaultValue
          operator
          isRequirement
        }
      }
    }
  }
`;

export const DELETE_VIOLATION = gql`
  mutation deleteViolation($violationId: ID!) {
    deleteViolation(violationId: $violationId) {
      id
    }
  }
`;
