import React, { useEffect, useRef, useState } from "react";
import { Field, useFormikContext } from "formik";
import FormRow from "components/base/FormRow";
import { Box, Grid } from "@mui/material";
import NakedInputField from "./NakedInputField";
import NakedSelectField from "./NakedSelectField";
import { SelectOptionInterface } from "interfaces/common";
import ToggleButton from "components/base/ToggleButton";
import { ToggleButtonGroup } from "@mui/material";
import InvalidMessage from "components/base/InvalidMessage";

interface Props {
  id: string;
  minField: string;
  maxField: string;
  isRangeField: string;
  unitsField?: string;
  label: string;
  isActive: boolean;
  labelXs: number;
  unitOptions: SelectOptionInterface[];
}

const RangeField = ({
  id,
  minField,
  maxField,
  isRangeField,
  label,
  isActive,
  labelXs,
  unitsField,
  unitOptions,
}: Props) => {
  const { values, setFieldValue, errors }: any = useFormikContext();
  const [disabled, setDisabled] = useState<boolean>(!isActive);

  const handleDisable = (value: boolean) => {
    setDisabled(!value);
  };

  // // Avoid running the handleDisabled the first time the component loads this would reset
  // // current values.
  const firstUpdate = useRef(true);
  useEffect(() => {
    if (typeof isActive !== "undefined" && !firstUpdate.current)
      handleDisable(isActive);
    else firstUpdate.current = false;
    // eslint-disable-next-line
  }, [isActive]);

  const toggleIsRange = (_event: React.MouseEvent<HTMLElement>, value: any) => {
    if (value !== null) setFieldValue(isRangeField, value);
  };

  const rangeFieldWidth = () => {
    if (unitsField) return values[isRangeField] ? 3 : 6;
    return values[isRangeField] ? 6 : 12;
  };

  return (
    <>
      <FormRow
        label={label}
        disabled={disabled}
        name={id}
        handleDisable={handleDisable}
        labelXs={labelXs}
      >
        <Grid container spacing={2}>
          <Grid item xs={rangeFieldWidth()}>
            <Field
              type="number"
              disabled={disabled}
              component={NakedInputField}
              id={`${id}-minimum`}
              name={minField}
              variant="outlined"
              error={Boolean(errors[minField])}
            />
            {errors[minField] && <InvalidMessage text={errors[minField]} />}
          </Grid>

          {values[isRangeField] && (
            <>
              <Grid item xs={rangeFieldWidth()}>
                <Field
                  type="number"
                  component={NakedInputField}
                  disabled={disabled}
                  labelXs={0}
                  id={`${id}-maximum`}
                  name={maxField}
                  variant="outlined"
                  error={Boolean(errors[maxField])}
                />
                {errors[maxField] && <InvalidMessage text={errors[maxField]} />}
              </Grid>
            </>
          )}

          {unitsField && (
            <Grid item xs={6}>
              <Field
                component={NakedSelectField}
                options={unitOptions}
                disabled={disabled}
                id={`${id}-units-input`}
                name={unitsField}
                variant="outlined"
              />
            </Grid>
          )}
        </Grid>
      </FormRow>
      <FormRow
        label={""}
        disabled={disabled}
        name={id}
        handleDisable={handleDisable}
        labelXs={labelXs}
      >
        <Box width={"100%"}>
          <ToggleButtonGroup
            value={values[isRangeField]}
            exclusive
            onChange={toggleIsRange}
            aria-label={`${label}-group`}
            fullWidth={true}
          >
            <ToggleButton
              fullWidth={true}
              name={isRangeField}
              selected={!values[isRangeField]}
              value={false}
              disabled={disabled}
              data-test-id={`${id}-specific-button`}
            >
              Specific
            </ToggleButton>

            <ToggleButton
              fullWidth={true}
              size={"small"}
              name={isRangeField}
              selected={values[isRangeField]}
              value={true}
              disabled={disabled}
              data-test-id={`${id}-range-button`}
            >
              Range
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </FormRow>
    </>
  );
};

export default RangeField;
