import {
  Checkbox,
  Chip,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
} from "@mui/material";
import React, { useEffect } from "react";

interface Props {
  options: string[];
  selectedOptions: string[];
  placeholder: string;
  selectedOptionsUpdated(options: string[]): void;
  resetSearchResults(): void;
  allSelectedLabel?: string;
  selectAllLabel?: string;
  disabled?: boolean;
}

const FieldWrapper = styled("div")`
  display: inline-flex;
  flex-wrap: wrap;
`;

const ChipsWrapper = styled("div")`
  display: flex;
  flex-wrap: wrap;
`;

const StyledChip = styled(Chip)`
  && {
    margin: 2px;
  }
`;

const StyledFormControl = styled(FormControl)`
  && {
    min-width: 200px;
    max-width: 400px;
  }
`;

const StyledSelect = styled(Select)`
  && {
    margin: 16px 8px 8px 0px;
    width: 300px;
    min-width: 300px;
    max-width: 300px;
  }
`;

const StyledCheckbox = styled(Checkbox)`
&& {
  &.Mui-checked {
    color: ${(props) => (props.checked ? props.theme.palette.primary.main : props.theme.palette.primary.contrastText)};
  }
}}
`;

const SearchMultiSelectField = ({
  options,
  placeholder,
  selectedOptionsUpdated,
  resetSearchResults,
  selectedOptions,
  allSelectedLabel,
  selectAllLabel,
  disabled,
}: Props) => {
  const [selectedItems, setSelectedItems] = React.useState<any>(
    selectedOptions || [],
  );
  const allOptions: string[] = [];
  options.forEach((option) => {
    allOptions.push(option);
  });
  const allSelected = selectedOptions.length === options.length;
  const [selectAllStatus, setSelectAllStatus] = React.useState<boolean>(false);

  const arrayMatch = (a: Array<any>, b: Array<any>) => {
    a = a.sort();
    b = b.sort();
    return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index])
    );
  };

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as string[];
    if (value[0] === "SelectAll" || value.indexOf("SelectAll") > -1) {
      handleSelectAll(allOptions);
      return;
    }
    setSelectedItems(value);
    selectedOptionsUpdated(value);
    resetSearchResults();
    if (arrayMatch(value, allOptions)) {
      setSelectAllStatus(true);
    } else {
      setSelectAllStatus(false);
    }
  };

  const handleSelectAll = (value: any) => {
    setSelectAllStatus(!selectAllStatus);
    setSelectedItems(!selectAllStatus ? value : []);
    selectedOptionsUpdated(!selectAllStatus ? value : []);
    resetSearchResults();
  };

  useEffect(() => {
    setSelectedItems(selectedOptions);
    if (
      selectedOptions &&
      selectedOptions.length &&
      arrayMatch(allOptions, selectedOptions)
    ) {
      setSelectAllStatus(true);
    }
  }, [selectedOptions, allOptions]);

  const handleRender = (selected: any) => {
    return (
      <ChipsWrapper>
        {allSelected === true && allSelectedLabel ? (
          <StyledChip key={allSelectedLabel} label={allSelectedLabel} />
        ) : (
          selected.map((value: string) => (
            <StyledChip key={value} label={value} />
          ))
        )}
      </ChipsWrapper>
    );
  };

  return (
    <FieldWrapper>
      <StyledFormControl>
        <InputLabel>{placeholder}</InputLabel>
        <StyledSelect
          multiple
          value={selectedItems}
          onChange={handleChange}
          input={<Input />}
          placeholder={placeholder}
          renderValue={handleRender}
          disabled={disabled}
        >
          <MenuItem disabled value="">
            <em>{placeholder}</em>
          </MenuItem>
          <MenuItem
            key="SelectAll"
            value="SelectAll"
            onChange={() => {
              handleSelectAll(allOptions);
            }}
          >
            <StyledCheckbox checked={selectAllStatus} />
            <ListItemText primary={selectAllLabel || "Select all"} />
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              <StyledCheckbox checked={selectedItems.indexOf(option) > -1} />
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </StyledSelect>
      </StyledFormControl>
    </FieldWrapper>
  );
};

export default SearchMultiSelectField;
