import { ProtocolDrugOrderInCycle } from "interfaces/templates";

export const prepareResponseForReOrdering = (
  drugOrderInCycle: ProtocolDrugOrderInCycle[],
  selectedCycle: number,
  sourceIndex: number,
  destinationIndex: number
) => {
  const startIndex = Math.min(sourceIndex, destinationIndex);
  const endIndex = Math.max(sourceIndex, destinationIndex);
  const delta = sourceIndex > destinationIndex ? 1 : -1;
  return drugOrderInCycle.map((drugOrder) => {
    const returnObj = JSON.parse(JSON.stringify(drugOrder));
    if (drugOrder.cycle !== selectedCycle) return returnObj;
    if (drugOrder.position === sourceIndex) {
      returnObj.position = destinationIndex;
      return returnObj;
    }
    if (drugOrder.position >= startIndex && drugOrder.position <= endIndex) {
      returnObj.position += delta;
    }
    return returnObj;
  });
};
