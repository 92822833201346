import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import UserContext from "contexts/UserContext";
import { getUserSearch } from "shared/utils";

const Home = () => {
  const user = useContext(UserContext);
  const history = useHistory();

  history.push(getUserSearch(user));

  return (
    <div>
      <h2>
        <Link to="/search">Search</Link>
      </h2>
    </div>
  );
};

export default Home;
