import { FieldProps, useFormikContext } from "formik";
import React, { useCallback } from "react";
import SelectField from "./SelectField";

interface Props extends FieldProps {
  label: string;
  options: any[];
  handleMutation: any;
  noEmptyOption: boolean;
  toggleActive?: boolean;
  isActive: boolean;
  readOnly: boolean;
  showComponentOnly?: boolean;
  placeholder?: string;
  width?: string;
  labelXs?: any;
}

const parentName = (name: string): string => name.split(".")[0];
const ObjectSelectField = (props: Props): JSX.Element => {
  const { handleMutation, field, options } = props;
  const { setFieldValue } = useFormikContext();
  const customHandleMutation = useCallback(
    (name: any, defaultValue: any) => {
      const selectedOption = options.find(
        (option) => option.data.id === defaultValue
      );
      if (selectedOption) {
        setFieldValue(parentName(field.name), selectedOption.data);
      }
      handleMutation && handleMutation(name, defaultValue);
    },
    [handleMutation, field.name, options, setFieldValue]
  );
  const newProps = { ...props, handleMutation: customHandleMutation };
  return <SelectField {...newProps} />;
};

export default ObjectSelectField;
