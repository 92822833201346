import { DrugInterface, emptyDrug } from "interfaces/formulary";
import { createContext } from "react";

export enum DrugAction {
  ADD,
  EDIT,
  CLONE,
}

export interface DrugContextType {
  action: DrugAction;
  setAction: (action: DrugAction) => void;
  drug: DrugInterface;
  setDrug: (value: DrugInterface) => void;
  modalIsVisible: boolean;
  setModalIsVisible: (value: boolean) => void;
}

const initialDrugContext: DrugContextType = {
  action: DrugAction.ADD,
  setAction: () => {},
  drug: emptyDrug,
  setDrug: () => {},
  modalIsVisible: false,
  setModalIsVisible: () => {},
};

const DrugContext = createContext<DrugContextType>(initialDrugContext);

export default DrugContext;
