import { Select, styled } from "@mui/material";

const OarSelect = styled(Select)`
&& {
  border-width: 1px;
  border-color: ${(props) => props.theme.palette.grey[300]};
  margin: 6px;
  padding-left: 6px;
  }
}}
`;

export default OarSelect;
