import React, { useContext } from "react";
import { useQuery } from "@apollo/client";
import { USER_QUERY } from "graphql/UserQueries";
import UserContext from "contexts/UserContext";
import { ThemeContext } from "contexts/ThemeContext";
import gcTheme from "components/themes/GC/theme";
import lumonusTheme from "components/themes/Lumonus/theme";

const GC_THEME_REGIONS = ["AU", "UK"];

interface Region {
  id: string;
  name: string;
}

const getTheme = (regions: Region[]): any => {
  if (regions.length === 0) return lumonusTheme;
  return regions.every((region: Region) =>
    GC_THEME_REGIONS.includes(region.name),
  )
    ? gcTheme
    : lumonusTheme;
};

const ProtectedRoute = (props: React.PropsWithChildren) => {
  const { data, loading } = useQuery(USER_QUERY, {
    fetchPolicy: "cache-first",
  });
  const themeContext = useContext(ThemeContext);
  if (loading) {
    return <div>Loading...</div>;
  }
  if (data?.user?.firstName) {
    // If user is only in AU and/or UK, set theme to GC. Otherwise, set theme to Lumonus.
    const regions = data?.user?.regions;
    const theme = getTheme(regions);
    themeContext?.setTheme(theme);
    return (
      <UserContext.Provider value={data.user}>
        <>{props.children}</>
      </UserContext.Provider>
    );
  }
  //if error or query didnt return reqd details
  else {
    return <h1>Not Authorized, Please login or use different credentials</h1>;
  }
};

export default ProtectedRoute;
