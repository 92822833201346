import React, { Fragment } from "react";
import {
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  IconButton,
  styled,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import {
  GET_VIOLATIONS,
  DELETE_VIOLATION,
} from "../../graphql/PeerReviewQueries";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useParams } from "react-router-dom";
import { doseName, prescriptionContent } from "i18n/constants";

const StyledTableHead = styled(TableHead)`
  background-color: ${(props) => props.theme.palette.grey[100]};
`;

const StyledTableHeaderCell = styled(TableCell)`
  font-weight: bold;
  border-bottom-width: 0px;
`;

const StyledTable = styled(Table)`
  max-width: 800px;
  border-spacing: 0 16px;
  border-collapse: separate;
`;

const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-head {
    background-color: black;
    color: white;
  }
  &.MuiTableCell-body {
    font-size: 14;
    padding-top: 4px;
    padding-bottom: 4px;
    border-top-width: 1px;
    border-color: ${(props) => props.theme.palette.grey[300]};
    border-style: solid;
    border-right-width: 0px;
    border-left-width: 0px;
    &:first-of-type {
      border-left-width: 1px;
    }
    &:last-of-type {
      border-right-width: 1px;
    }
  }
`;

interface RuleInterface {
  id: string;
  site: any;
  rule: any[];
  isMultidose: boolean;
}

interface RuleColumnInterface {
  attribute: string;
  label: string;
}

interface Props {
  violations: any[];
  isActive: boolean;
  editViolation: any;
}

interface ParamTypes {
  templateId: string;
}

const RULE_TABLE_COLUMNS: RuleColumnInterface[] = [
  { label: "Site", attribute: "siteName" },
  { label: "Requirements", attribute: "requisites" },
  { label: "Constraints", attribute: "constraints" },
  { label: "Edit", attribute: "edit" },
  { label: "Delete", attribute: "delete" },
];

const formatFieldName = (field: keyof typeof prescriptionContent.fields) => {
  if (field in prescriptionContent.fields) {
    return prescriptionContent.fields[field];
  }
  return field.replace(/_/g, " ");
};

const formatValue = (values: any) => {
  if (values.constructor === Array)
    return values
      .map((value: any) => {
        if (value === true || value === "true") return "Yes";
        if (value === false || value === "false") return "No";
        return value;
      })
      .join(", ");
  return "";
};

const formatRule = (rule: any, index: number, isMultidose: boolean) => {
  const defaultValues = rule.defaultValue;
  const valueText = formatValue(defaultValues);

  const field = formatFieldName(rule?.field?.name);
  const operator = rule.operator;

  // Handle Dose and FractionDose differently if it is an SIB site
  if (isMultidose && ["Dose", "Fraction Dose"].includes(field)) {
    const doseNames = doseName(defaultValues.length);
    return defaultValues.map((value: any, index: number) => {
      const sibField = `${doseNames[index]} ${field}`;
      return (
        <div
          key={`${sibField}-${index}`}
        >{`${sibField} ${operator} ${value}`}</div>
      );
    });
  }
  return (
    <div key={`${field}-${index}`}>{`${field} ${operator} ${valueText}`}</div>
  );
};

const ruleRow = (
  site: any,
  violation: RuleInterface,
  key: string,
  isActive: boolean,
  onEdit: any,
  onDelete: any,
): JSX.Element => {
  const requirements = violation.rule.filter(
    (r: any) => r.isRequirement === true,
  );
  const constraints = violation.rule.filter(
    (r: any) => r.isRequirement === false,
  );
  const isMultidose = violation.isMultidose;
  const siteName = isMultidose ? `${site?.name} (SIB)` : site?.name;
  return (
    <Fragment key={key}>
      <TableRow data-test-id={`rule-row-${key}`} key={key}>
        <StyledTableCell data-test-id={`siteName-${key}`}>
          {siteName}
        </StyledTableCell>
        <StyledTableCell data-test-id={`preRequisites-${key}`}>
          {requirements.map((rule: any, index: number) =>
            formatRule(rule, index, isMultidose),
          )}
        </StyledTableCell>
        <StyledTableCell data-test-id={`constraints-${key}`}>
          {constraints.map((rule: any, index: number) =>
            formatRule(rule, index, isMultidose),
          )}
        </StyledTableCell>
        <StyledTableCell align="center">
          <IconButton
            disabled={!isActive}
            data-test-id={`violationEdit-${key}`}
            onClick={onEdit}
            size="large"
          >
            <EditIcon color={isActive ? "primary" : "disabled"} />
          </IconButton>
        </StyledTableCell>
        <StyledTableCell align="center">
          <IconButton
            disabled={!isActive}
            data-test-id={`violationDelete-${key}`}
            onClick={onDelete}
            size="large"
          >
            <DeleteIcon color={isActive ? "primary" : "disabled"} />
          </IconButton>
        </StyledTableCell>
      </TableRow>
    </Fragment>
  );
};

const ViolationsTable = (props: Props) => {
  const { templateId } = useParams<ParamTypes>();
  const { violations, isActive, editViolation } = props;

  const [deleteViolation] = useMutation(DELETE_VIOLATION, {
    refetchQueries: [{ query: GET_VIOLATIONS, variables: { templateId } }],
  });
  const onDeleteViolation = (index: number) => {
    deleteViolation({
      variables: { violationId: violations?.[index]?.id },
    });
  };

  return (
    <TableContainer>
      <StyledTable aria-label="customized table">
        <colgroup>
          <col style={{ width: "20%" }} />
          <col style={{ width: "35%" }} />
          <col style={{ width: "35%" }} />
          <col style={{ width: "5%" }} />
          <col style={{ width: "5%" }} />
        </colgroup>
        <StyledTableHead>
          <TableRow>
            {RULE_TABLE_COLUMNS.map((header, index) => (
              <StyledTableHeaderCell
                align={index > 2 ? "center" : "left"}
                key={`rule-header-${index}`}
              >
                {header?.label}
              </StyledTableHeaderCell>
            ))}
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {violations?.map((violation: RuleInterface, index: number) =>
            ruleRow(
              violation?.site,
              violation,
              `rule-row-${index}`,
              isActive,
              () => editViolation(index),
              () => onDeleteViolation(index),
            ),
          )}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
};

export default ViolationsTable;
