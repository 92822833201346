import React, { useContext } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  styled,
  useTheme,
  CustomTheme,
  Stack,
  Divider,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Link, useLocation, useHistory } from "react-router-dom";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import { LOGOUT } from "graphql/UserQueries";
import { useMutation } from "@apollo/client";
import { navigateToExternalURL } from "shared/utils";
import UserContext from "contexts/UserContext";
import EnvironmentBanner from "./EnvironmentBanner";
import Favicon from "react-favicon";

const StyledAppBar = styled(AppBar)`
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  height: 56px;
`;

const StyledMenuItem = styled(MenuItem)`
  color: ${(props) => props.theme.palette.grey[600]};
  font-size: large;
`;

const StyledToolbar = styled(Toolbar)`
  background-color: white;
  align-items: center;
  height: 56px;
  min-height: 56px;
`;

const StyledLink = styled(Link)<{ $isActive: boolean }>`
  color: ${(props) => props.theme.palette.primary.contrastText};
  border-bottom: ${(props) =>
    props.$isActive
      ? `3px solid ${props.theme.palette.primary.contrastText}`
      : "none"};
  height: 100%;
  line-height: 24px;
  text-decoration: none;
  background-color: transparent;
  padding-top: 16px;
  &:hover {
    text-decoration: none;
    color: ${(props) => props.theme.palette.primary.contrastText};
  }
`;

const StyleTypography = styled(Typography)`
  color: black;
`;

const StyledMenuTypography = styled(StyleTypography)`
  flex-grow: 1;
  cursor: pointer;
`;

const TopNav = (): JSX.Element => {
  const location = useLocation();
  const theme: CustomTheme = useTheme();
  const history = useHistory();
  const [anchorDropDown, setAnchorDropDown] =
    React.useState<null | HTMLElement>(null);
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorDropDown(event.currentTarget);
  };
  const isActiveItem = (itemLocation: string): boolean => {
    return location.pathname.startsWith(itemLocation);
  };
  const isMenuOpen = Boolean(anchorDropDown);
  const handleMenuClose = () => {
    setAnchorDropDown(null);
  };

  const user = useContext(UserContext);

  const [logout] = useMutation(LOGOUT);

  const renderMenu = (
    <Menu
      anchorEl={anchorDropDown}
      id="account-menu"
      open={isMenuOpen}
      onClose={handleMenuClose}
      slotProps={{
        paper: {
          elevation: 0,
          sx: {
            width: "200px",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem>
        <ListItemText>
          {user.firstName} {user.lastName}
        </ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem
        onClick={() => {
          navigateToExternalURL("sso/logout");
          logout();
        }}
      >
        <ListItemIcon>
          <ExitToAppOutlinedIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Logout</ListItemText>
      </MenuItem>
    </Menu>
  );

  return (
    <StyledAppBar position="static">
      <Favicon url={theme.custom.favicon} />
      <StyledToolbar
        variant="dense"
        style={{ backgroundColor: theme.palette.primary.main }}
      >
        <Stack
          sx={{
            cursor: "pointer",
            svg: {
              height: "auto",
              width: "138px",
            },
            div: {
              display: "flex",
              alignItems: "center",
            },
          }}
          width="200px"
          data-testid="app-icon"
          onClick={() => history.push("/home")}
        >
          {theme?.custom?.logo}
        </Stack>
        <Stack
          direction="row"
          gap={4}
          width={1}
          alignItems="center"
          marginLeft="81px"
          height={"56px"}
        >
          {user.roAccess && (
            <StyledLink to="/search" $isActive={isActiveItem("/search")}>
              <Typography variant="button">
                {user ? "Search" : "Login"}
              </Typography>
            </StyledLink>
          )}
          {user.roAccess && (
            <StyledLink
              data-test-id="review-nav"
              to="/review"
              $isActive={isActiveItem("/review")}
            >
              <Typography variant="button">Review</Typography>
            </StyledLink>
          )}
          {user.moAccess && (
            <StyledLink
              to="/protocol_search"
              $isActive={isActiveItem("/protocol_search")}
            >
              <Typography variant="button">Protocol Templates</Typography>
            </StyledLink>
          )}
          {user.moAccess && (
            <StyledLink
              data-test-id="view-formulary"
              to="/formulary"
              $isActive={isActiveItem("/formulary")}
            >
              <Typography variant="button">Formulary</Typography>
            </StyledLink>
          )}
        </Stack>
        <EnvironmentBanner />
        {user && (
          <StyledMenuItem onClick={handleProfileMenuOpen}>
            <AccountCircleOutlinedIcon
              fontSize="large"
              style={{ color: theme.palette.primary.contrastText }}
            />
          </StyledMenuItem>
        )}
      </StyledToolbar>
      {renderMenu}
    </StyledAppBar>
  );
};

export default TopNav;
