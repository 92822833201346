import React, { useState, useEffect } from "react";
import {
  GET_VIOLATIONS,
  CREATE_VIOLATION,
  UPDATE_VIOLATION,
} from "../../graphql/PeerReviewQueries";
import { useMutation } from "@apollo/client";
import Autocomplete from "@mui/material/Autocomplete";
import OarDivider from "../oars/OarDivider";
import {
  TextField,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  useTheme,
} from "@mui/material";
import PeerReviewField from "./PeerReviewField";
import GreenButton from "../base/GreenButton";
import { defaultConstraintRequirement, GenericRule, Rule } from "./constants";

const autoCompleteStyle = {
  borderWidth: "1px",
  margin: "6px",
  paddingLeft: "6px",
  maxWidth: "40%",
};

const BoldGrid = styled(Grid)`
  font-weight: bold;
`;

interface RuleOutput {
  field: string;
  defaultValue: any[];
  operator: string;
  isRequirement: boolean;
  id: any;
}

interface Site {
  isMultidose: boolean;
  site: string;
}

interface Props {
  setIsVisible: any;
  isVisible: any;
  index: number;
  violation: any;
  templateId: string;
  siteList: any[];
}

interface ViolationState {
  index: number;
  isMultidose: boolean;
  site: string;
  requirements: Rule[];
  constraints: Rule[];
  isEdit: boolean;
  validated: boolean;
  modalShow: boolean;
  isVisible: boolean;
}

const formatRules = (rules: Rule[], isRequirement: boolean) =>
  rules.filter((r: Rule) => r.isRequirement === isRequirement);

const mergeRequirementsConstraints = (
  requirements: Rule[],
  constraints: Rule[],
): RuleOutput[] => {
  const filterRules = (rules: Rule[], isRequirement: boolean): RuleOutput[] => {
    // Remove rules with no default value
    const hasDefaultValue = rules.filter(
      (rule: Rule): boolean =>
        rule.defaultValue?.length > 0 && rule.defaultValue[0] !== "",
    );
    // Map the jank data into something the backend is expecting
    const niceRule = hasDefaultValue.map((rule: Rule): RuleOutput => {
      // Remove empty elements from the default Value
      const defaultValue = rule.defaultValue
        .filter((val: any): boolean => val !== "")
        .map((val: any): string => val !== null && val.toString());
      return {
        field: rule.field?.name || "",
        operator: rule.operator,
        isRequirement,
        defaultValue,
        id: rule.id,
      };
    });

    // Remove any rules without a field
    return niceRule.filter((rule: RuleOutput): boolean => !!rule.field);
  };

  return filterRules(requirements, true).concat(
    filterRules(constraints, false),
  );
};

const ViolationModal = ({
  setIsVisible,
  isVisible,
  index,
  violation,
  siteList,
  templateId,
}: Props): JSX.Element => {
  const stateFromData = (data: any): any => ({
    index: index,
    isMultidose: data.isMultidose,
    site: data.site?.name,
    requirements: formatRules(data.rule, true),
    constraints: formatRules(data.rule, false),
    isEdit: true,
  });
  const theme = useTheme();
  const siteOptions = siteList?.map((site: Site) => {
    const label = site.isMultidose ? `${site.site} (SIB)` : site.site;
    return { value: site.site, label };
  });

  const [state, setState] = useState<ViolationState>();
  const [updateViolation] = useMutation(UPDATE_VIOLATION, {
    refetchQueries: [{ query: GET_VIOLATIONS, variables: { templateId } }],
  });
  const [createViolation] = useMutation(CREATE_VIOLATION, {
    refetchQueries: [{ query: GET_VIOLATIONS, variables: { templateId } }],
  });

  const onCreateViolation = (): void => {
    if (!state) return;
    const rules = mergeRequirementsConstraints(
      state.requirements,
      state.constraints,
    );

    // Only create a violation if we have some rules
    if (rules.length)
      createViolation({
        variables: {
          templateId,
          siteName: state.site,
          rules,
        },
      });
    setIsVisible(false);
  };

  const onEditViolation = (): void => {
    if (!state) return;
    const rules = mergeRequirementsConstraints(
      state.requirements,
      state.constraints,
    );
    updateViolation({
      variables: {
        violationId: violation?.id,
        siteName: state.site,
        rules,
      },
    });
    setIsVisible(false);
  };

  const addConstraint = (): void => {
    if (!state) return;
    const constraints = state.constraints;
    constraints.push(defaultConstraintRequirement);
    setState({ ...state, constraints });
  };

  const removeConstraint = (index: any): void => {
    if (!state) return;
    const constraints = state.constraints;
    constraints.splice(index, 1);
    setState({ ...state, constraints });

    // If its the last constraint, add a new one with default values
    if (constraints.length === 0) addConstraint();
  };

  const addRequirement = (): void => {
    if (!state) return;
    const requirements = state.requirements;
    requirements.push(defaultConstraintRequirement);
    setState({ ...state, requirements });
  };

  const removeRequirement = (index: any): void => {
    if (!state) return;
    const requirements = state.requirements;
    requirements.splice(index, 1);
    setState({ ...state, requirements });

    // If its the last requirement, add a new one with default values
    if (requirements.length === 0) addRequirement();
  };

  useEffect(() => {
    if (violation) {
      setState({ state, ...stateFromData(violation) });
    } else if (violation === null) {
      setState({
        index: index,
        isMultidose: false,
        site: siteOptions?.[0]?.value,
        requirements: [defaultConstraintRequirement],
        constraints: [defaultConstraintRequirement],
        validated: false,
        modalShow: true,
        isVisible: true,
        isEdit: false,
      });
    }
  }, [violation, index, siteList]);

  const siteDefaultValue = siteOptions?.find(
    (site: { value: string; label: string }) => site.value === state?.site,
  );
  // Don't render the modal if we don't have a state yet
  if (!state) return <></>;
  return (
    <Dialog
      open={isVisible}
      onClose={() => setIsVisible(false)}
      scroll="paper"
      data-test-id="violations-modal"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle style={{ fontWeight: "bold" }}>
        {state.isEdit ? "Edit Violation" : "Add Violation"}
      </DialogTitle>
      <DialogContent>
        <Grid container alignItems="center">
          <BoldGrid item xs={2}>
            Site
          </BoldGrid>
          <Grid item xs={10}>
            <Autocomplete
              sx={{ borderColor: theme.palette.grey[300] }}
              style={autoCompleteStyle}
              disableClearable
              id="site-name-select"
              options={siteOptions || []}
              autoHighlight
              getOptionLabel={(option: { label: string; value: string }) =>
                option.label
              }
              isOptionEqualToValue={(
                option: { label: string; value: string },
                value: { value: string },
              ): boolean => value?.value === option?.value}
              data-test-id="btn-confirmation-dialog-cancel"
              value={siteDefaultValue}
              onChange={(_event: any, selectedOption: any): void => {
                setState({ ...state, site: selectedOption?.value || "" });
              }}
              renderInput={(params) => (
                <TextField {...params} InputProps={{ ...params.InputProps }} />
              )}
            />
          </Grid>
        </Grid>
        <OarDivider />
        <Grid container alignItems="center">
          <BoldGrid item xs={2}>
            Requirements
          </BoldGrid>
          <Grid item xs>
            <PeerReviewField
              id="requirements"
              label="Requirements"
              value={state.requirements}
              onChange={(index: number, value: GenericRule): void => {
                state.requirements[index] = { ...value, isRequirement: true };
                setState({ ...state });
              }}
              addRow={addRequirement}
              removeRow={removeRequirement}
            />
          </Grid>
        </Grid>
        <OarDivider />
        <Grid container alignItems="center">
          <BoldGrid item xs={2}>
            Constraints
          </BoldGrid>
          <Grid item xs>
            <PeerReviewField
              id="constraints"
              label="Constraints"
              value={state.constraints}
              onChange={(index, value: GenericRule): void => {
                state.constraints[index] = { ...value, isRequirement: false };
                setState({ ...state });
              }}
              addRow={addConstraint}
              removeRow={removeConstraint}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <GreenButton
          data-test-id="violation-cancel"
          onClick={() => {
            setIsVisible(false);
          }}
        >
          Cancel
        </GreenButton>
        <GreenButton
          solidgreen={"true"}
          data-test-id="violation-confirm"
          title="Confirm"
          onClick={state.isEdit ? onEditViolation : onCreateViolation}
        >
          Save
        </GreenButton>
      </DialogActions>
    </Dialog>
  );
};

export default ViolationModal;
