import {
  DrugOrderActionType,
  DrugOrderInterface,
  emptyDrugOrder,
} from "interfaces/formulary";
import { createContext } from "react";

export interface DrugOrderContextType {
  drugOrder: DrugOrderInterface;
  setDrugOrder: (value: DrugOrderInterface) => void;
  modalIsVisible: boolean;
  action: DrugOrderActionType;
  setModalIsVisible: (value: boolean) => void;
  setActionType: (value: DrugOrderActionType) => void;
}
const initialDrugOrderContext: DrugOrderContextType = {
  drugOrder: emptyDrugOrder,
  setDrugOrder: () => {},
  modalIsVisible: false,
  action: DrugOrderActionType.ADD,
  setModalIsVisible: () => {},
  setActionType: () => {},
};

const DrugOrderContext = createContext<DrugOrderContextType>(
  initialDrugOrderContext
);

export default DrugOrderContext;
