import { Select, SelectProps, styled } from "@mui/material";

interface Props extends SelectProps {
  width?: string;
}

const PeerReviewSelect = styled(Select)<Props>`
&& {
  border-width: 1px;
  border-color: ${(props) => props.theme.palette.grey[300]};
  margin: 6px;
  padding-left: 6px;
  width: ${(props) => props.width || "unset"};
  }
}}
`;

export default PeerReviewSelect;
