import React from "react";
import { EventsType, useIdleTimer } from "react-idle-timer";

const onIdleHandler = (): void => {
  // Reload the current page after the timer has expired
  window.location.reload();
};
const Refresh = () => {
  const filteredEvents: EventsType[] = ["mousedown", "touchstart"];
  useIdleTimer({
    onIdle: onIdleHandler,
    timeout: 1000 * 60 * 60,
    events: filteredEvents,
  });

  return <></>;
};

export default Refresh;
