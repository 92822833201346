import { Checkbox as MUICheckbox, styled } from "@mui/material";

const Checkbox = styled(MUICheckbox)`
  && {
    &.Mui-checked {
      color: ${(props) =>
        props.disabled
          ? props.theme.palette.grey[600]
          : props.checked
            ? props.theme.palette.primary.main
            : "white"};
    }
  }
`;

export default Checkbox;
