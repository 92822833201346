import React from "react";
import { Button, ButtonProps, styled, useTheme } from "@mui/material";

interface Props extends ButtonProps {
  solidgreen?: string;
}

const FontColor = (props: Props) => {
  const theme = useTheme();
  if (props.solidgreen === "true") {
    return "white";
  }
  if (props.disabled) {
    return theme.palette.grey[600];
  }
  return theme.palette.primary.main;
};

const StyledButton = styled(Button)<Props>`
&& {
    text-transform: none;
    color: ${(props: Props) => FontColor(props)};
    border-color: ${(props) =>
      props.disabled
        ? props.theme.palette.grey[600]
        : props.theme.palette.primary.main};
    border-radius: 18px;
    min-height: 2em;
    min-width: 120px;
    width: auto;
    height: 36px;
    font-size: 16px;
    border-width: 2px;

    line-height: 24px;
    padding: 4px 12px;
    text-align: center;
  }
}}
`;

const GreenButton = (props: Props) => {
  return <StyledButton variant="outlined" {...props} />;
};

export default GreenButton;
